import clsx from "clsx";
import { ChangeEvent, InputHTMLAttributes, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import CharacterCounter from "./CharacterCounter";

/* eslint-disable @typescript-eslint/no-explicit-any */

export interface InputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
  hideLabel?: boolean;
  horizontalLayout?: boolean;
  formReturn: UseFormReturn<any, any, any>;
  hideCharCounter?: boolean;
}

export default function InputField({
  name,
  label,
  formReturn,
  hideLabel,
  horizontalLayout,
  hideCharCounter = false,
  ...rest
}: InputFieldProps) {
  const [length, setLenght] = useState<number>(
    formReturn?.getValues()?.[name]?.length ?? 0
  );

  const {
    register,
    formState: { errors },
  } = formReturn;

  const { onChange: hookFormOnChange, ...restRegister } = register(name);

  const characterCounterComponent =
    !rest.maxLength || hideCharCounter ? null : (
      <CharacterCounter counter={rest.maxLength - length} />
    );

  const onTextChanged = (event) => {
    setLenght(event.target.value.length);
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    hookFormOnChange(e);

    if (rest.maxLength) {
      onTextChanged(e);
    }

    rest?.onChange?.(e);
  };

  return (
    <>
      <div
        className={clsx(
          horizontalLayout
            ? "flex flex-row justify-start items-center gap-1"
            : "flex flex-col justify-center items-start gap-1"
        )}
      >
        {!hideLabel ? (
          <label
            htmlFor={name}
            className="block text-sm/6 font-medium leading-5 text-gray-900"
          >
            {label}
          </label>
        ) : null}

        <div className={clsx("relative", horizontalLayout ? null : "w-full")}>
          <input
            {...register(name)}
            {...restRegister}
            {...rest}
            type={rest.type ?? "text"}
            onChange={handleOnChange}
            autoComplete={label}
            className={clsx(
              "block w-full rounded-md border-0 py-1.5 text-gray-600 text-sm/6 shadow-sm ring-1 ring-inset ring-gray-200 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-400 read-only:bg-gray-50/80",
              errors[name] ? "ring-[#cd3d64] focus:ring-[#cd3d64]" : "",
              rest.maxLength ? "pr-8" : null
            )}
          />

          {characterCounterComponent}

          <p role="alert" className="text-sm text-[#cd3d64] mt-1">
            {errors[name]?.message?.toString()}
          </p>
        </div>
      </div>
    </>
  );
}
