import { useEffect, useMemo, useState } from "react";
import ToggleBox from "../../../../../components/common/ToggleBox";
import permissionsService, {
  Permission,
} from "../../../../../services/application/permissions-service";
import { UseFormReturn } from "react-hook-form";
import UserUpdateFormInput from "../../../../../models/form-inputs/user-update-input";

interface PermissionsSectionProps {
  form: UseFormReturn<UserUpdateFormInput, void, UserUpdateFormInput>;
  defaultValue?: string[];
}

export default function PermissionsSection({
  form,
  defaultValue,
}: PermissionsSectionProps) {
  const [allPermissions, setAllPermissions] = useState(defaultValue === null);
  const permissions = useMemo(() => permissionsService.getPermissions(), []);
  const [selectedPermissions, setSelectedPermissions] =
    useState<string[]>(defaultValue);

  const permissionsMap = useMemo(
    () =>
      permissions.reduce((accumulator, current: Permission) => {
        accumulator[current.code] =
          selectedPermissions == null
            ? true
            : selectedPermissions.includes(current.code);
        return accumulator;
      }, {} as Record<string, boolean>),
    [permissions, selectedPermissions]
  );

  useEffect(() => {
    form.setValue("permissions", selectedPermissions);
  }, [form, selectedPermissions]);

  const onAllPermissionsChanged = (value: boolean) => {
    setAllPermissions(value);
    setSelectedPermissions(value ? null : []);
  };

  return (
    <ul
      role="list"
      className="divide-y divide-gray-100 border border-gray-100 rounded-md mt-6"
    >
      <li className="flex items-center justify-between py-4 px-8 text-sm leading-6">
        <ToggleBox
          value={allPermissions}
          label="All"
          description="Grant access to all current and future permissions."
          onChanged={onAllPermissionsChanged}
        />
      </li>
      {permissions.map((permission) => (
        <li
          key={permission.code}
          className="flex items-center justify-between py-4 px-8 text-sm leading-6"
        >
          <ToggleBox
            value={permissionsMap[permission.code]}
            label={permission.name}
            disabled={allPermissions}
            description={permission.description}
            onChanged={(value) => {
              setSelectedPermissions((prev) => {
                if (value) {
                  return [...prev, permission.code];
                }

                return prev.filter((x) => x !== permission.code);
              });
            }}
          />
        </li>
      ))}
    </ul>
  );
}
