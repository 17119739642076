import clsx from "clsx";
import { OTPInput } from "input-otp";
import Spinner from "../common/Spinner";

interface OtpCodeFieldProps {
  maxLength: number;
  loading: boolean;
  onComplete: (code: string) => void;
}

export default function OtpCodeField({
  maxLength,
  loading,
  onComplete,
}: OtpCodeFieldProps) {
  return (
    <div className="relative flex items-center justify-center">
      <OTPInput
        maxLength={maxLength}
        onComplete={onComplete}
        containerClassName="group flex items-center has-[:disabled]:opacity-30"
        render={({ slots }) => (
          <>
            <div className="flex">
              {slots.slice(0, 3).map((slot, idx) => (
                <Slot key={idx} {...slot} isDisabled={loading} />
              ))}
            </div>

            <FakeDash />

            <div className="flex">
              {slots.slice(3).map((slot, idx) => (
                <Slot key={idx} {...slot} isDisabled={loading} />
              ))}
            </div>
          </>
        )}
      />

      {loading && (
        <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center">
          <Spinner />
        </div>
      )}
    </div>
  );
}

interface CustomSlotProps {
  isDisabled: boolean;
  char?: string;
  placeholderChar: string;
  hasFakeCaret: boolean;
}

// Feel free to copy. Uses @shadcn/ui tailwind colors.
function Slot(props: CustomSlotProps) {
  return (
    <div
      className={clsx(
        "relative w-12 h-14 text-[2rem]",
        "flex items-center justify-center",
        "transition-all duration-300",
        "border-border border-y border-r first:border-l first:rounded-l-md last:rounded-r-md",
        { "opacity-30": props.isDisabled }
      )}
    >
      <div className="group-has-[input[data-input-otp-placeholder-shown]]:opacity-20">
        {props.char ?? props.placeholderChar}
      </div>
      {props.hasFakeCaret && <FakeCaret />}
    </div>
  );
}

// You can emulate a fake textbox caret!
function FakeCaret() {
  return (
    <div className="absolute pointer-events-none inset-0 flex items-center justify-center animate-caret-blink">
      <div className="w-px h-8 bg-gray-800" />
    </div>
  );
}

function FakeDash() {
  return (
    <div className="flex w-10 justify-center items-center">
      <div className="w-3 h-1 rounded-full bg-gray-400" />
    </div>
  );
}
