import { Outlet } from "react-router-dom";
import ToastHolder from "../../components/common/ToastHolder";

function RootLayout() {
  return (
    <>
      <Outlet />
      <ToastHolder />
    </>
  );
}

export default RootLayout;
