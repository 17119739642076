import { useForm } from "react-hook-form";
import InputField from "../../../../components/form/InputField";
import TenantUser from "../../../../models/entities/tenant-user";
import User from "../../../../models/entities/user";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect, useMemo } from "react";

export interface DeleteDialogContentProps {
  owners: TenantUser[];
  user: User;
  validationChanged: (isValid: boolean) => void;
}

export default function DeleteDialogContent({
  owners,
  user,
  validationChanged,
}: DeleteDialogContentProps) {
  const schema = useMemo(() => {
    const object = {
      email: yup
        .string()
        .required()
        .email()
        .equals([user.email], "Email does not match"),
    };

    if (owners.length) {
      owners.forEach((x) => {
        object[`tenant-${x.tenant.id}`] = yup
          .string()
          .required()
          .equals([x.tenant.name], "Workspace name does not match");
      });
    }

    return yup.object(object).required();
  }, [owners, user.email]);

  const form = useForm({
    resolver: yupResolver(schema),
    mode: "all",
    reValidateMode: "onChange",
  });

  const {
    formState: { isValid },
  } = form;

  useEffect(() => {
    validationChanged(isValid);
  }, [isValid, validationChanged]);

  return (
    <div>
      <form className="text-sm mb-4">
        {owners.length ? (
          <>
            <div>
              You are about to delete your account along with all of the
              following workspaces:{" "}
              <span className="font-semibold">
                {owners.map((x) => x.tenant.name).join(", ")}
              </span>
              .
              <div className="mt-2">
                Any data associated with your account or with the workspaces you
                own will be deleted. The delete action is immediate and
                irreversible.
              </div>
            </div>
            <div className="mt-2 font-medium italic">
              To proceed with the deletion, please type your email address in
              the field as well as the names of the workspaces to be deleted.
            </div>
            <div className="flex flex-col mt-4">
              <InputField
                name="email"
                placeholder={user.email}
                formReturn={form}
                label={""}
              />

              {owners.map((x) => (
                <div key={x.tenant.id}>
                  <InputField
                    name={`tenant-${x.tenant.id}`}
                    placeholder={x.tenant.name}
                    formReturn={form}
                    label={""}
                  />
                </div>
              ))}
            </div>
          </>
        ) : (
          <div></div>
        )}
      </form>
    </div>
  );
}
