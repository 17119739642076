import axios from "axios";

class StorageService {
  /**
   * Uploads a file to S3 using a presigned URL and tracks the upload progress.
   *
   * @param {string} presignedUrl The presigned URL for uploading.
   * @param {File} file The file to upload.
   * @param {Function} onUploadProgress Function to call for progress updates. It receives a number argument.
   * @returns {Promise<void>} Promise that resolves when the upload is complete.
   */
  uploadFileToS3(
    presignedUrl: string,
    metadata: { [key: string]: string },
    file: File,
    onUploadProgress?: (progress: number) => void,
    abortSignal?: AbortSignal
  ): Promise<void> {
    return axios.put(presignedUrl, file, {
      signal: abortSignal,
      headers: {
        "content-type": file.type,
        ...metadata,
      },
      onUploadProgress: !onUploadProgress
        ? null
        : (progressEvent) => {
            if (abortSignal.aborted) {
              return;
            }
            const progress = (progressEvent.loaded / progressEvent.total) * 100;
            onUploadProgress(Math.round(progress));
          },
    });
  }
}

export default new StorageService();
