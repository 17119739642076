import Post from "../../../../models/entities/post";
import PostActions from "../../../shared/post-actions/PostActions";
import { Fragment } from "react/jsx-runtime";
import PostPreviewTile from "../../calendar/components/views/PostPreviewTile";
import { useRef } from "react";

export interface PostsListProps {
  filteredPosts: Post[];
  loading: boolean;
}
export default function PostsList({ filteredPosts, loading }: PostsListProps) {
  const postComposerRef = useRef(null);

  return (
    <>
      {filteredPosts.length == 0 && !loading && (
        <div className="w-full h-full flex flex-col justify-center items-center">
          <div className="text-sm text-center leading-8 text-gray-600">
            No posts found for the selected filters.
          </div>
        </div>
      )}
      {filteredPosts.length > 0 && (
        <div className="flex flex-col gap-4">
          <PostActions composerRef={postComposerRef}>
            {({
              deletePost,
              isUpdating,
              moveToDraft,
              moveToScheduled,
              publishNow,
              editPost,
              clonePost,
            }) => (
              <>
                {filteredPosts.map((post) => (
                  <Fragment key={post.id}>
                    {post && (
                      <div className="rounded-md border">
                        <PostPreviewTile
                          metricsViewMode="expanded"
                          event={{
                            id: post.id,
                            name: post.title,
                            time: "time",
                            datetime: "datetime",
                            post: post,
                          }}
                          flat={true}
                          isSubmitting={isUpdating}
                          onDelete={(event) => deletePost(event.post)}
                          onEdit={() => editPost(post)}
                          onClone={() => clonePost(post)}
                          publishNow={(event) => publishNow(event.post)}
                          moveToDraft={(event) => moveToDraft(event.post)}
                          moveToScheduled={(event) =>
                            moveToScheduled(event.post)
                          }
                        />
                      </div>
                    )}
                  </Fragment>
                ))}
              </>
            )}
          </PostActions>
        </div>
      )}
    </>
  );
}
