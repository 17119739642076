import { useCallback, useEffect, useRef } from "react";
import attachmentService from "../../../../services/api/attachment-service";
import useCurrentSocialSet from "../../../../hooks/useCurrentSocialSet";
import storageService from "../../../../services/storage/storage-service";
import { AttachmentType } from "../../../../models/entities/attachment";
import errorReporter from "../../../../utils/error-reporter";
import AttachmentSlot from "../../create-post/models/attachment-slot";

interface AttachmentsHandlerProps {
  attachmentSlots: AttachmentSlot[];
  onSlotUpdated: (slot: AttachmentSlot) => void;
}

export default function AttachmentsHandler({
  attachmentSlots,
  onSlotUpdated,
}: AttachmentsHandlerProps) {
  const currentSocialSet = useCurrentSocialSet();
  const abortControllerRef = useRef<Record<string, AbortController>>({});

  const uploadFile = useCallback(
    async (slot: AttachmentSlot) => {
      if (abortControllerRef.current[slot.id]) {
        abortControllerRef.current[slot.id].abort();
      }

      abortControllerRef.current[slot.id] = new AbortController();

      const abortSignal = abortControllerRef.current[slot.id].signal;
      const file: File = slot.file;

      slot.status = "uploading";
      onSlotUpdated(slot);

      if (file) {
        try {
          slot.progress = 0;
          onSlotUpdated(slot);

          const { url, metadata, attachment } =
            await attachmentService.getUploadUrl(
              {
                id: slot.id,
                socialSetId: currentSocialSet.id,
                fileName: file.name,
                fileSize: file.size,
                mimeType: file.type,
                role: "MediaAttachment",
              },
              abortSignal
            );

          slot.attachment = attachment;
          onSlotUpdated(slot);

          await storageService.uploadFileToS3(
            url,
            metadata,
            file,
            (progress) => {
              slot.progress = progress;
              onSlotUpdated(slot);
            },
            abortSignal
          );

          if (abortSignal.aborted) return;

          slot.status = "processing";
          onSlotUpdated(slot);

          const processedAttachment =
            attachment.type == AttachmentType.Photo
              ? await attachmentService.processPhoto(
                  attachment,
                  file,
                  abortSignal
                )
              : await attachmentService.processVideo(
                  attachment,
                  file,
                  abortSignal
                );

          if (abortSignal.aborted) return;

          slot.attachment = processedAttachment;
          slot.status = "ready";
          slot.progress = null;

          onSlotUpdated(slot);
        } catch (e) {
          if (!abortSignal.aborted) {
            slot.status = "error";
            slot.progress = null;

            onSlotUpdated(slot);

            errorReporter.alertErrors(e);
          }
        }
      }
    },
    [currentSocialSet.id, onSlotUpdated]
  );

  // Initiate upload to any files that are ready to upload
  useEffect(() => {
    for (const slot of attachmentSlots) {
      if (slot.status == "readyToUpload") {
        uploadFile(slot);
      }
    }
  }, [attachmentSlots, uploadFile]);

  // Abort any active uploads when the component is unmounted
  useEffect(() => {
    const controllers = abortControllerRef.current;

    return () => {
      for (const key in controllers) {
        const abortController = controllers[key];
        if (abortController) {
          abortController.abort();
        }
      }
    };
  }, []);

  return null;
}
