import { Outlet } from "react-router-dom";
import VerticalCarousel from "./components/VerticalCarousel";

export default function LoggedOutLayout() {
  return (
    <div className="flex min-h-full flex-1 h-screen bg-white p-6">
      <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <main className="mx-auto w-full max-w-md sm:px-4 md:w-96 md:max-w-sm md:px-0">
          <div className="flex justify-center mb-8">
            <a href="/" aria-label="Home">
              <img
                className="w-40"
                src="/logo/viraly-logo-rect.png"
                alt="Viraly"
              />
            </a>
          </div>

          <div>
            <Outlet />
          </div>
        </main>
      </div>
      <div className="relative hidden overflow-hidden w-0 flex-1 lg:block bg-primary-100/55 rounded-3xl">
        <div className="absolute left-0 bottom-0">
          <div
            className="w-48 h-48 rounded-b-[2rem] rounded-t-[7rem]"
            style={{
              background: "linear-gradient(to top, #E03058, #FFE6EC)",
            }}
          ></div>
        </div>
        <div className="absolute top-0 right-0">
          <div
            className="w-48 h-48 rounded-t-[2rem] rounded-b-[7rem]"
            style={{
              background: "linear-gradient(to bottom, #E03058, #FFE6EC)",
            }}
          ></div>
        </div>

        <div className="w-64 h-full mx-auto">
          <VerticalCarousel />
        </div>
      </div>
    </div>

    // <div className="flex flex-row items-center justify-center h-screen">
    //   <div>
    //     Form
    //     <Outlet />
    //   </div>

    //   <div>image</div>
    // </div>
  );
}
