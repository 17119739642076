/* eslint-disable @typescript-eslint/no-explicit-any */
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ReactNode, useEffect } from "react";
import { PostInstance } from "../../models/post-instance";
import CategorySelector from "./CategorySelector";
import InputField from "../../../../../components/form/InputField";
import VisibilitySelector from "./VisibilitySelector";
import LicenseSelector from "./LicenseSelector";
import ToggleControl from "./ToggleControl";
import { YouTubePostType } from "../../../../../models/entities/post";
import PrimaryTextBox from "../../../../../components/common/PrimaryTextBox";
import eventBusService from "../../../../../services/application/event-bus/event-bus-service";
import EventBusEvents from "../../../../../services/application/event-bus/event-bus-events";
import AttachmentSlot from "../../models/attachment-slot";

interface YouTubeFormProps {
  postInstance: PostInstance;
  attachments: AttachmentSlot[];
  onPostInstanceChanged: (postInstance: PostInstance, touched: boolean) => void;
  children?: ReactNode | (({ primaryForm, secondaryForm }) => ReactNode);
}

interface YouTubeFormInput {
  channelId: string;
  description?: string;
  title: string;
  categoryId: string;
  embeddable: boolean;
  license: string;
  privacyStatus: string;
  madeForKids: boolean;
  notifySubscribers: boolean;
  tags: string[];
  firstComment?: string;
}

const schema = yup
  .object({
    description: yup.string(),
    title: yup.string().required(),
    categoryId: yup.string().required(),
    embeddable: yup.boolean().required(),
    license: yup.string().required(),
    privacyStatus: yup.string().required(),
    madeForKids: yup.boolean().required(),
    notifySubscribers: yup.boolean().required(),
    tags: yup.array().of(yup.string()).required(),
    firstComment: yup.string().max(500),
  })
  .required();

export default function YouTubeForm({
  postInstance,
  onPostInstanceChanged,
  children,
}: YouTubeFormProps) {
  const youTubeConfig = postInstance.postConfig.youTube;

  const form = useForm<YouTubeFormInput>({
    resolver: yupResolver(schema) as any,
    defaultValues: {
      description: youTubeConfig.contentOptions?.description ?? "",
      title: youTubeConfig.contentOptions?.title ?? "",
      categoryId: youTubeConfig.contentOptions?.categoryId ?? "",
      embeddable: youTubeConfig.contentOptions?.embeddable ?? true,
      license: youTubeConfig.contentOptions?.license ?? "",
      privacyStatus: youTubeConfig.contentOptions?.privacyStatus ?? "PUBLIC",
      madeForKids: youTubeConfig.contentOptions?.madeForKids ?? false,
      notifySubscribers:
        youTubeConfig.contentOptions?.notifySubscribers ?? true,
      tags: youTubeConfig.contentOptions?.tags ?? [],
      firstComment: youTubeConfig.firstCommentOptions?.comment ?? "",
    },
  });

  // Receive AI content from AI service and insert it into the textbox
  useEffect(() => {
    const insertAiContent = (content) => {
      form.setValue("description", content);
      onDescriptionChanged(content);
    };

    eventBusService.on(EventBusEvents.INSERT_AI_CONTENT, insertAiContent);

    return () => {
      eventBusService.remove(EventBusEvents.INSERT_AI_CONTENT, insertAiContent);
    };
  });

  const onDescriptionChanged = (text: string) => {
    const updatedPostInstance: PostInstance = {
      ...postInstance,
      postConfig: {
        ...postInstance.postConfig,
        youTube: {
          ...postInstance.postConfig.youTube,
          contentOptions: {
            ...youTubeConfig.contentOptions,
            description: text,
          },
        },
      },
    };

    onPostInstanceChanged(updatedPostInstance, true);
  };

  const onTitleChanged = (event) => {
    const text = event.target.value;

    const updatedPostInstance: PostInstance = {
      ...postInstance,
      title: text,
      postConfig: {
        ...postInstance.postConfig,
        youTube: {
          ...postInstance.postConfig.youTube,
          contentOptions: {
            ...youTubeConfig.contentOptions,
            title: text,
          },
        },
      },
    };

    onPostInstanceChanged(updatedPostInstance, true);
  };

  const onFirstCommentChanged = (text: string) => {
    const updatedPostInstance: PostInstance = {
      ...postInstance,
      postConfig: {
        ...postInstance.postConfig,
        youTube: {
          ...postInstance.postConfig.youTube,
          firstCommentOptions: {
            ...postInstance.postConfig.youTube.firstCommentOptions,
            comment: text,
          },
        },
      },
    };

    onPostInstanceChanged(updatedPostInstance, true);
  };

  const onCategoryChanged = (value: string) => {
    const updatedPostInstance: PostInstance = {
      ...postInstance,
      postConfig: {
        ...postInstance.postConfig,
        youTube: {
          ...postInstance.postConfig.youTube,
          contentOptions: {
            ...youTubeConfig.contentOptions,
            categoryId: value,
          },
        },
      },
    };

    onPostInstanceChanged(updatedPostInstance, true);
  };

  const onPrivacyStatusChanged = (value: string) => {
    const updatedPostInstance: PostInstance = {
      ...postInstance,
      postConfig: {
        ...postInstance.postConfig,
        youTube: {
          ...postInstance.postConfig.youTube,
          contentOptions: {
            ...youTubeConfig.contentOptions,
            privacyStatus: value,
          },
        },
      },
    };

    onPostInstanceChanged(updatedPostInstance, true);
  };

  const onLicenseChanged = (value: string) => {
    const updatedPostInstance: PostInstance = {
      ...postInstance,
      postConfig: {
        ...postInstance.postConfig,
        youTube: {
          ...postInstance.postConfig.youTube,
          contentOptions: {
            ...youTubeConfig.contentOptions,
            license: value,
          },
        },
      },
    };

    onPostInstanceChanged(updatedPostInstance, true);
  };

  const onToggleChanged = (key: string, value: boolean) => {
    const updatedPostInstance: PostInstance = {
      ...postInstance,
      postConfig: {
        ...postInstance.postConfig,
        youTube: {
          ...postInstance.postConfig.youTube,
          contentOptions: {
            ...youTubeConfig.contentOptions,
            [key]: value,
          },
        },
      },
    };

    onPostInstanceChanged(updatedPostInstance, true);
  };

  const selectPostType = (postType: YouTubePostType) => {
    const updatedPostInstance: PostInstance = {
      ...postInstance,
      postConfig: {
        ...postInstance.postConfig,
        youTube: {
          ...postInstance.postConfig.youTube,
          contentOptions: {
            ...postInstance.postConfig.youTube.contentOptions,
            postType: postType,
          },
        },
      },
    };

    onPostInstanceChanged(updatedPostInstance, true);
  };

  const secondaryForm = (
    <div className="md:shadow custom-shadow rounded-md py-3 md:p-3">
      <div className="flex flex-col gap-2">
        <div className="flex justify-start items-center gap-4 relative">
          <div className="text-gray-700 font-semibold text-xs w-24">Title</div>
          <div className="flex-1">
            <InputField
              label="Title"
              placeholder="Video title"
              name="title"
              hideLabel={true}
              formReturn={form}
              onChange={onTitleChanged}
              maxLength={100}
            />
          </div>
        </div>

        <div className="flex justify-start items-center gap-4 relative">
          <div className="text-gray-700 font-semibold text-xs w-24">
            Category
          </div>
          <div className="flex-1">
            <CategorySelector
              categoryId={youTubeConfig.contentOptions?.categoryId}
              onCategorySelected={onCategoryChanged}
            />
          </div>
        </div>

        <div className="flex justify-start items-center gap-4 relative">
          <div className="text-gray-700 font-semibold text-xs w-24">
            Visibility
          </div>
          <div className="flex-1">
            <VisibilitySelector
              value={youTubeConfig.contentOptions?.privacyStatus}
              onValueSelected={onPrivacyStatusChanged}
            />
          </div>
        </div>

        <div className="flex justify-start items-center gap-4 relative">
          <div className="text-gray-700 font-semibold text-xs w-24">
            License
          </div>
          <div className="flex-1">
            <LicenseSelector
              value={youTubeConfig.contentOptions?.license}
              onValueSelected={onLicenseChanged}
            />
          </div>
        </div>

        <div className="mt-4 flex flex-col gap-4">
          <ToggleControl
            initial={youTubeConfig?.contentOptions?.notifySubscribers}
            title="Notify Subscribers"
            description="YouTube will send notification when the video is published"
            onChange={(value) => onToggleChanged("notifySubscribers", value)}
          />

          <ToggleControl
            initial={youTubeConfig?.contentOptions?.embeddable}
            title="Allow Embedding"
            description="Allow others to embed the video on their website"
            onChange={(value) => onToggleChanged("embeddable", value)}
          />

          <ToggleControl
            initial={youTubeConfig?.contentOptions?.madeForKids}
            title="Made for Kids"
            description="This video is made for kids under the age of 13"
            onChange={(value) => onToggleChanged("madeForKids", value)}
          />
        </div>
      </div>
    </div>
  );

  const primaryForm = (
    <div className="flex flex-col">
      <div className="flex flex-row">
        <fieldset className="w-full mt-0.5">
          <div className="flex items-center space-x-10">
            <div
              className="flex items-center cursor-pointer"
              onClick={() => selectPostType("ShortVideo")}
            >
              <input
                name="postType"
                type="radio"
                readOnly
                checked={youTubeConfig.contentOptions.postType == "ShortVideo"}
                className="h-4 w-4 border-gray-300 text-primary-600 focus:ring-transparent cursor-pointer"
              />
              <label className="ml-3 block text-sm font-medium leading-6 text-gray-600 cursor-pointer">
                Short
              </label>
            </div>
            <div className="flex-1"></div>
          </div>
        </fieldset>
      </div>

      {/* Description */}
      <div className="mt-2.5">
        <PrimaryTextBox
          channel={postInstance.channel}
          onFirstCommentChanged={onFirstCommentChanged}
          onTextChanged={onDescriptionChanged}
          textAreaOptions={{
            hideLabel: true,
            maxLength: 5000,
            className: "h-[124px]",
            name: "description",
            label: "Description",
            formHook: form,
            placeholder: "Video description",
          }}
          firstCommentOptions={{
            hideLabel: true,
            rows: 5,
            maxLength: 500,
            name: "firstComment",
            label: "First Comment",
            formHook: form,
            placeholder: "Write your first comment",
          }}
        />
      </div>
    </div>
  );

  return (
    <form>
      <>
        {" "}
        {typeof children === "function"
          ? children({ primaryForm, secondaryForm })
          : children}{" "}
      </>
    </form>
  );
}
