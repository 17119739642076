import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "../../../components/common/Button";
import { useState } from "react";
import { autoSignIn, confirmSignUp, resendSignUpCode } from "aws-amplify/auth";
import { XCircleIcon } from "@heroicons/react/20/solid";
import alertService from "../../../services/alert/alert-service";
import OtpCodeField from "../../../components/form/OtpCodeField";

export default function ConfirmSignup() {
  const navigate = useNavigate();

  const search = useLocation().search;
  const email = new URLSearchParams(search).get("email");
  const [submitting, setSubmitting] = useState(false);
  const [resending, setResending] = useState(false);
  const [error, setError] = useState("");

  const onSubmit = async (code: string) => {
    setError("");

    try {
      setSubmitting(true);

      const result = await confirmSignUp({
        username: email,
        confirmationCode: code,
      });

      await handleConfirmSignUp(result.nextStep.signUpStep);
      alertService.success("Account confirmed successfully");
    } catch (e) {
      setError(e.message);
    } finally {
      setSubmitting(false);
    }
  };

  const handleConfirmSignUp = async (nextStep: string) => {
    console.log(nextStep);

    switch (nextStep) {
      case "DONE":
        navigate("/scheduler");
        break;

      case "COMPLETE_AUTO_SIGN_IN":
        await handleAutoSignIn();
        break;
    }
  };

  const handleAutoSignIn = async () => {
    try {
      const signInOutput = await autoSignIn();
      if (signInOutput.nextStep.signInStep === "DONE") {
        navigate("/scheduler");
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const resendCode = async () => {
    setError("");

    try {
      setResending(true);

      await resendSignUpCode({
        username: email,
      });

      alertService.success("Code resent successfully");
    } catch (e) {
      setError(e.message);
    } finally {
      setResending(false);
    }
  };

  const onCodeComplete = (code: string) => {
    onSubmit(code);
  };

  return (
    <>
      <div className="text-center">
        <h2 className="mt-8 text-2xl/9 font-bold tracking-tight text-gray-900">
          Confirm your account
        </h2>
        <p className="mt-2 text-sm text-gray-500">
          Enter the 6-digit OTP code received at the email address associated
          with the email address entered on the previous step.
        </p>
      </div>

      <div className="mt-10">
        <div>
          {error && (
            <div className="mb-6 rounded-md bg-red-50 p-4">
              <div className="flex">
                <div className="shrink-0">
                  <XCircleIcon
                    aria-hidden="true"
                    className="size-5 text-red-400"
                  />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">{error}</h3>
                </div>
              </div>
            </div>
          )}

          <div className="space-y-4">
            <OtpCodeField
              loading={submitting}
              maxLength={6}
              onComplete={onCodeComplete}
            />
          </div>
        </div>

        <p className="mt-4 text-sm/6 text-gray-500 text-center">
          <Button
            text="Resend Code"
            variant="text"
            color="brand"
            onClick={resendCode}
            disabled={resending || submitting}
            loading={resending}
          />
        </p>

        <p className="mt-4 text-center">
          <Link
            to="/login"
            className="text-sm/6 text-gray-600 hover:text-gray-900 transition-all cursor-pointer"
          >
            ← Return to sign in
          </Link>
        </p>
      </div>
    </>
  );
}
