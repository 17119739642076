import { useEffect } from "react";
import AttachmentSlot from "../../models/attachment-slot";
import { PostInstance } from "../../models/post-instance";

interface PinterestSyncerProps {
  attachments: AttachmentSlot[];
  postInstance: PostInstance;
  onPostInstanceChanged: (postInstance: PostInstance, touched: boolean) => void;
}

export default function PinterestSyncer({
  attachments,
  postInstance,
  onPostInstanceChanged,
}: PinterestSyncerProps) {
  const pinterestConfig = postInstance.postConfig.pinterest;

  useEffect(() => {
    const setSystemPostType = () => {
      let systemPostType = pinterestConfig.contentOptions.systemPostType;

      const initialSystemPostType = systemPostType;

      if (attachments.length == 1) {
        systemPostType = attachments[0].isPhoto ? "ImagePin" : "VideoPin";
      } else if (attachments.length > 1) {
        systemPostType = "Carousel";
      }

      if (systemPostType != initialSystemPostType) {
        const updatedPostInstance: PostInstance = {
          ...postInstance,
          postConfig: {
            ...postInstance.postConfig,
            pinterest: {
              contentOptions: {
                ...pinterestConfig.contentOptions,
                systemPostType: systemPostType,
              },
            },
          },
        };

        onPostInstanceChanged(updatedPostInstance, false);
      }
    };

    setSystemPostType();
  }, [pinterestConfig.contentOptions, postInstance, attachments]);

  return null;
}
