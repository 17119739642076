import Post from "../../../../../models/entities/post";
import MetricItem from "./MetricItem";
import { formatMetricCount } from "../../../../../utils/metric-utils";
import MetricsViewMode from "../../models/metrics-view-mode";

export interface InstagramMetricsProps {
  post: Post;
  metricsViewMode: MetricsViewMode;
}

export default function InstagramMetrics({ post }: InstagramMetricsProps) {
  const metrics = post.metrics.instagram;

  return (
    <div className="flex flex-wrap gap-4 text-center justify-between">
      {post.config.instagram.contentOptions.postType == "Post" && (
        <>
          <MetricItem
            value={formatMetricCount(metrics.impressions)}
            label="Impressions"
          />

          <MetricItem value={formatMetricCount(metrics.reach)} label="Reach" />
        </>
      )}

      {post.config.instagram.contentOptions.postType == "Reel" && (
        <>
          <MetricItem value={formatMetricCount(metrics.reach)} label="Reach" />
          <MetricItem value={formatMetricCount(metrics.plays)} label="Plays" />
        </>
      )}

      <MetricItem value={formatMetricCount(metrics.likes)} label="Likes" />
      <MetricItem
        value={formatMetricCount(metrics.comments)}
        label="Comments"
      />

      <MetricItem value={formatMetricCount(metrics.shares)} label="Shares" />
    </div>
  );
}
