import { useEffect, useMemo, useState } from "react";
import ViewRange from "../calendar/models/view-range";
import todayViewRange from "../../../utils/view-range-utils";
import queryNames from "../../../queries/query-names";
import { useQuery } from "@tanstack/react-query";
import postService from "../../../services/api/post-service";
import dayjs from "dayjs";
import PostsList from "./components/PostsList";
import PostsHeader from "./components/PostsHeader";
import useCurrentSocialSet from "../../../hooks/useCurrentSocialSet";
import useCurrentChannel from "../../../hooks/useCurrentChannel";
import Channel from "../../../models/entities/channel";

export default function Posts() {
  const socialSet = useCurrentSocialSet();
  const channel = useCurrentChannel();
  const [selectedChannels, setSelectedChannels] = useState<Channel[]>([]);
  const [viewRange, setViewRange] = useState<ViewRange>(
    todayViewRange(socialSet.timezone)
  );

  const { isLoading, data } = useQuery({
    queryKey: [
      queryNames.postsByDateRange,
      socialSet?.id,
      viewRange.summary.startDate,
      viewRange.summary.endDate,
    ],
    queryFn: () =>
      !socialSet?.id
        ? Promise.resolve([])
        : postService.list(
            socialSet?.id,
            viewRange.summary.startDate,
            viewRange.summary.endDate,
            viewRange.summary.timezoneOffset
          ),
  });

  useEffect(() => {
    setSelectedChannels([]);
  }, [channel]);

  const filteredPosts = useMemo(() => {
    let result = data || [];

    result = channel
      ? result.filter((post) => post.channel.id === channel.id)
      : selectedChannels.length > 0
      ? result.filter((post) =>
          selectedChannels.some((x) => x.id === post.channel.id)
        )
      : result;

    result.sort((a, b) => {
      return dayjs(a.scheduledAt).isAfter(dayjs(b.scheduledAt)) ? -1 : 1;
    });

    return result;
  }, [channel, data, selectedChannels]);

  const adjustViewRange = (viewRange, direction): ViewRange => {
    const increment = direction === "next" ? 1 : -1;

    // Adjust month based on the direction
    const newDate = dayjs(
      new Date(viewRange.monthly.year, viewRange.monthly.month)
    ).add(increment, "month");

    const startOfMonth = newDate.startOf("month");
    const endOfMonth = newDate.endOf("month");
    const startDate = startOfMonth.format("YYYY-MM-DD");
    const endDate = endOfMonth.format("YYYY-MM-DD");

    return {
      ...viewRange,
      monthly: {
        year: newDate.year(),
        month: newDate.month(),
        startDate: startDate,
        endDate: endDate,
        headerLabel: startOfMonth.format("MMM YYYY"),
      },
      summary: {
        ...viewRange.summary,
        startDate,
        endDate,
      },
    };
  };

  const onNext = () => {
    setViewRange(adjustViewRange(viewRange, "next"));
  };

  const onPrevious = () => {
    setViewRange(adjustViewRange(viewRange, "previous"));
  };

  const onToday = () => {
    setViewRange(todayViewRange(socialSet.timezone));
  };

  return (
    <>
      {/* <SchedulerLayout> */}
      <div className="flex h-full flex-col overflow-hidden rounded-md shadow overflow-y-auto bg-white min-h-[calc(100dvh-15.5rem)] lg:h-[calc(100dvh-14rem)] xl:h-[calc(100dvh-8rem)]">
        <PostsHeader
          viewRange={viewRange}
          unfilteredPosts={data ?? []}
          loading={isLoading}
          showChannelFilters={!channel}
          selectedChannels={selectedChannels}
          onChannelsFiltered={setSelectedChannels}
          onNext={onNext}
          onPrevious={onPrevious}
          onToday={onToday}
        />

        <div className="h-full lg:flex lg:flex-col">
          <div className=" bg-white lg:flex lg:flex-auto lg:flex-col p-4">
            <PostsList
              filteredPosts={filteredPosts ?? []}
              loading={isLoading}
            />
          </div>
        </div>
      </div>
      {/* </SchedulerLayout> */}
    </>
  );
}
