import { useState } from "react";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import Channel from "../../models/entities/channel";
import RedirectChannelConnector from "../../modules/social-sets/channels/components/RedirectChannelConnector";
import Button from "./Button";
import { channelConfig, findChannelConfig } from "../../data/channels-config";
// import { useAnimation } from "../../hooks/useAnimation";
import animation from "../../assets/animations/missing-scopes.json";
import Lottie from "react-lottie-player";

export interface CommentScopeGuardProps {
  channel: Channel;
  close: () => void;
}

export default function CommentScopeGuard({
  channel,
  close,
}: CommentScopeGuardProps) {
  const [isUpdatingChannel, setIsUpdatingChannel] = useState<boolean>(false);

  // const { animation } = useAnimation("/animations/missing-scopes.json");

  const onRefreshChannel = () => {
    setIsUpdatingChannel(true);
  };

  return (
    <div className="w-full">
      <div className="mx-auto space-y-8">
        <div className="flex flex-col gap-8">
          <div className="flex items-center justify-between bg-white rounded-lg shadow py-4 px-6 gap-4">
            <div className="flex items-center space-x-4 py-2 truncate">
              <div className="relative">
                <img
                  referrerPolicy="no-referrer"
                  className="h-12 w-12 flex-none rounded-full bg-gray-50 outline outline-1 outline-gray-50"
                  src={
                    channel.pictureUrl ??
                    findChannelConfig(channel.type).imageUrl
                  }
                />
                <img
                  className="h-5 w-5 flex-none rounded-full border border-white bg-gray-50 absolute top-8 right-0 -mr-2"
                  src={findChannelConfig(channel.type).imageUrl}
                />
              </div>

              <h2 className="flex-1 text-xl font-semibold truncate text-gray-900">
                {channel.name}
              </h2>
            </div>
            <div className="ml-auto">
              <button
                onClick={close}
                type="button"
                className="text-3xl text-gray-900 font-medium leading-none cursor-pointer hover:text-gray-600"
              >
                ×
              </button>
            </div>
          </div>

          <div className="flex flex-col items-center justify-between bg-white rounded-lg shadow pb-4 px-6 gap-8">
            <h2 className="text-xl font-semibold text-gray-900">
              Insufficient Permissions
            </h2>

            <div>
              {animation && (
                <Lottie
                  loop
                  animationData={animation}
                  play
                  style={{ height: 200 }}
                />
              )}
            </div>

            <p className="max-w-md text-center text-gray-500">
              You currently haven't granted Viraly the required permissions to
              add first comments to posts for this channel. To enable this
              feature, please reconnect the channel and ensure you grant all the
              requested permissions from the linked social media platform.
            </p>

            <div className="mt-4">
              <RedirectChannelConnector
                showInfoPopup={false}
                channelConfig={channelConfig.find(
                  (x) => x.type == channel.type
                )}
                channelId={channel.id}
                externalChannelId={channel.externalId}
                socialSetId={channel.socialSetId}
              >
                {({ onClick }) => {
                  return (
                    <Button
                      onClick={() => {
                        onRefreshChannel();
                        onClick();
                      }}
                      text="Reconnect Channel"
                      variant="solid"
                      color="brand"
                      loading={isUpdatingChannel}
                      loadingText="Redirecting"
                      icon={<ArrowPathIcon className="w-4 mr-1" />}
                    />
                  );
                }}
              </RedirectChannelConnector>
            </div>

            <div className="max-w-md text-xs text-center text-gray-500">
              If you click on "Reconnect Channel" your changes in the Post
              Composer popup will be lost since you will be redirected away from
              the page and asked to reauthorize the channel.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
