import { Navigate, Outlet } from "react-router-dom";
import RootLayout from "../layouts/RootLayout";
import MainLayout from "../layouts/MainLayout";
import CreateSocialSet from "../modules/social-sets/create/CreateSocialSet";
import ViewSocialSet from "../modules/social-sets/view/ViewSocialSet";
import ListSocialSets from "../modules/social-sets/list/ListSocialSets";
import NotificationsSettings from "../modules/settings/notifications/NotificationsSettings";
import PlanSettings from "../modules/settings/plan/PlanSettings";
import BillingSettings from "../modules/settings/billing/BillingSettings";
import BootstrapperLayout from "../layouts/BootstrapperLayout";
import GenericError from "../components/errors/GenericError";
import Invite from "../modules/invite/Invite";
import Signup from "../modules/auth/signup/Signup";
import Users from "../modules/settings/users/list/Users";
import CreateUser from "../modules/settings/users/create/CreateUser";
import ViewUser from "../modules/settings/users/view/ViewUser";
import EditUser from "../modules/settings/users/edit/EditUser";
import Signout from "../modules/auth/signout/Signout";
import OAuthCallback from "../modules/oauth/callback/OAuthCallback";
import OAuthRelayCallback from "../modules/oauth/callback/OAuthRelayCallback";
import SchedulerCalendar from "../modules/scheduler/calendar/Calendar";
import ScheduledPosts from "../modules/scheduler/posts/Posts";
import EditWorkspace from "../modules/settings/workspace/edit/EditWorkspace";
import WorkspaceSettings from "../modules/settings/workspace/WorkspaceSettings";
import ProfileSettings from "../modules/settings/profile/ProfileSettings";
import EditProfile from "../modules/settings/profile/edit/EditProfile";
import SignupCoupon from "../modules/auth/signup-coupon/SignupCoupon";
import Affiliate from "../modules/settings/affiliate/Affiliate";
import ActiveSubscriptionGuard from "../guards/ActiveSubscriptionGuard";
import UsersRoot from "../modules/settings/users/UsersRoot";
import SchedulerRoot from "../modules/scheduler/SchedulerRoot";
import Channels from "../modules/social-sets/channels/Channels";
import SchedulerList from "../modules/scheduler/list/SchedulerList";
import ListQueue from "../modules/scheduler/list/queue/ListQueue";
import ListDrafts from "../modules/scheduler/list/drafts/ListDrafts";
import ListApprovals from "../modules/scheduler/list/approvals/ListApprovals";
import ListPublished from "../modules/scheduler/list/published/ListPublished";
import ChannelListSettings from "../modules/scheduler/list/settings/ChannelListSettings";
import MediaLayout from "../modules/scheduler/media/MediaLayout";
import HashtagsLayout from "../modules/scheduler/hashtags/HashtagsLayout";
import OutletErrorBoundary from "../components/error-boundaries/OutletErrorBoundary";
import { ErrorBoundary } from "@sentry/react";
import AuthGuard from "../guards/AuthGuard";
import UnauthGuard from "../guards/UnauthGuard";
import Signin from "../modules/auth/signin/Signin";
import ForgotPassword from "../modules/auth/forgot-password/ForgotPassword";
import ResetPassword from "../modules/auth/reset-password/ResetPassword";
import ConfirmSignup from "../modules/auth/confirm-signup/ConfirmSignup";
import NewPassword from "../modules/auth/new-password/NewPassword";
import { Analytics, SchedulerLayout } from "./lazyComponents";
import LoggedInLayout from "../layouts/LoggedInLayout";
import LoggedOutLayout from "../layouts/LoggedOutLayout/LoggedOutLayout";
import OAuthLayout from "../layouts/OAuthLayout";
import Page404 from "../pages/Page404";
import EditSocialSet from "../modules/social-sets/edit/EditSocialSet";
import SocialSetsRoot from "../modules/social-sets/SocialSetsRoot";
import Settings from "../modules/settings";
import { Suspense } from "react";

const routesConfig = [
  {
    element: (
      <ErrorBoundary fallback={<GenericError />} showDialog>
        <RootLayout />
      </ErrorBoundary>
    ),
    children: [
      { path: "404", element: <Page404 /> },
      {
        path: "/",
        element: <Navigate to="/scheduler" replace />,
      },
      {
        path: "/invite",
        element: <Invite />,
      },
      {
        path: "/sign-out",
        element: <Signout />,
      },
      {
        path: "/coupon-signup/:couponId",
        element: <SignupCoupon />,
      },
      {
        path: "/oauth/cognito-redirect",
        element: <Navigate to="/scheduler" replace />,
      },
      {
        path: "",
        element: (
          <UnauthGuard>
            <LoggedOutLayout />
          </UnauthGuard>
        ),
        children: [
          {
            path: "/login",
            element: <Signin />,
          },
          {
            path: "/signup",
            element: <Signup />,
          },
          {
            path: "/confirm-signup",
            element: <ConfirmSignup />,
          },

          {
            path: "/forgot-password",
            element: <ForgotPassword />,
          },
          {
            path: "/reset-password",
            element: <ResetPassword />,
          },
          {
            path: "/new-password",
            element: <NewPassword />,
          },
        ],
      },
      {
        path: "/",
        element: (
          <AuthGuard>
            <LoggedInLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: "/",
            element: <BootstrapperLayout />,
            children: [
              {
                path: "oauth",
                element: <OAuthLayout />,
                children: [
                  {
                    path: "facebook/callback",
                    element: (
                      <OAuthCallback
                        channelType="Facebook"
                        callbackParams={["code"]}
                      />
                    ),
                  },
                  {
                    path: "instagram/callback",
                    element: (
                      <OAuthCallback
                        channelType="Instagram"
                        useFragments={true}
                        callbackParams={["access_token"]}
                      />
                    ),
                  },
                  {
                    path: "pinterest/callback",
                    element: (
                      <OAuthCallback
                        channelType="Pinterest"
                        callbackParams={["code"]}
                      />
                    ),
                  },
                  {
                    path: "twitter/callback",
                    element: (
                      <OAuthCallback
                        channelType="Twitter"
                        callbackParams={["oauth_verifier", "oauth_token"]}
                        stateProvider="SessionStorage"
                      />
                    ),
                  },
                  {
                    path: "google/callback",
                    element: (
                      <OAuthCallback
                        channelType="YouTube"
                        callbackParams={["code"]}
                      />
                    ),
                  },
                  {
                    path: "tiktok/callback",
                    element: (
                      <OAuthCallback
                        channelType="TikTok"
                        callbackParams={["code"]}
                      />
                    ),
                  },
                  {
                    path: "tiktok/callback-relay",
                    element: (
                      <OAuthRelayCallback to="https://localhost:5173/oauth/tiktok/callback" />
                    ),
                  },
                  {
                    path: "threads/callback",
                    element: (
                      <OAuthCallback
                        channelType="Threads"
                        callbackParams={["code"]}
                      />
                    ),
                  },
                  {
                    path: "threads/callback-relay",
                    element: (
                      <OAuthRelayCallback to="https://localhost:5173/oauth/threads/callback" />
                    ),
                  },
                  {
                    path: "linkedin/callback",
                    element: (
                      <OAuthCallback
                        channelType="LinkedIn"
                        callbackParams={["code"]}
                      />
                    ),
                  },
                ],
              },
              {
                path: "/",
                element: <MainLayout />,
                children: [
                  {
                    path: "/",
                    element: (
                      <ActiveSubscriptionGuard>
                        <Outlet />
                      </ActiveSubscriptionGuard>
                    ),
                    children: [
                      {
                        path: "scheduler",
                        element: <SchedulerRoot />,
                        children: [
                          {
                            index: true,
                            element: <Navigate to="calendar" replace />,
                          },
                          {
                            path: "calendar",
                            element: (
                              <OutletErrorBoundary>
                                <Suspense>
                                  <SchedulerLayout />
                                </Suspense>
                              </OutletErrorBoundary>
                            ),
                            children: [
                              {
                                index: true,
                                element: <Navigate to="view" replace />,
                              },
                              {
                                path: "view",
                                element: (
                                  <OutletErrorBoundary>
                                    <SchedulerCalendar />
                                  </OutletErrorBoundary>
                                ),
                              },
                              {
                                path: "posts",
                                element: (
                                  <OutletErrorBoundary>
                                    <ScheduledPosts />
                                  </OutletErrorBoundary>
                                ),
                              },
                              {
                                path: "media-library",
                                element: (
                                  <OutletErrorBoundary>
                                    <MediaLayout />
                                  </OutletErrorBoundary>
                                ),
                              },
                              {
                                path: "hashtag-lists",
                                element: (
                                  <OutletErrorBoundary>
                                    <HashtagsLayout />
                                  </OutletErrorBoundary>
                                ),
                              },
                            ],
                          },
                          {
                            path: "list",
                            element: (
                              <OutletErrorBoundary>
                                <SchedulerList />
                              </OutletErrorBoundary>
                            ),
                            children: [
                              {
                                index: true,
                                element: <Navigate to="queue" replace />,
                              },
                              {
                                path: "queue",
                                element: (
                                  <OutletErrorBoundary>
                                    <ListQueue />
                                  </OutletErrorBoundary>
                                ),
                              },
                              {
                                path: "drafts",
                                element: (
                                  <OutletErrorBoundary>
                                    <ListDrafts />
                                  </OutletErrorBoundary>
                                ),
                              },
                              {
                                path: "approvals",
                                element: (
                                  <OutletErrorBoundary>
                                    <ListApprovals />
                                  </OutletErrorBoundary>
                                ),
                              },
                              {
                                path: "published",
                                element: (
                                  <OutletErrorBoundary>
                                    <ListPublished />
                                  </OutletErrorBoundary>
                                ),
                              },
                              {
                                path: "settings",
                                element: (
                                  <OutletErrorBoundary>
                                    <ChannelListSettings />
                                  </OutletErrorBoundary>
                                ),
                              },
                            ],
                          },
                        ],
                      },
                      {
                        path: "analytics",
                        element: (
                          <OutletErrorBoundary>
                            <Suspense>
                              {" "}
                              <Analytics />
                            </Suspense>
                          </OutletErrorBoundary>
                        ),
                      },
                      {
                        path: "social-sets",
                        element: <SocialSetsRoot />,
                        children: [
                          {
                            path: "",
                            element: (
                              <OutletErrorBoundary>
                                <ListSocialSets />
                              </OutletErrorBoundary>
                            ),
                          },
                          {
                            path: ":id",
                            element: (
                              <OutletErrorBoundary>
                                <ViewSocialSet />
                              </OutletErrorBoundary>
                            ),
                          },
                          {
                            path: ":id/edit",
                            element: (
                              <OutletErrorBoundary>
                                <EditSocialSet />
                              </OutletErrorBoundary>
                            ),
                          },
                          {
                            path: "create",
                            element: (
                              <OutletErrorBoundary>
                                <CreateSocialSet />
                              </OutletErrorBoundary>
                            ),
                          },
                          {
                            path: ":id/channels",
                            element: (
                              <OutletErrorBoundary>
                                <Channels />
                              </OutletErrorBoundary>
                            ),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "settings",
                    element: <Settings />,
                    children: [
                      {
                        index: true,
                        element: <Navigate to="profile" replace />,
                      },
                      {
                        path: "profile",
                        element: (
                          <OutletErrorBoundary>
                            <ProfileSettings />
                          </OutletErrorBoundary>
                        ),
                      },
                      {
                        path: "profile/edit",
                        element: (
                          <OutletErrorBoundary>
                            <EditProfile />
                          </OutletErrorBoundary>
                        ),
                      },
                      {
                        path: "workspace",
                        element: (
                          <OutletErrorBoundary>
                            <WorkspaceSettings />
                          </OutletErrorBoundary>
                        ),
                      },
                      {
                        path: "workspace/edit",
                        element: (
                          <OutletErrorBoundary>
                            <EditWorkspace />
                          </OutletErrorBoundary>
                        ),
                      },
                      {
                        path: "notifications",
                        element: (
                          <OutletErrorBoundary>
                            <NotificationsSettings />
                          </OutletErrorBoundary>
                        ),
                      },
                      {
                        path: "plan",
                        element: (
                          <OutletErrorBoundary>
                            <PlanSettings />
                          </OutletErrorBoundary>
                        ),
                      },
                      {
                        path: "billing",
                        element: (
                          <OutletErrorBoundary>
                            <BillingSettings />
                          </OutletErrorBoundary>
                        ),
                      },
                      {
                        path: "users",
                        element: <UsersRoot />,
                        children: [
                          {
                            index: true,
                            element: (
                              <OutletErrorBoundary>
                                <Users />
                              </OutletErrorBoundary>
                            ),
                          },
                          {
                            path: "invite",
                            element: (
                              <OutletErrorBoundary>
                                <CreateUser />
                              </OutletErrorBoundary>
                            ),
                          },
                          {
                            path: ":id",
                            element: (
                              <OutletErrorBoundary>
                                <ViewUser />
                              </OutletErrorBoundary>
                            ),
                          },
                          {
                            path: ":id/edit",
                            element: (
                              <OutletErrorBoundary>
                                <EditUser />
                              </OutletErrorBoundary>
                            ),
                          },
                        ],
                      },
                      {
                        path: "affiliate",
                        element: (
                          <OutletErrorBoundary>
                            <Affiliate />
                          </OutletErrorBoundary>
                        ),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      // No match
      { path: "*", element: <Navigate to="/404" replace /> },
    ],
  },
];

export default routesConfig;
