import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Button from "../../../components/common/Button";
import { useState } from "react";
import { confirmSignIn } from "aws-amplify/auth";
import { XCircleIcon } from "@heroicons/react/20/solid";
import PasswordField from "../../../components/form/PasswordField";
import alertService from "../../../services/alert/alert-service";
import ValidationMessages from "../../../data/validation-messages";

const schema = yup
  .object({
    password: yup.string().required(ValidationMessages.NewPasswordRequired),
    repeatPassword: yup
      .string()
      .required(ValidationMessages.RepeatPasswordRequired)
      .oneOf([yup.ref("password")], ValidationMessages.PasswordsMustMatch),
  })
  .required();

interface FormInput {
  password?: string;
  repeatPassword?: string;
}

export default function NewPassword() {
  const navigate = useNavigate();

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState("");

  const form = useForm<FormInput>({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const submitForm = () => {
    form.handleSubmit(onSubmit)();
  };

  const onSubmit = async (input: FormInput) => {
    setError("");

    try {
      setSubmitting(true);

      const result = await confirmSignIn({
        challengeResponse: input.password,
      });

      await handleConfirmSignIn(result.nextStep.signInStep);
    } catch (e) {
      setError(e.message);
    } finally {
      setSubmitting(false);
    }
  };

  const handleConfirmSignIn = async (nextStep: string): Promise<void> => {
    console.log(nextStep);

    switch (nextStep) {
      case "DONE":
        navigate("/scheduler");
        break;

      default:
        alertService.error(`Could not handle next step: ${nextStep}`);
    }
  };

  return (
    <>
      <div className="text-center">
        <h2 className="mt-8 text-2xl/9 font-bold tracking-tight text-gray-900">
          Change your password
        </h2>
        <p className="mt-2 text-sm text-gray-500">
          Your current password has expired. Please create a new password to
          continue.
        </p>
      </div>

      <div className="mt-10">
        <div>
          {error && (
            <div className="mb-6 rounded-md bg-red-50 p-4">
              <div className="flex">
                <div className="shrink-0">
                  <XCircleIcon
                    aria-hidden="true"
                    className="size-5 text-red-400"
                  />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">{error}</h3>
                </div>
              </div>
            </div>
          )}

          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <div>
              <PasswordField
                label="Password"
                name="password"
                formReturn={form}
              />
            </div>

            <div>
              <PasswordField
                label="Confirm password"
                name="repeatPassword"
                formReturn={form}
              />
            </div>

            <div>
              <Button
                onClick={submitForm}
                text="Change password"
                color="brand"
                variant="solid"
                disabled={submitting}
                loading={submitting}
                type="submit"
                className="w-full"
              />
            </div>
          </form>
        </div>

        <p className="mt-4 text-center">
          <Link
            to="/login"
            className="text-sm/6 text-gray-600 hover:text-gray-900 transition-all cursor-pointer"
          >
            ← Return to sign in
          </Link>
        </p>
      </div>
    </>
  );
}
