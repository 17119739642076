export default class ValidationMessages {
  // Email
  static readonly EmailRequired = "Please enter an email address";
  static readonly EmailFormat = "The entered email is not a valid email format";

  // Password
  static readonly PasswordRequired = "Please enter your password";
  static readonly RepeatPasswordRequired = "Please repeat your new password";
  static readonly PasswordsMustMatch = "Passwords must match";
  static readonly NewPasswordRequired = "Please enter your new password";

  // OTP Code
  static readonly OtpCodeRequired =
    "Please enter the OTP code sent to your email";
  static readonly OtpCodeDigitsOnly = "The Code should have digits only";
  static readonly OtpCodeLength = (length: number) =>
    `Code length must be exactly ${length} digits`;

  // Full Name
  static readonly FullNameRequired = "Please enter full name";
  static readonly FullNameLength = (length: number) =>
    `Full name must be at most ${length} characters`;

  // Hashtags
  static readonly HashtagsRequired = "Please enter at least one hashtag";
  static readonly TopicRequired = "Please enter a topic";

  // Workspace
  static readonly WorkspaceNameRequired = "Please enter workspace name";
  static readonly WorkspaceNameLength = (length: number) =>
    `Workspace name must be at most ${length} characters`;

  // Social set
  static readonly SocialSetNameRequired = "Please enter social set name";
  static readonly SocialSetNameMaxLength = (length: number) =>
    `Social set name must be at most ${length} characters`;
  static readonly SocialSetColorRequired = "Please select a color";
  static readonly SocialSetTimezoneRequired = "Please select a timezone";
}
