// import { useAnimation } from "../../hooks/useAnimation";
import Lottie from "react-lottie-player";
import animation from "../../assets/animations/coming-soon.json";

interface ComingSoonProps {
  title?: string;
  description?: string;
}

export default function ComingSoon({ title, description }: ComingSoonProps) {
  // const { animation } = useAnimation("/animations/coming-soon.json");

  return (
    <div className="flex flex-col gap-4 items-center justify-center">
      <div className="text-lg/7 font-medium tracking-tight text-gray-950">
        {title ?? "Coming soon"}
      </div>

      {animation && (
        <Lottie loop animationData={animation} play style={{ height: 250 }} />
      )}

      <div className="text-sm/6 text-gray-600 max-w-lg">
        {description ??
          "This feature is coming soon. Stay tuned for more updates."}
      </div>
    </div>
  );
}
