import Post from "../../../../../models/entities/post";
import MetricItem from "./MetricItem";
import { formatMetricCount } from "../../../../../utils/metric-utils";
import MetricsViewMode from "../../models/metrics-view-mode";

export interface TikTokMetricsProps {
  post: Post;
  metricsViewMode: MetricsViewMode;
}

export default function TikTokMetrics({ post }: TikTokMetricsProps) {
  const metrics = post.metrics.tikTok;

  return (
    <div className="flex flex-wrap gap-4 text-center">
      <MetricItem value={formatMetricCount(metrics.views)} label="Views" />
      <MetricItem value={formatMetricCount(metrics.likes)} label="Likes" />
      <MetricItem
        value={formatMetricCount(metrics.comments)}
        label="Comments"
      />
      <MetricItem value={formatMetricCount(metrics.shares)} label="Shares" />
    </div>
  );
}
