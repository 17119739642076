import Button from "../../../../components/common/Button";
import animation from "../../../../assets/animations/empty-box.json";
import Lottie from "react-lottie-player";

export interface EmptyHashtagListsProps {
  onOpenCreatePopup: () => void;
}

export default function EmptyHashtagLists({
  onOpenCreatePopup,
}: EmptyHashtagListsProps) {
  return (
    <div className="h-full flex flex-col items-center justify-center gap-3 text-center">
      <div>
        <Lottie loop animationData={animation} play style={{ height: 200 }} />
      </div>
      <div className="text-gray-800 text-md font-medium">
        Whoa, your hashtag library is empty!
      </div>

      <div className="text-gray-500 text-sm max-w-lg">
        Time to fix that! Start building your hashtag lists now so you can boost
        your posts with the perfect tags when you’re ready to share.
      </div>

      <div className="mt-6">
        <Button
          text="Create Hashtag List"
          color="brand"
          onClick={onOpenCreatePopup}
        />
      </div>
    </div>
  );
}
