import { useContext, useMemo } from "react";
import GlobalStateContext from "../state/global-state/GlobalStateContext";
import SocialSet from "../models/entities/social-set";

const useSocialSets = (): SocialSet[] | null => {
  const { state } = useContext(GlobalStateContext);

  const socialSet = useMemo(() => {
    return state?.socialSets;
  }, [state.socialSets]);

  return socialSet;
};

export default useSocialSets;
