import GenerateUploadUrlRequest from "../../models/api-requests/get-upload-url-request";
import GetUploadUrlResponse from "../../models/api-responses/get-upload-url";
import Attachment, { AttachmentInfo } from "../../models/entities/attachment";
import {
  generateVideoThumbnail,
  getImageDimensions,
  getVideoDimensions,
  replaceFileExtension,
  urlToFile,
} from "../../utils/file-utils";
import ApiService from "../core/api-service";
import storageService from "../storage/storage-service";
import { v4 as uuidv4 } from "uuid";

class AttachmentService {
  getUploadUrl(
    model: GenerateUploadUrlRequest,
    abortSignal?: AbortSignal
  ): Promise<GetUploadUrlResponse> {
    return ApiService.post<GetUploadUrlResponse>(`attachments`, model, {
      signal: abortSignal,
    });
  }

  async processPhoto(
    attachment: Attachment,
    file: File,
    abortSignal?: AbortSignal
  ): Promise<Attachment> {
    const videoUrl = URL.createObjectURL(file);
    const { width, height } = await getImageDimensions(videoUrl);

    if (abortSignal?.aborted) {
      return attachment;
    }

    return ApiService.put<Attachment>(
      `attachments/${attachment.id}/process-photo`,
      {
        width: width,
        height: height,
      },
      { signal: abortSignal }
    );
  }

  async preProcessDocument(
    file: File
  ): Promise<{ preview: AttachmentInfo; thumbnailPreview: AttachmentInfo }> {
    const imageUrl = URL.createObjectURL(file);

    const preview: AttachmentInfo = {
      url: imageUrl,
      width: 0,
      height: 0,
      fileName: file.name,
      fileSize: file.size,
      duration: 0,
      resolution: 0,
      aspectRatio: "0",
      mimeType: file.type,
      s3Bucket: "",
      s3Key: "",
    };

    const thumbnailPreview: AttachmentInfo = {
      ...preview,
    };

    return { preview, thumbnailPreview };
  }

  async preProcessImage(
    file: File
  ): Promise<{ preview: AttachmentInfo; thumbnailPreview: AttachmentInfo }> {
    const { width, height } = await this.getFileDimensions(file);
    const imageUrl = URL.createObjectURL(file);

    const preview: AttachmentInfo = {
      url: imageUrl,
      width: width,
      height: height,
      fileName: file.name,
      fileSize: file.size,
      duration: 0,
      resolution: height,
      aspectRatio: `${width}x${height}`,
      mimeType: file.type,
      s3Bucket: "",
      s3Key: "",
    };

    const thumbnailPreview: AttachmentInfo = {
      ...preview,
    };

    return { preview, thumbnailPreview };
  }

  async preProcessVideo(
    file: File
  ): Promise<{ preview: AttachmentInfo; thumbnailPreview: AttachmentInfo }> {
    const videoUrl = URL.createObjectURL(file);
    const { width, height, duration, video } = await getVideoDimensions(
      videoUrl
    );
    const thumbnailData = await generateVideoThumbnail(video);
    const thumbnailName = replaceFileExtension(file.name, "jpg");
    const thumbnailFile = await urlToFile(thumbnailData, thumbnailName);
    const thumbnailUrl = URL.createObjectURL(thumbnailFile);

    const preview: AttachmentInfo = {
      url: videoUrl,
      width: width,
      height: height,
      fileName: file.name,
      fileSize: file.size,
      duration: duration,
      resolution: height,
      aspectRatio: `${width}x${height}`,
      mimeType: file.type,
      s3Bucket: "",
      s3Key: "",
    };

    const thumbnailPreview: AttachmentInfo = {
      url: thumbnailUrl,
      width: width,
      height: height,
      fileName: thumbnailFile.name,
      fileSize: thumbnailFile.size,
      duration: 0,
      resolution: height,
      aspectRatio: `${width}x${height}`,
      mimeType: thumbnailFile.type,
      s3Bucket: "",
      s3Key: "",
    };

    return { preview, thumbnailPreview };
  }

  async getFileDimensions(
    file: File
  ): Promise<{ width: number; height: number; duration: number }> {
    const dataUrl = URL.createObjectURL(file);
    const isPhoto = file.type.startsWith("image");
    const isVideo = file.type.startsWith("video");

    try {
      if (isPhoto) {
        const { width, height } = await getImageDimensions(dataUrl);

        return {
          width,
          height,
          duration: 0,
        };
      } else if (isVideo) {
        const disposeVideo = true;
        const { width, height, duration } = await getVideoDimensions(
          dataUrl,
          disposeVideo
        );

        return {
          width,
          height,
          duration,
        };
      }
    } finally {
      if (dataUrl) {
        URL.revokeObjectURL(dataUrl);
      }
    }
  }

  async processVideo(
    attachment: Attachment,
    file: File,
    abortSignal?: AbortSignal
  ): Promise<Attachment> {
    const videoUrl = URL.createObjectURL(file);
    let videoReference = null;

    try {
      const { width, height, duration, video } = await getVideoDimensions(
        videoUrl
      );
      const thumbnailData = await generateVideoThumbnail(video);
      const thumbnailName = replaceFileExtension(file.name, "jpg");
      const thumbnailFile = await urlToFile(thumbnailData, thumbnailName);

      videoReference = video;

      const {
        url: thumbnailUploadUrl,
        metadata: thumbnailUploadMetadata,
        attachment: thumbnailAttachment,
      } = await this.getUploadUrl({
        id: uuidv4().substring(0, 8),
        socialSetId: attachment.socialSetId,
        fileName: thumbnailName,
        fileSize: thumbnailFile.size,
        mimeType: thumbnailFile.type,
        role: "VideoThumbnail",
      });

      await storageService.uploadFileToS3(
        thumbnailUploadUrl,
        thumbnailUploadMetadata,
        thumbnailFile,
        null,
        abortSignal
      );

      const processedThumbnail = await this.processPhoto(
        thumbnailAttachment,
        thumbnailFile,
        abortSignal
      );

      attachment = await ApiService.put<Attachment>(
        `attachments/${attachment.id}/process-video`,
        {
          width: width,
          height: height,
          duration: duration,
          thumbnails: processedThumbnail.thumbnails,
        },
        { signal: abortSignal }
      );
    } finally {
      if (videoUrl) {
        URL.revokeObjectURL(videoUrl);
      }

      if (videoReference) {
        videoReference.remove();
      }
    }

    return attachment;
  }
}

export default new AttachmentService();
