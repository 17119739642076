/* eslint-disable @typescript-eslint/no-explicit-any */
import SocialSet from "../../../../models/entities/social-set";
import Button from "../../../../components/common/Button";
import { useContext, useEffect, useRef, useState } from "react";
import DeleteDialog from "../../../../components/dialogs/DeleteDialog";
import alertService from "../../../../services/alert/alert-service";
import socialSetsService from "../../../../services/api/social-sets-service";
import { Link, useNavigate } from "react-router-dom";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import {
  TrashIcon,
  ArrowPathIcon,
  EllipsisVerticalIcon,
  CheckBadgeIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";
import Channel from "../../../../models/entities/channel";
import channelService from "../../../../services/api/channel-service";
import Spinner from "../../../../components/common/Spinner";

import { GetSocialSetStatsResponse } from "../../../../models/api-responses/get-social-set-stats";
import ChannelAccountTypes from "../../../../data/channel-account-types";
import clsx from "clsx";
import planQuotaService from "../../../../services/application/plan-quota-service";
import GlobalStateContext from "../../../../state/global-state/GlobalStateContext";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import channelProviderService from "../../../../services/application/channel-provider-service";
import OAuthConstants from "../../../../constants/oauth";
import {
  findChannelConfig,
  channelConfig,
} from "../../../../data/channels-config";
import GetChannelStatsResponse from "../../../../models/api-responses/get-channel-stats";
import ChannelsList from "../../channels/components/ChannelsList";
import ChannelsPlan from "../../channels/components/ChannelsPlan";
import ChannelsQuota from "../../channels/components/ChannelsQuota";
import RedirectChannelConnector from "../../channels/components/RedirectChannelConnector";
import { refetchQuery } from "../../../../queries/query-utils";
import queryNames from "../../../../queries/query-names";
import actionTypes from "../../../../state/global-state/action-types";

interface SocialSetProfileProps {
  socialSet: SocialSet;
  socialSets: SocialSet[];
  refreshChannelId?: string;
}

export default function SocialSetProfile({
  socialSet,
  socialSets,
  refreshChannelId,
}: SocialSetProfileProps) {
  const navigate = useNavigate();

  const deleteDialogRef = useRef(null);
  const disconnectChannelDialogRef = useRef(null);

  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [updatingChannel, setUpdatingChannel] = useState<Channel>(null);
  const [isUpdatingChannel, setIsUpdatingChannel] = useState<boolean>(false);
  const [updatingChannelLabel, setUpdatingChannelLabel] = useState<string>("");
  const [socialSetStats, setSocialSetStats] =
    useState<GetSocialSetStatsResponse>();
  const [channelStats, setChannelStats] = useState<GetChannelStatsResponse>();
  const [isLoadingStats, setIsLoadingStats] = useState<boolean>(false);

  const { state, dispatch } = useContext(GlobalStateContext);

  const channels = socialSets.flatMap((x) => x.channels);
  const subscription = state.currentTenant.subscription;
  const channelsQuota = planQuotaService.getChannelsLimit(subscription);
  const channelsCount = channels.length;
  const quotaFilled = channelsQuota == channelsCount;

  // Initiate channel refresh if required
  useEffect(() => {
    const process = async () => {
      if (refreshChannelId) {
        const channel = channels.find((x) => x.id == refreshChannelId);

        if (channel) {
          onRefreshChannel(channel);

          const state = JSON.stringify({
            action: OAuthConstants.RefreshChannel,
            socialSetId: socialSet.id,
            channelId: refreshChannelId,
            externalChannelId: channel.externalId,
          });

          const url = await channelProviderService.getAuthorizationUrl(
            channel.type,
            state
          );
          window.location.href = url;
        } else {
          alertService.error(
            "The channel you are trying to refresh is not found"
          );
        }
      }
    };

    process();
    // No dependencies required, execute only once on load
  }, []);

  const onDelete = async () => {
    if (socialSets.length == 1) {
      alertService.info("You must keep at least one social set");
      return;
    }

    deleteDialogRef.current.openDialog();

    if (socialSet.channels.length) {
      setIsLoadingStats(true);

      try {
        const stats = await socialSetsService.getStats(socialSet.id);
        setSocialSetStats(stats);
      } finally {
        setIsLoadingStats(false);
      }
    }
  };

  const onRefreshChannel = (channel: Channel) => {
    setUpdatingChannel(channel);
    setIsUpdatingChannel(true);
    setUpdatingChannelLabel("Redirecting");
  };

  const onDisconnectChannel = async (channel: Channel) => {
    disconnectChannelDialogRef.current.openDialog();

    setUpdatingChannel(channel);
    setIsLoadingStats(true);

    try {
      const stats = await channelService.getStats(channel.id);
      setChannelStats(stats);
    } finally {
      setIsLoadingStats(false);
    }
  };

  const deleteSocialSetConfirmed = async () => {
    setIsDeleting(true);

    try {
      await socialSetsService.delete(socialSet.id.toString());
      await refetchQuery([queryNames.socialSets]);

      alertService.success("Social set successfully deleted");

      dispatch({
        type: actionTypes.SET_CURRENT_SOCIAL_SET,
        payload: socialSets.filter((x) => x.id != socialSet.id)?.[0] ?? null,
      });

      navigate(`/social-sets`, { replace: true });
    } finally {
      setIsDeleting(false);
    }
  };

  const disconnectChannelConfirmed = async () => {
    setIsUpdatingChannel(true);
    setUpdatingChannelLabel("Disconnecting");

    try {
      await channelService.delete(updatingChannel.id.toString());
      await refetchQuery([queryNames.socialSets]);

      alertService.success("Channel successfully disconnected");
    } finally {
      setUpdatingChannel(null);
      setIsUpdatingChannel(false);
    }
  };

  return (
    <>
      <div className="bg-white shadow sm:rounded-lg min-h-[315px] px-4 py-5 sm:px-6">
        {/* Header section */}
        <div className="border-b border-gray-200 bg-white pb-5">
          <div className="flex flex-nowrap items-center justify-between gap-4">
            <div className="flex gap-4 items-center truncate">
              <div
                className="flex flex-shrink-0 items-center justify-center rounded-full text-sm font-medium text-white w-9 h-9"
                style={{ backgroundColor: socialSet.colorHex }}
              >
                {socialSet.code}
              </div>
              <div className="flex flex-col truncate">
                <h3 className="text-base font-semibold leading-7 text-gray-900 truncate">
                  {socialSet.name}
                </h3>
                <p className="text-sm text-gray-500 ">
                  View, edit or delete social set
                </p>
              </div>
            </div>
            <div className="flex sm:hidden flex-shrink-0 gap-2">
              <div className="flex-shrink-0 gap-2 flex">
                <Menu as="div" className="relative inline-block text-left">
                  <MenuButton as="div">
                    <Button
                      type="button"
                      variant="text"
                      color="slate"
                      className="rounded-full"
                      disabled={isDeleting || isUpdatingChannel}
                      text=""
                    >
                      {(isDeleting || isUpdatingChannel) && (
                        <>
                          <Spinner size="4" />
                        </>
                      )}
                      {!(isDeleting || isUpdatingChannel) && (
                        <>
                          <EllipsisVerticalIcon className="-mr-1 w-6 text-gray-400" />
                        </>
                      )}
                    </Button>
                  </MenuButton>

                  <MenuItems
                    transition
                    anchor="bottom end"
                    className="[--anchor-gap:0.25rem] [--anchor-padding:0px] z-10 w-56 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5"
                  >
                    <div className="py-1">
                      <MenuItem>
                        {({ focus }) => (
                          <Link
                            to="edit"
                            className={clsx(
                              focus
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "group flex items-center px-4 py-2 text-sm"
                            )}
                          >
                            <PencilIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                            Edit social set
                          </Link>
                        )}
                      </MenuItem>
                      <MenuItem>
                        {({ focus }) => (
                          <div
                            onClick={onDelete}
                            className={clsx(
                              focus
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "group flex items-center px-4 py-2 text-sm"
                            )}
                          >
                            <TrashIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                            Delete social set
                          </div>
                        )}
                      </MenuItem>
                    </div>
                  </MenuItems>
                </Menu>
              </div>
            </div>
            <div className="hidden sm:flex flex-shrink-0 gap-2">
              <Button
                variant="outline"
                color="brand"
                className="w-24"
                onClick={onDelete}
                disabled={isDeleting}
                loading={isDeleting}
                text="Delete"
              />
              <Button
                to="edit"
                variant="solid"
                color="brand"
                className="w-24"
                disabled={isDeleting}
                text="Edit"
              />
            </div>
          </div>
        </div>
        {/* Main content */}
        <div className="flex-1 pb-5">
          <div role="list" className="mt-3">
            <div className="mt-6 max-w-lg w-full mx-auto">
              <div className="pb-5">
                <div className="flex flex-wrap items-center justify-between sm:flex-nowrap">
                  <h2 className="text-lg sm:text-2xl font-bold text-gray-600 flex">
                    {socialSet.channels.length > 0
                      ? "My Channels"
                      : "Connect a Channel"}
                  </h2>
                  {socialSet.channels.length > 0 && (
                    <Button
                      to="channels"
                      color="brand"
                      variant="solid"
                      text="Connect Channel"
                      data-tooltip-id={
                        quotaFilled ? "tooltip-placeholder" : null
                      }
                      data-tooltip-content="Can't connect new channels. Please upgrade your plan."
                      disabled={quotaFilled}
                      onClick={() =>
                        quotaFilled ? navigate("/settings/plan") : null
                      }
                    />
                  )}
                </div>
              </div>

              {/* Show connected channels for the current social set if at least one channel is already connected */}
              {socialSet.channels.length > 0 && (
                <>
                  <div className="flex-1">
                    <div role="list" className="">
                      <div className="mx-auto">
                        <div className="mt-1 gap-4 flex flex-col">
                          <div className="flex flex-col gap-2">
                            <ul role="list" className="grid grid-cols-1 gap-4">
                              <li className="mb-4">
                                <ChannelsPlan />
                              </li>
                              <li>
                                <ChannelsQuota />
                              </li>

                              {socialSet.channels.map((channel) => (
                                <li
                                  key={channel.id}
                                  className="flex flex-col border border-gray-300 rounded-lg "
                                >
                                  {channel.status == "Expired" && (
                                    <div
                                      className="flex items-center gap-1 rounded-t-md bg-red-100 px-2 py-1 text-xs text-red-700 truncate"
                                      data-tooltip-id="tooltip-placeholder"
                                      data-tooltip-content={
                                        channel.errorMessage
                                      }
                                    >
                                      <ExclamationCircleIcon className="w-4" />
                                      <span className="font-medium">
                                        Connection Expired
                                      </span>
                                      <span className="text-red-600 truncate">
                                        - {channel.errorMessage}
                                      </span>
                                    </div>
                                  )}
                                  <div className="flex items-center justify-between gap-2 py-4 px-4">
                                    <div className="flex min-w-0 gap-x-4 items-center">
                                      <div className="relative">
                                        <img
                                          referrerPolicy="no-referrer"
                                          className="h-9 w-9 flex-none rounded-full bg-gray-50 outline outline-1 outline-gray-50"
                                          src={
                                            channel.pictureUrl ??
                                            findChannelConfig(channel.type)
                                              .imageUrl
                                          }
                                        />
                                        <img
                                          className="h-5 w-5 flex-none rounded-full border border-white border-3 bg-gray-50 absolute top-6 right-0 -mr-2"
                                          src={
                                            findChannelConfig(channel.type)
                                              .imageUrl
                                          }
                                        />
                                      </div>
                                      <div className="min-w-0 flex-1">
                                        <p className="flex gap-2 items-center font-medium leading-6 text-gray-900 truncate">
                                          <span className="truncate">
                                            {channel.name}
                                          </span>
                                        </p>
                                        <p className="text-left text-sm leading-4 text-gray-500 whitespace-nowrap">
                                          {
                                            ChannelAccountTypes[
                                              channel?.accountType
                                            ]?.label
                                          }
                                        </p>
                                      </div>
                                    </div>

                                    {channel.status == "Active" && (
                                      <>
                                        {!(
                                          isUpdatingChannel &&
                                          updatingChannel?.id == channel.id
                                        ) && (
                                          <div className="ml-auto mr-2 hidden sm:block">
                                            <CheckBadgeIcon className="h-5 w-5 text-green-600" />
                                          </div>
                                        )}

                                        <div>
                                          {isUpdatingChannel &&
                                          updatingChannel?.id == channel.id ? (
                                            <>
                                              <div className="flex items-center gap-2">
                                                <span className="text-xs text-gray-600">
                                                  {updatingChannelLabel}
                                                </span>{" "}
                                                <Spinner size="6" />
                                              </div>
                                            </>
                                          ) : (
                                            <>
                                              <Menu
                                                as="div"
                                                className="relative flex-none"
                                              >
                                                <MenuButton
                                                  className={clsx(
                                                    "-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900",
                                                    isUpdatingChannel
                                                      ? "opacity-50"
                                                      : ""
                                                  )}
                                                  disabled={isUpdatingChannel}
                                                >
                                                  <EllipsisVerticalIcon className="h-6 w-6" />
                                                </MenuButton>

                                                <MenuItems
                                                  transition
                                                  anchor="bottom end"
                                                  className="[--anchor-gap:0.25rem] [--anchor-padding:0px] z-10 w-60 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 py-2"
                                                >
                                                  <MenuItem>
                                                    {() => (
                                                      <div className="mx-2">
                                                        <RedirectChannelConnector
                                                          showInfoPopup={false}
                                                          channelConfig={channelConfig.find(
                                                            (x) =>
                                                              x.type ==
                                                              channel.type
                                                          )}
                                                          channelId={channel.id}
                                                          externalChannelId={
                                                            channel.externalId
                                                          }
                                                          socialSetId={
                                                            socialSet.id
                                                          }
                                                        >
                                                          {({ onClick }) => {
                                                            return (
                                                              <button
                                                                onClick={() => {
                                                                  onRefreshChannel(
                                                                    channel
                                                                  );
                                                                  onClick();
                                                                }}
                                                                type="button"
                                                                className="flex items-center hover:bg-gray-50 rounded-md p-2 w-full h-full text-sm leading-6 text-gray-900"
                                                              >
                                                                <ArrowPathIcon
                                                                  width="20"
                                                                  className="mr-2"
                                                                />
                                                                Refresh
                                                                Connection
                                                              </button>
                                                            );
                                                          }}
                                                        </RedirectChannelConnector>
                                                      </div>
                                                    )}
                                                  </MenuItem>
                                                  <MenuItem>
                                                    {() => (
                                                      <div className="mx-2">
                                                        <button
                                                          onClick={() => {
                                                            onDisconnectChannel(
                                                              channel
                                                            );
                                                          }}
                                                          type="button"
                                                          className="flex items-center hover:bg-gray-50 rounded-md p-2 w-full h-full text-sm leading-6 text-gray-900"
                                                        >
                                                          <TrashIcon
                                                            width="20"
                                                            className="mr-2"
                                                          />
                                                          Disconnect Channel
                                                        </button>
                                                      </div>
                                                    )}
                                                  </MenuItem>
                                                </MenuItems>
                                              </Menu>
                                            </>
                                          )}
                                        </div>
                                      </>
                                    )}

                                    {channel.status == "Expired" && (
                                      <>
                                        <div className="flex flex-row items-center gap-2">
                                          <RedirectChannelConnector
                                            showInfoPopup={false}
                                            channelConfig={channelConfig.find(
                                              (x) => x.type == channel.type
                                            )}
                                            channelId={channel.id}
                                            externalChannelId={
                                              channel.externalId
                                            }
                                            socialSetId={socialSet.id}
                                          >
                                            {({ onClick }) => {
                                              return (
                                                <Button
                                                  onClick={() => {
                                                    onRefreshChannel(channel);
                                                    onClick();
                                                  }}
                                                  text="Reconnect"
                                                  variant="solid"
                                                  color="brand"
                                                  loading={
                                                    isUpdatingChannel &&
                                                    updatingChannel?.id ==
                                                      channel.id
                                                  }
                                                  loadingText="Redirecting"
                                                  icon={
                                                    <ArrowPathIcon className="w-4 mr-1" />
                                                  }
                                                />
                                              );
                                            }}
                                          </RedirectChannelConnector>
                                          <Menu
                                            as="div"
                                            className="relative flex-none"
                                          >
                                            <MenuButton
                                              className={clsx(
                                                "-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900",
                                                isUpdatingChannel
                                                  ? "opacity-50"
                                                  : ""
                                              )}
                                              disabled={isUpdatingChannel}
                                            >
                                              <EllipsisVerticalIcon className="h-6 w-6" />
                                            </MenuButton>

                                            <MenuItems
                                              transition
                                              anchor="bottom end"
                                              className="[--anchor-gap:0.25rem] [--anchor-padding:0px] z-10 w-60 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5"
                                            >
                                              <MenuItem>
                                                {() => (
                                                  <div className="mx-2">
                                                    <button
                                                      onClick={() => {
                                                        onDisconnectChannel(
                                                          channel
                                                        );
                                                      }}
                                                      type="button"
                                                      className="flex items-center hover:bg-gray-50 rounded-md p-2 w-full h-full text-sm leading-6 text-gray-900"
                                                    >
                                                      <TrashIcon
                                                        width="20"
                                                        className="mr-2"
                                                      />
                                                      Disconnect Channel
                                                    </button>
                                                  </div>
                                                )}
                                              </MenuItem>
                                            </MenuItems>
                                          </Menu>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/* Connect new channel list if no channels are connected in the current social set */}
              {!socialSet?.channels?.length && (
                <div className="flex flex-col gap-2 ">
                  <div className="rounded-md bg-purple-50 p-4 mb-6">
                    <div className="flex">
                      <div className="flex-1 md:flex md:justify-between">
                        <p className="text-sm text-purple-700">
                          {(socialSets ?? []).flatMap((x) => x.channels)
                            .length == 0
                            ? "Time to get started by adding your first channel!"
                            : "Connect a channel to add it to the current social set"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <ChannelsList socialSet={socialSet} showHeader={false} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <DeleteDialog
        title="Delete social set"
        ref={deleteDialogRef}
        disabled={isLoadingStats}
        onConfirm={deleteSocialSetConfirmed}
      >
        {isLoadingStats ? (
          <div className="pr-10 py-5">
            <Spinner />
          </div>
        ) : socialSet.channels.length && socialSetStats?.channels?.length ? (
          <div>
            <div>
              <div className="text-sm mb-4">
                The following channels and their posts will be deleted and any
                scheduled posts within this social set will be canceled:
              </div>
              <ul className="space-y-4 mt-2">
                {socialSetStats.channels.map((x) => (
                  <li
                    key={x.channelId}
                    className="flex items-center min-w-0 gap-x-4"
                  >
                    <div className="relative">
                      <img
                        referrerPolicy="no-referrer"
                        className="h-8 w-8 flex-none rounded-full bg-gray-50 outline outline-1 outline-gray-50"
                        src={
                          x.channelPictureUrl ??
                          findChannelConfig(x.channelType).imageUrl
                        }
                      />
                      <img
                        className="h-5 w-5 flex-none rounded-full border border-white bg-gray-50 absolute top-3.5 right-0 -mr-2"
                        src={findChannelConfig(x.channelType).imageUrl}
                      />
                    </div>
                    <div className="overflow-hidden flex-1 text-sm leading-0 text-gray-800 text-ellipsis">
                      {x.channelName}
                      <span className="ml-2 text-xs leading-5 text-gray-500 font-bold whitespace-nowrap">
                        ({x.postsCount} posts)
                      </span>
                    </div>

                    {/* <span className="text-sm font-semibold leading-3 text-gray-900 truncate">{x.channelName}</span>
                    <span className="text-xs leading-5 text-gray-500 font-bold whitespace-nowrap">
                      ({x.postsCount} posts)
                    </span> */}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ) : (
          <div>Are you sure you want to delete the social set?</div>
        )}
      </DeleteDialog>

      <DeleteDialog
        title="Disconnect Channel"
        confirmLabel="Disconnect"
        disabled={isLoadingStats}
        ref={disconnectChannelDialogRef}
        onConfirm={disconnectChannelConfirmed}
      >
        {isLoadingStats ? (
          <div className="pr-10 py-5">
            <Spinner />
          </div>
        ) : (
          <>
            {updatingChannel && (
              <div>
                <div className="text-sm mb-4">
                  {!channelStats?.postsCount && (
                    <span>
                      You are about to disconnect this channel. This information
                      cannot be recovered:
                    </span>
                  )}
                  {channelStats?.postsCount > 0 && (
                    <span>
                      You are about to disconnect this channel, along with any
                      existing posts, analytics and data. This information
                      cannot be recovered:
                    </span>
                  )}
                </div>
                <div className="flex min-w-0 gap-x-4">
                  <div className="relative">
                    <img
                      referrerPolicy="no-referrer"
                      className="h-9 w-9 flex-none rounded-full bg-gray-50 outline outline-1 outline-gray-50"
                      src={
                        updatingChannel.pictureUrl ??
                        findChannelConfig(updatingChannel.type).imageUrl
                      }
                    />
                    <img
                      className="h-5 w-5 flex-none rounded-full border border-white border-3 bg-gray-50 absolute top-6 right-0 -mr-2"
                      src={findChannelConfig(updatingChannel.type).imageUrl}
                    />
                  </div>
                  <div className="min-w-0 flex-1">
                    <div className="flex gap-2 items-center">
                      <span className="font-medium leading-6 text-gray-900 truncate">
                        {updatingChannel.name}
                      </span>
                      <span className="text-xs leading-5 text-gray-500 font-bold whitespace-nowrap">
                        ({channelStats?.postsCount} posts)
                      </span>
                    </div>
                    <p className="text-left text-sm leading-4 text-gray-500">
                      {ChannelAccountTypes[updatingChannel?.accountType]?.label}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </DeleteDialog>
    </>
  );
}
