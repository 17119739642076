/* eslint-disable @typescript-eslint/no-explicit-any */
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ReactNode, useEffect } from "react";
import { PostInstance } from "../../models/post-instance";
import PrimaryTextBox from "../../../../../components/common/PrimaryTextBox";
import eventBusService from "../../../../../services/application/event-bus/event-bus-service";
import EventBusEvents from "../../../../../services/application/event-bus/event-bus-events";
import AttachmentSlot from "../../models/attachment-slot";

interface TwitterFormProps {
  postInstance: PostInstance;
  attachments: AttachmentSlot[];
  onPostInstanceChanged: (postInstance: PostInstance, touched: boolean) => void;
  children?: ReactNode | (({ primaryForm, secondaryForm }) => ReactNode);
}

interface TwitterFormInput {
  caption?: string;
  channelId: string;
}

const schema = yup
  .object({
    caption: yup.string(),
  })
  .required();

export default function TwitterForm({
  postInstance,
  onPostInstanceChanged,
  children,
}: TwitterFormProps) {
  const twitterConfig = postInstance.postConfig.twitter;

  const form = useForm<TwitterFormInput>({
    resolver: yupResolver(schema) as any,
    defaultValues: {
      caption: twitterConfig.contentOptions?.caption ?? "",
    },
  });

  // Receive AI content from AI service and insert it into the textbox
  useEffect(() => {
    const insertAiContent = (content) => {
      form.setValue("caption", content);
      onCaptionChanged(content);
    };

    eventBusService.on(EventBusEvents.INSERT_AI_CONTENT, insertAiContent);

    return () => {
      eventBusService.remove(EventBusEvents.INSERT_AI_CONTENT, insertAiContent);
    };
  });

  const onCaptionChanged = (text: string) => {
    const updatedPostInstance: PostInstance = {
      ...postInstance,
      title: text,
      postConfig: {
        ...postInstance.postConfig,
        twitter: {
          contentOptions: {
            ...twitterConfig.contentOptions,
            caption: text,
          },
        },
      },
    };

    onPostInstanceChanged(updatedPostInstance, true);
  };

  // const onSuperFollowersChanged = (value: boolean) => {
  //   const updatedPostInstance: PostInstance = {
  //     ...postInstance,
  //     postConfig: {
  //       ...postInstance.postConfig,
  //       twitter: {
  //         ...postInstance.postConfig.pinterest,
  //         contentOptions: {
  //           ...twitterConfig.contentOptions,
  //           superFollowers: value,
  //         },
  //       },
  //     },
  //   };

  //   onPostInstanceChanged(updatedPostInstance);
  // };

  const primaryForm = (
    <>
      <div className="flex flex-row">
        <fieldset className="w-full h-6 mt-3 block"></fieldset>
      </div>

      {/* Caption */}
      <div>
        <PrimaryTextBox
          channel={postInstance.channel}
          onTextChanged={onCaptionChanged}
          textAreaOptions={{
            hideLabel: true,
            maxLength: 280,
            className: "h-[124px]",
            name: "caption",
            label: "Caption",
            formHook: form,
            placeholder: "Your post caption",
          }}
        />
      </div>
    </>
  );

  const secondaryForm = (
    <>
      {/* <div className="mt-6 border rounded-md px-4 py-2">
        <div className="leading-8 font-medium text-md text-gray-700">More Options</div>
        <div className="mt-2">
          <div className="flex justify-start items-center gap-4 relative">
            <div className="text-gray-700 font-semibold text-xs">Super followers</div>
          </div>
        </div>
      </div> */}
    </>
  );

  return (
    <form>
      <>
        {typeof children === "function"
          ? children({ primaryForm, secondaryForm })
          : children}{" "}
      </>
    </form>
  );
}
