import { useEffect } from "react";
import AttachmentSlot from "../../models/attachment-slot";
import { PostInstance } from "../../models/post-instance";

interface TikTokSyncerProps {
  attachments: AttachmentSlot[];
  postInstance: PostInstance;
  onPostInstanceChanged: (postInstance: PostInstance, touched: boolean) => void;
}

export default function TikTokSyncer({
  postInstance,
  onPostInstanceChanged,
}: TikTokSyncerProps) {
  const tikTokConfig = postInstance.postConfig.tikTok;

  // Set initial system post type
  useEffect(() => {
    const setSystemPostType = () => {
      let systemPostType = tikTokConfig.contentOptions.systemPostType;

      const initialSystemPostType = systemPostType;

      if (tikTokConfig.contentOptions.postType == "Video") {
        systemPostType = "Video";
      } else if (tikTokConfig.contentOptions.postType == "Photo") {
        systemPostType = "Photo";
      }

      if (systemPostType != initialSystemPostType) {
        const updatedPostInstance: PostInstance = {
          ...postInstance,
          postConfig: {
            ...postInstance.postConfig,
            tikTok: {
              contentOptions: {
                ...tikTokConfig.contentOptions,
                systemPostType: systemPostType,
              },
            },
          },
        };

        onPostInstanceChanged(updatedPostInstance, false);
      }
    };

    setSystemPostType();
  }, [tikTokConfig.contentOptions, onPostInstanceChanged, postInstance]);

  return null;
}
