import Post from "../../../../../models/entities/post";
import MetricItem from "./MetricItem";
import { formatMetricCount } from "../../../../../utils/metric-utils";
import MetricsViewMode from "../../models/metrics-view-mode";

export interface TwitterMetricsProps {
  post: Post;
  metricsViewMode: MetricsViewMode;
}

export default function TwitterMetrics({
  metricsViewMode,
  post,
}: TwitterMetricsProps) {
  const metrics = post.metrics.twitter;

  return (
    <div className="flex flex-wrap gap-4 text-center">
      <MetricItem
        value={formatMetricCount(metrics.impressions)}
        label="Impressions"
      />
      <MetricItem
        value={formatMetricCount(metrics.engagements)}
        label="Engagement"
      />
      {metrics.videoViews !== null && metricsViewMode == "expanded" && (
        <MetricItem
          value={formatMetricCount(metrics.videoViews)}
          label="Video Views"
        />
      )}
      <MetricItem value={formatMetricCount(metrics.likes)} label="Likes" />
      <MetricItem value={formatMetricCount(metrics.replies)} label="Replies" />
      <MetricItem
        value={formatMetricCount(metrics.profileClicks)}
        label="Clicks"
      />

      {metricsViewMode == "expanded" && (
        <>
          <MetricItem
            value={formatMetricCount(metrics.retweets)}
            label="Retweets"
          />
          <MetricItem
            value={formatMetricCount(metrics.quotes)}
            label="Quotes"
          />
          <MetricItem
            value={formatMetricCount(metrics.bookmarks)}
            label="Bookmarks"
          />
        </>
      )}
    </div>
  );
}
