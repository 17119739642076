import { MenuItems, Menu, MenuButton, MenuItem } from "@headlessui/react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/react/20/solid";
import clsx from "clsx";
import { useContext } from "react";
import ViewRange from "../../calendar/models/view-range";
import Post from "../../../../models/entities/post";
import Spinner from "../../../../components/common/Spinner";
import FilterChannelsSelector from "./FilterChannelsSelector";
import Channel from "../../../../models/entities/channel";
import GlobalStateContext from "../../../../state/global-state/GlobalStateContext";
import TimezoneLabel from "../../../../components/common/TimezoneLabel";

export interface PostsHeaderProps {
  viewRange: ViewRange;
  loading: boolean;
  showChannelFilters: boolean;
  unfilteredPosts: Post[];
  selectedChannels: Channel[];
  onChannelsFiltered: (channels: Channel[]) => void;
  onPrevious: () => void;
  onToday: () => void;
  onNext: () => void;
}

export default function PostsHeader({
  viewRange,
  loading,
  showChannelFilters,
  unfilteredPosts,
  selectedChannels,
  onChannelsFiltered,
  onNext,
  onPrevious,
  onToday,
}: PostsHeaderProps) {
  const { state } = useContext(GlobalStateContext);
  const currentSocialSet = state.currentSocialSet;

  return (
    <header className="flex flex-none items-center justify-start gap-4 sticky top-0 z-10 ring-1 ring-black ring-opacity-5 px-6 py-4 bg-white rounded-t-md">
      <div className="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch">
        <button
          onClick={onPrevious}
          type="button"
          className="flex h-9 w-12 items-center justify-center rounded-l-md border-y border-l border-gray-200 pr-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pr-0 md:hover:bg-gray-50"
        >
          <span className="sr-only">Previous Month</span>
          <ChevronLeftIcon className="h-5 w-5" />
        </button>
        <button
          onClick={onToday}
          type="button"
          className="hidden border-y border-gray-200 px-3.5 text-sm font-normal text-gray-900 hover:bg-gray-50 focus:relative md:block"
        >
          This Month
        </button>
        <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
        <button
          onClick={onNext}
          type="button"
          className="flex h-9 w-12 items-center justify-center rounded-r-md border-y border-r border-gray-200 pl-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pl-0 md:hover:bg-gray-50"
        >
          <span className="sr-only">Next Month</span>
          <ChevronRightIcon className="h-5 w-5" />
        </button>
      </div>
      <div className="flex-1 flex flex-col items-start sm:flex-row sm:items-center truncate whitespace-nowrap">
        <h1 className="text-base font-semibold text-gray-900">
          <time>{viewRange.monthly.headerLabel}</time>
        </h1>
        <div className="sm:ml-2 text-sm text-slate-500">
          {!loading ? (
            `(${unfilteredPosts.length} posts)`
          ) : (
            <Spinner size="5" />
          )}
        </div>
      </div>

      <div className="flex items-center ml-auto">
        <div className="hidden md:flex items-center">
          <div>
            {showChannelFilters && (
              <FilterChannelsSelector
                unfilteredPosts={unfilteredPosts}
                channels={currentSocialSet?.channels ?? []}
                selected={selectedChannels}
                onSelected={onChannelsFiltered}
              />
            )}
          </div>

          <div className="ml-4">
            <TimezoneLabel />
          </div>
        </div>

        <Menu as="div" className="relative ml-6 md:hidden">
          <MenuButton className="-mx-2 flex items-center rounded-full border border-transparent p-2 text-gray-400 hover:text-gray-500">
            <span className="sr-only">Open menu</span>
            <EllipsisHorizontalIcon className="h-5 w-5" />
          </MenuButton>

          <MenuItems
            transition
            anchor="bottom end"
            className="[--anchor-gap:0.25rem] [--anchor-padding:0px] z-10 w-40 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5"
          >
            <div className="py-1">
              <MenuItem>
                {({ focus }) => (
                  <a
                    onClick={onToday}
                    className={clsx(
                      focus ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    Today
                  </a>
                )}
              </MenuItem>
            </div>
          </MenuItems>
        </Menu>
      </div>
    </header>
  );
}
