import clsx from "clsx";
import { ReactNode, Suspense, useCallback, useMemo, useRef } from "react";
import { useDropzone } from "react-dropzone";
import AttachmentSlot from "../models/attachment-slot";
import PhotoIcon from "@heroicons/react/24/outline/PhotoIcon";
import {
  DndContext,
  closestCenter,
  TouchSensor,
  useSensor,
  useSensors,
  PointerSensor,
} from "@dnd-kit/core";
import {
  SortableContext,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable";
import { ValidationError } from "../models/validation-result";
import createPostHelper, {
  AttachmentsChangedData,
} from "../../../../services/application/create-post-helper";
import { PostInstance } from "../models/post-instance";
import SelectMediaDialog from "../../../../components/dialogs/SelectMediaDialog";
import Attachment from "./Atachment";

export interface AttachmentsProps {
  attachmentSlots: AttachmentSlot[];
  acceptedTypes: string[];
  validationErrors: ValidationError[];
  postInstance: PostInstance;
  onAttachmentsChanged: (data: AttachmentsChangedData) => void;
  children?: ReactNode | ((onClick) => ReactNode);
}

export default function Attachments({
  attachmentSlots,
  acceptedTypes,
  validationErrors,
  postInstance,
  onAttachmentsChanged,
  children,
}: AttachmentsProps) {
  const mediaDialogRef = useRef(null);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const newSlotPromises = acceptedFiles.map((file) =>
        createPostHelper.getSlotFromFile(file, postInstance.channel.type)
      );
      const newSlots = await Promise.all(newSlotPromises);

      onAttachmentsChanged({
        operation: "Add",
        slots: newSlots,
      });
    },
    [onAttachmentsChanged]
  );

  const accept = useMemo(
    () =>
      acceptedTypes.reduce((accumulator, current: string) => {
        accumulator[current] = [];
        return accumulator;
      }, {}),
    [acceptedTypes]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onDrop,
    accept: accept,
  });

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor)
  );

  const onSlotUpdated = (slot: AttachmentSlot) => {
    onAttachmentsChanged({
      operation: "Update",
      slots: [slot],
    });
  };

  const onSlotDeleted = (slot: AttachmentSlot) => {
    onAttachmentsChanged({
      operation: "Delete",
      slots: [slot],
    });
  };

  const onReordered = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      onAttachmentsChanged({
        operation: "Reorder",
        slots: [
          attachmentSlots.find((x) => x.id == active.id),
          attachmentSlots.find((x) => x.id == over.id),
        ],
      });
    }
  };

  const openLibrary = () => {
    mediaDialogRef.current.openDialog();
  };

  const onMediaSelected = (slots: AttachmentSlot[]) => {
    slots.forEach((s) => (s.channelType = postInstance?.channel?.type));

    const withoutDuplicates = slots.filter((x) =>
      attachmentSlots.every((y) => x.id != y.id)
    );

    onAttachmentsChanged({
      operation: "Add",
      slots: withoutDuplicates,
    });
  };

  const uploadSection = (
    <div className="flex flex-col gap-1 w-full h-full relative">
      <div
        {...getRootProps()}
        className={clsx(
          "flex-1 w-full h-full group transition-all border-dashed border-2 rounded-md p-2 text-xs font-medium text-gray-500 flex items-center justify-center cursor-pointer hover:border-gray-200 hover:text-gray-400",
          isDragActive ? "border-primary-600" : "border-gray-300"
        )}
      >
        <input {...getInputProps()} />

        <div className="h-full flex flex-col justify-center items-center -mt-8">
          <PhotoIcon className="w-8 mb-1" />

          {isDragActive ? (
            <p>Drop now</p>
          ) : (
            <p className="text-center">
              <span className="text-primary-600 group-hover:text-primary-400">
                Drag &amp; drop
              </span>{" "}
              <br />
              or select a file
            </p>
          )}
        </div>
      </div>
      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          openLibrary();

          return false;
        }}
        className="truncate flex-1 mt-auto absolute bottom-0 border-t w-full group transition-all border-t-1 p-2 text-xs font-medium text-gray-500 flex items-center justify-center cursor-pointer hover:border-gray-200 hover:text-gray-400"
      >
        Select from Library
      </div>
      {/* <div className="w-full group transition-all border-2 rounded-md p-2 text-xs font-medium text-gray-500 flex items-center justify-center cursor-pointer hover:border-gray-200 hover:text-gray-400">
        or Import from Library
      </div> */}
    </div>
  );

  const attachmentsSection = (
    <Suspense>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={onReordered}
      >
        <SortableContext
          items={attachmentSlots.map((x) => x.id)}
          strategy={horizontalListSortingStrategy}
        >
          {attachmentSlots.map((slot) => (
            <Attachment
              key={slot.id}
              id={slot.id}
              slot={slot}
              validationErrors={validationErrors.filter(
                (x) => x.attachmentId == slot.id
              )}
              onSlotUpdated={onSlotUpdated}
              onSlotDeleted={onSlotDeleted}
            />
          ))}
        </SortableContext>
      </DndContext>
    </Suspense>
  );

  return (
    <>
      {typeof children === "function"
        ? children({ uploadSection, attachmentsSection })
        : children}

      <SelectMediaDialog ref={mediaDialogRef} onConfirm={onMediaSelected} />
    </>
  );
}
