import InputField from "../../../../../components/form/InputField";
import UserUpdateFormInput from "../../../../../models/form-inputs/user-update-input";
import { UseFormReturn } from "react-hook-form";

interface UserDetailsSectionProps {
  form: UseFormReturn<UserUpdateFormInput, void, UserUpdateFormInput>;
  onSubmit: (input: UserUpdateFormInput) => void;
}

export default function UserDetailsSection({
  form,
  onSubmit,
}: UserDetailsSectionProps) {
  return (
    <form onSubmit={form.handleSubmit(onSubmit)} className="flex-1">
      <div className="md:col-span-2">
        <div className="py-6">
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <InputField
                label="Full name"
                name="name"
                formReturn={form}
                maxLength={60}
              />
            </div>
            <div className="sm:col-span-3">
              <InputField
                label="E-mail address"
                type="email"
                name="email"
                formReturn={form}
                maxLength={80}
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
