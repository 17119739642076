/* eslint-disable @typescript-eslint/no-explicit-any */
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { TikTokPostType } from "../../../../../models/entities/post";
import { ReactNode, useEffect, useState } from "react";
import { PostInstance } from "../../models/post-instance";
import { useQuery } from "@tanstack/react-query";
import queryNames from "../../../../../queries/query-names";
import tiktokService from "../../../../../services/api/tiktok-service";
import TikTokPrivacyOption from "../../../../../models/tiktok/tiktok-privacy-option";
import DiscloseContent from "./DiscloseContent";
import Interactions from "./Interactions";
import PrivacyStatus from "./PrivacyStatus";
import PostTypeSelector from "./PostTypeSelector";
import PrimaryTextBox from "../../../../../components/common/PrimaryTextBox";
import InputField from "../../../../../components/form/InputField";
import MusicSettings from "./MusicSettings";
import AIContentSettings from "./AIContentSettings";
import eventBusService from "../../../../../services/application/event-bus/event-bus-service";
import EventBusEvents from "../../../../../services/application/event-bus/event-bus-events";
import AttachmentSlot from "../../models/attachment-slot";

interface TikTokFormProps {
  postInstance: PostInstance;
  attachments: AttachmentSlot[];
  onPostInstanceChanged: (postInstance: PostInstance, touched: boolean) => void;
  children?: ReactNode | (({ primaryForm, secondaryForm }) => ReactNode);
}

const schema = yup
  .object({
    title: yup.string().max(2200),
    description: yup.string().max(4000),
    privacyStatus: yup.string().required(),
    comment: yup.boolean().required(),
    duet: yup.boolean().required(),
    stitch: yup.boolean().required(),
    commercialContent: yup.boolean().required(),
    promotionalContent: yup.boolean().required(),
    paidPartnership: yup.boolean().required(),
    aiGenerated: yup.boolean().required(),
    autoAddMusic: yup.boolean(),
  })
  .required();

export default function TikTokForm({
  postInstance,
  onPostInstanceChanged,
  children,
}: TikTokFormProps) {
  const tikTokConfig = postInstance.postConfig.tikTok;

  const [privacyStatus, setPrivacyStatus] = useState<TikTokPrivacyOption>(null);
  const [discloseContent, setDiscloseContent] = useState<boolean>(
    tikTokConfig.contentOptions.commercialContent
  );
  const [promotionalContent, setPromotionalContent] = useState<boolean>(
    tikTokConfig.contentOptions.promotionalContent
  );
  const [paidPartnership, setPaidPartnership] = useState<boolean>(
    tikTokConfig.contentOptions.paidPartnership
  );
  const [comment, setComment] = useState<boolean>(
    tikTokConfig.contentOptions.comment
  );
  const [duet, setDuet] = useState<boolean>(tikTokConfig.contentOptions.duet);
  const [stitch, setStitch] = useState<boolean>(
    tikTokConfig.contentOptions.stitch
  );
  const [autoAddMusic, setAutoAddMusic] = useState<boolean>(
    tikTokConfig.contentOptions.autoAddMusic
  );
  const [aiGenerated, setAiGenerated] = useState<boolean>(
    tikTokConfig.contentOptions.aiGenerated
  );
  const [commentDisabled, setCommentDisabled] = useState<boolean>(false);
  const [duetDisabled, setDuetDisabled] = useState<boolean>(false);
  const [stitchDisabled, setStitchDisabled] = useState<boolean>(false);

  const form = useForm({
    resolver: yupResolver(schema) as any,
    defaultValues: {
      title: tikTokConfig.contentOptions?.title ?? "",
      description: tikTokConfig.contentOptions?.description ?? "",
      privacyStatus: tikTokConfig.contentOptions?.privacyStatus ?? "",
      comment: tikTokConfig.contentOptions?.comment ?? false,
      duet: tikTokConfig.contentOptions?.duet ?? false,
      stitch: tikTokConfig.contentOptions?.stitch ?? false,
      commercialContent:
        tikTokConfig.contentOptions?.commercialContent ?? false,
      promotionalContent:
        tikTokConfig.contentOptions?.promotionalContent ?? false,
      paidPartnership: tikTokConfig.contentOptions?.paidPartnership ?? false,
      aiGenerated: tikTokConfig.contentOptions?.aiGenerated ?? false,
    },
  });

  const { data: creatorInfo } = useQuery({
    queryKey: [queryNames.tikTokCreatorInfo, postInstance.channel.id],
    queryFn: () => tiktokService.getCreatorInfo(postInstance.channel.id),
    staleTime: Infinity,
  });

  // Receive AI content from AI service and insert it into the textbox
  useEffect(() => {
    const insertAiContent = (content) => {
      form.setValue("description", content);
      onDescriptionChanged(content);
    };

    eventBusService.on(EventBusEvents.INSERT_AI_CONTENT, insertAiContent);

    return () => {
      eventBusService.remove(EventBusEvents.INSERT_AI_CONTENT, insertAiContent);
    };
  });

  // Set privacy status dropdown value
  useEffect(() => {
    if (tikTokConfig?.contentOptions?.privacyStatus) {
      setPrivacyStatus(
        tiktokService.privacyOptions.find(
          (x) => x.value === tikTokConfig.contentOptions.privacyStatus
        )
      );
    }
  }, [tikTokConfig?.contentOptions?.privacyStatus]);

  // Set interactions based on system post type
  useEffect(() => {
    switch (postInstance.postConfig.tikTok.contentOptions.systemPostType) {
      case "Video":
        setCommentDisabled(false);
        setDuetDisabled(false);
        setStitchDisabled(false);
        break;
      case "Photo":
        setCommentDisabled(false);
        setDuetDisabled(true);
        setStitchDisabled(true);
        setDuet(false);
        setStitch(false);
        break;
    }
  }, [postInstance?.postConfig?.tikTok?.contentOptions?.systemPostType]);

  // Attach creator info to post instance
  useEffect(() => {
    if (creatorInfo && postInstance) {
      postInstance.extras = { creatorInfo: creatorInfo };
    }
  }, [creatorInfo, postInstance]);

  const onSettingsChanged = ({
    updateSetting,
  }: {
    updateSetting: (postInstance: PostInstance) => void;
  }) => {
    const updatedPostInstance: PostInstance = {
      ...postInstance,
      postConfig: {
        ...postInstance.postConfig,
        tikTok: {
          contentOptions: {
            ...tikTokConfig.contentOptions,
          },
        },
      },
    };

    updateSetting(updatedPostInstance);
    onPostInstanceChanged(updatedPostInstance, true);
  };

  const onPaidPartnershipChanged = (value: boolean) => {
    setPaidPartnership(value);

    onSettingsChanged({
      updateSetting: (postInstance) => {
        postInstance.postConfig.tikTok.contentOptions.paidPartnership = value;
      },
    });
  };

  const onDiscloseContentChanged = (value: boolean) => {
    setDiscloseContent(value);

    onSettingsChanged({
      updateSetting: (postInstance) => {
        postInstance.postConfig.tikTok.contentOptions.commercialContent = value;
      },
    });
  };

  const onPromotionalContentChanged = (value: boolean) => {
    setPromotionalContent(value);

    onSettingsChanged({
      updateSetting: (postInstance) => {
        postInstance.postConfig.tikTok.contentOptions.promotionalContent =
          value;
      },
    });
  };

  const onPrivacyStatusChanged = (privacyStatus: TikTokPrivacyOption) => {
    setPrivacyStatus(privacyStatus);

    onSettingsChanged({
      updateSetting: (postInstance) => {
        postInstance.postConfig.tikTok.contentOptions.privacyStatus =
          privacyStatus.value;
      },
    });
  };

  const onCommentChanged = (value: boolean) => {
    setComment(value);

    onSettingsChanged({
      updateSetting: (postInstance) => {
        postInstance.postConfig.tikTok.contentOptions.comment = value;
      },
    });
  };

  const onStitchChanged = (value: boolean) => {
    setStitch(value);

    onSettingsChanged({
      updateSetting: (postInstance) => {
        postInstance.postConfig.tikTok.contentOptions.stitch = value;
      },
    });
  };

  const onDuetChanged = (value: boolean) => {
    setDuet(value);

    onSettingsChanged({
      updateSetting: (postInstance) => {
        postInstance.postConfig.tikTok.contentOptions.duet = value;
      },
    });
  };

  const onAutoAddMusicChanged = (value: boolean) => {
    setAutoAddMusic(value);

    onSettingsChanged({
      updateSetting: (postInstance) => {
        postInstance.postConfig.tikTok.contentOptions.autoAddMusic = value;
      },
    });
  };

  const onAiGeneratedChanged = (value: boolean) => {
    setAiGenerated(value);

    onSettingsChanged({
      updateSetting: (postInstance) => {
        postInstance.postConfig.tikTok.contentOptions.aiGenerated = value;
      },
    });
  };

  const selectPostType = (postType: TikTokPostType) => {
    const updatedPostInstance: PostInstance = {
      ...postInstance,
      postConfig: {
        ...postInstance.postConfig,
        tikTok: {
          contentOptions: {
            ...tikTokConfig.contentOptions,
            postType: postType,
          },
        },
      },
    };

    onPostInstanceChanged(updatedPostInstance, true);
  };

  const onTitleChanged = (text: string) => {
    const updatedPostInstance: PostInstance = {
      ...postInstance,
      title: text,
      postConfig: {
        ...postInstance.postConfig,
        tikTok: {
          contentOptions: {
            ...tikTokConfig.contentOptions,
            title: text,
          },
        },
      },
    };

    onPostInstanceChanged(updatedPostInstance, true);
  };

  const onDescriptionChanged = (text: string) => {
    const updatedPostInstance: PostInstance = {
      ...postInstance,
      title: text,
      postConfig: {
        ...postInstance.postConfig,
        tikTok: {
          contentOptions: {
            ...tikTokConfig.contentOptions,
            description: text,
          },
        },
      },
    };

    onPostInstanceChanged(updatedPostInstance, true);
  };

  const primaryForm = (
    <>
      <div className="flex flex-row">
        <PostTypeSelector
          tikTokConfig={tikTokConfig}
          onPostTypeChanged={selectPostType}
        />
      </div>

      <div className="mt-2.5">
        {postInstance.postConfig.tikTok.contentOptions.systemPostType ==
          "Video" && (
          <PrimaryTextBox
            channel={postInstance.channel}
            onTextChanged={onTitleChanged}
            textAreaOptions={{
              hideLabel: true,
              maxLength: 2200,
              className: "h-[124px]",
              name: "title",
              formHook: form,
              placeholder: "Write a video caption",
            }}
          />
        )}
        {postInstance.postConfig.tikTok.contentOptions.systemPostType ==
          "Photo" && (
          <>
            <div className="flex flex-col mb-3">
              <InputField
                label="Title"
                placeholder="Your post title"
                name="title"
                hideLabel={true}
                formReturn={form}
                onChange={(event) => onTitleChanged(event.target.value)}
                maxLength={90}
              />
            </div>

            <PrimaryTextBox
              channel={postInstance.channel}
              onTextChanged={onDescriptionChanged}
              textAreaOptions={{
                hideLabel: true,
                maxLength: 4000,
                className: "h-[160px]",
                name: "description",
                formHook: form,
                placeholder: "Post description",
              }}
            />
          </>
        )}
      </div>
    </>
  );

  const secondaryForm = (
    <div className="md:shadow custom-shadow rounded-md py-3 md:p-3">
      <PrivacyStatus
        creatorInfo={creatorInfo}
        privacyStatus={privacyStatus}
        paidPartnership={paidPartnership}
        onPrivacyStatusChanged={onPrivacyStatusChanged}
      />

      <div className="mt-2">
        <Interactions
          comment={comment}
          duet={duet}
          stitch={stitch}
          commentDisabled={commentDisabled}
          duetDisabled={duetDisabled}
          stitchDisabled={stitchDisabled}
          onCommentChanged={onCommentChanged}
          onDuetChanged={onDuetChanged}
          onStitchChanged={onStitchChanged}
        />
      </div>

      {postInstance.postConfig.tikTok.contentOptions.systemPostType ==
        "Photo" && (
        <div className="mt-2">
          <MusicSettings
            autoAddMusic={autoAddMusic}
            onAutoAddMusicChanged={onAutoAddMusicChanged}
          />
        </div>
      )}

      {postInstance.postConfig.tikTok.contentOptions.systemPostType ==
        "Video" && (
        <div className="mt-2">
          <AIContentSettings
            aiGenerated={aiGenerated}
            onAiGeneratedChanged={onAiGeneratedChanged}
          />
        </div>
      )}

      <div className="mt-4 border-t border-gray-200 py-4">
        <DiscloseContent
          discloseContent={discloseContent}
          promotionalContent={promotionalContent}
          paidPartnership={paidPartnership}
          privacyStatus={privacyStatus?.value}
          discloseContentChanged={onDiscloseContentChanged}
          promotinalContentChanged={onPromotionalContentChanged}
          paidPartnershipChanged={onPaidPartnershipChanged}
        />
      </div>
    </div>
  );

  return (
    <form>
      <>
        {" "}
        {typeof children === "function"
          ? children({ primaryForm, secondaryForm })
          : children}{" "}
      </>
    </form>
  );
}
