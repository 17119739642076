/* eslint-disable @typescript-eslint/no-explicit-any */
import { SparklesIcon } from "@heroicons/react/20/solid";
import TextAreaField, { TextAreaFieldProps } from "../form/TextAreaField";
import Emojis from "../../modules/scheduler/create-post/components/Emojis";
import { useEffect, useMemo, useRef, useState } from "react";
import eventBusService from "../../services/application/event-bus/event-bus-service";
import EventBusEvents from "../../services/application/event-bus/event-bus-events";
import Hashtag from "../../models/entities/hashtag";
import HashtagsPicker from "../../modules/scheduler/create-post/components/HashtagsPicker";
import clsx from "clsx";
import { ChatBubbleBottomCenterIcon } from "@heroicons/react/24/outline";
import Channel from "../../models/entities/channel";
import scopeAuthorizerService from "../../services/application/scope-authorizer-service";
import GenericDialog from "../dialogs/GenericDialog";
import CommentScopeGuard from "./CommentScopeGuard";

export interface PrimaryTextBoxProps {
  textAreaOptions: TextAreaFieldProps;
  firstCommentOptions?: TextAreaFieldProps;
  channel: Channel;
  controlsLayout?: "internal" | "external";
  onTextChanged: (value: string) => void;
  onFirstCommentChanged?: (value: string) => void;
}

export default function PrimaryTextBox({
  textAreaOptions,
  firstCommentOptions,
  controlsLayout = "internal",
  channel,
  onTextChanged,
  onFirstCommentChanged,
}: PrimaryTextBoxProps) {
  const fieldKey = textAreaOptions.name;
  const formHook = textAreaOptions.formHook;
  const firstCommentFieldKey = firstCommentOptions?.name;
  const firstCommentFormHook = firstCommentOptions?.formHook;
  const commentScopeDialogRef = useRef(null);

  const [commentEnabled, setCommentEnabled] = useState(false);

  const id = useMemo(() => `textbox-${fieldKey}`, [fieldKey]);
  const firstCommentId = useMemo(
    () => `textbox-${firstCommentFieldKey}`,
    [firstCommentFieldKey]
  );

  useEffect(() => {
    if (firstCommentFormHook) {
      const hasValue = !!firstCommentFormHook?.getValues(firstCommentFieldKey);
      setCommentEnabled(hasValue);
    }
  }, []);

  const onEmojiClick = (emojiObject) => {
    const textAreaElement = document.getElementById(id) as any;
    let textAreaValue = formHook.getValues()[fieldKey];
    const selection = textAreaElement.selectionEnd;

    textAreaValue = `${textAreaValue.substring(
      0,
      textAreaElement.selectionStart
    )}${emojiObject.emoji} ${textAreaValue.substring(
      textAreaElement.selectionEnd
    )}`;

    formHook.setValue(fieldKey, textAreaValue);
    textAreaElement.focus();
    textAreaElement.selectionEnd = selection + 3;

    onTextChanged(textAreaValue);
  };

  const openAiAssistant = () => {
    eventBusService.dispatch(EventBusEvents.OPEN_AI_ASSISTANT);
    setTimeout(() => {
      eventBusService.dispatch(EventBusEvents.UPDATE_AI_ASSISTANT_CONTENT, {
        content: formHook.getValues()[fieldKey],
      });
    });
  };

  const onChange = (content?: string) => {
    eventBusService.dispatch(EventBusEvents.UPDATE_AI_ASSISTANT_CONTENT, {
      content: content,
    });

    onTextChanged(content);
  };

  const onHashtagsSelected = (hashtag: Hashtag) => {
    const textAreaElement = document.getElementById(id) as any;
    let textAreaValue = formHook.getValues()[fieldKey];
    const selection = textAreaElement.selectionEnd;
    const hashtagsContent = hashtag.hashtags;

    textAreaValue = `${textAreaValue}\n\n${hashtagsContent}`;

    formHook.setValue(fieldKey, textAreaValue);
    textAreaElement.focus();
    textAreaElement.selectionEnd = selection + 3;

    onTextChanged(textAreaValue);
  };

  const toggleComments = () => {
    if (!scopeAuthorizerService.authorizeFirstComment(channel)) {
      commentScopeDialogRef?.current?.openDialog();
      return;
    }

    setCommentEnabled(!commentEnabled);

    // If enabling comments, focus on the first comment field
    if (!commentEnabled) {
      setTimeout(() => {
        const firstCommentElement = document.getElementById(
          firstCommentId
        ) as any;
        firstCommentElement.focus();
      });

      onFirstCommentChanged(formHook.getValues()[firstCommentFieldKey]);
    } else {
      // If disabling comments, focus on the main text area
      setTimeout(() => {
        const textAreaElement = document.getElementById(id) as any;
        textAreaElement.focus();
      });

      onFirstCommentChanged("");
    }
  };

  return (
    <>
      <div className="flex flex-row relative">
        <div
          className={clsx(
            "min-w-0 flex-1",
            controlsLayout == "internal" && "border rounded-md"
          )}
        >
          <div className="overflow-hidden">
            <TextAreaField
              className={clsx(
                textAreaOptions.className,
                controlsLayout == "internal" &&
                  "!border-none !ring-0 resize-none"
              )}
              id={id}
              autosize={false}
              autoFocus={textAreaOptions.autoFocus}
              label={textAreaOptions.label}
              placeholder={textAreaOptions.placeholder}
              name={fieldKey}
              formHook={formHook}
              rows={textAreaOptions.rows}
              maxLength={textAreaOptions.maxLength}
              hideLabel={textAreaOptions.hideLabel}
              onChange={(e) => onChange(e.target.value)}
            />

            {firstCommentOptions && commentEnabled && (
              <div className="border-t border-b">
                <TextAreaField
                  className={clsx(
                    "h-8 border-t overflow-y-auto",
                    controlsLayout == "internal" &&
                      "!border-none !ring-0 resize-none"
                  )}
                  id={firstCommentId}
                  autosize={true}
                  autoFocus={firstCommentOptions.autoFocus}
                  label={firstCommentOptions.label}
                  placeholder={firstCommentOptions.placeholder}
                  name={firstCommentFieldKey}
                  formHook={firstCommentFormHook}
                  rows={firstCommentOptions.rows}
                  maxLength={firstCommentOptions.maxLength}
                  hideLabel={firstCommentOptions.hideLabel}
                  onChange={(e) => onFirstCommentChanged?.(e.target.value)}
                />
              </div>
            )}
          </div>

          <div
            className={clsx(
              "inset-x-0 flex justify-between",
              controlsLayout == "internal" ? "py-1 px-2" : "py-2"
            )}
          >
            <div className="flex items-center space-x-5">
              <div className="flex-shrink-0">
                <span
                  onClick={openAiAssistant}
                  className="inline-flex items-center gap-2 rounded-md bg-primary-50 hover:bg-primary-200 px-2 py-1 text-xs cursor-pointer font-medium text-primary-700 ring-1 ring-inset ring-primary-700/10"
                >
                  <SparklesIcon className="w-4 h-4" />
                  <span className="inline-block text-xs font-medium">
                    AI Caption Generator
                  </span>
                </span>
              </div>
            </div>

            <div className="flex items-center gap-3">
              {firstCommentOptions && (
                <div
                  data-tooltip-id="tooltip-placeholder"
                  data-tooltip-content="Add First Comment"
                  data-tooltip-delay-show={350}
                >
                  <ChatBubbleBottomCenterIcon
                    onClick={toggleComments}
                    className={clsx(
                      "w-5 h-5 cursor-pointer ",
                      commentEnabled
                        ? "hover:text-blue-800 text-blue-600"
                        : "hover:text-gray-600 text-gray-400"
                    )}
                  />
                </div>
              )}
              <HashtagsPicker onSelected={onHashtagsSelected} />

              <Emojis onEmojiClick={onEmojiClick} />
            </div>
          </div>
        </div>
      </div>

      <GenericDialog ref={commentScopeDialogRef}>
        <CommentScopeGuard
          channel={channel}
          close={() => commentScopeDialogRef.current.closeDialog()}
        />
      </GenericDialog>
    </>
  );
}
