import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../services/auth/auth-service";

interface AuthGuardProps {
  children: React.ReactNode;
}
export default function AuthGuard({ children }: AuthGuardProps) {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(null);

  useEffect(() => {
    const process = async () => {
      const result = await AuthService.isAuthenticated();

      if (!result) {
        navigate("/login");
      } else {
        setIsAuthenticated(true);
      }
    };

    process();
  }, [navigate]);

  if (isAuthenticated === true) {
    return <>{children}</>;
  }

  return null;
}
