import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../services/auth/auth-service";

interface UnauthGuardProps {
  children: React.ReactNode;
}
export default function UnauthGuard({ children }: UnauthGuardProps) {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(null);

  useEffect(() => {
    const process = async () => {
      const result = await AuthService.isAuthenticated();

      if (result) {
        navigate("/scheduler");
      } else {
        setIsAuthenticated(false);
      }
    };

    process();
  }, [navigate]);

  if (isAuthenticated === false) {
    return <>{children}</>;
  }
}
