import Post from "../../../../../models/entities/post";
import MetricItem from "./MetricItem";
import { formatMetricCount } from "../../../../../utils/metric-utils";
import MetricsViewMode from "../../models/metrics-view-mode";

export interface ThreadsMetricsProps {
  post: Post;
  metricsViewMode: MetricsViewMode;
}

export default function ThreadsMetrics({
  metricsViewMode,
  post,
}: ThreadsMetricsProps) {
  const metrics = post.metrics.threads;

  return (
    <div className="flex flex-wrap gap-4 text-center">
      <MetricItem value={formatMetricCount(metrics.views)} label="Views" />
      <MetricItem value={formatMetricCount(metrics.likes)} label="Likes" />
      <MetricItem value={formatMetricCount(metrics.replies)} label="Replies" />
      <MetricItem value={formatMetricCount(metrics.shares)} label="Shares" />
      {metricsViewMode == "expanded" && (
        <>
          <MetricItem
            value={formatMetricCount(metrics.quotes)}
            label="Quotes"
          />
          <MetricItem
            value={formatMetricCount(metrics.reposts)}
            label="Reposts"
          />
        </>
      )}
    </div>
  );
}
