import { ChannelType, PostConfig } from "../models/entities/post";
import TikTokCreator from "../models/tiktok/tiktok-creator";
import AttachmentSlot from "../modules/scheduler/create-post/models/attachment-slot";
import { PostInstance } from "../modules/scheduler/create-post/models/post-instance";
import {
  ValidationError,
  ValidationResult,
} from "../modules/scheduler/create-post/models/validation-result";
import { roundDecimal } from "./math-utils";
import { isValidUrl } from "./shared-utils";
import { v4 as uuidv4 } from "uuid";

export function getDefaultPostConfig(channelType: ChannelType): PostConfig {
  switch (channelType) {
    case "Facebook":
      return {
        channelType: channelType,
        facebook: {
          contentOptions: {
            postType: "Post",
            systemPostType: "SinglePhotoPost",
          },
        },
      };

    case "Instagram":
      return {
        channelType: channelType,
        instagram: {
          contentOptions: {
            postType: "Post",
            systemPostType: "ImagePost",
          },
        },
      };

    case "Pinterest":
      return {
        channelType: channelType,
        pinterest: {
          contentOptions: {
            systemPostType: "ImagePin",
            boardId: null,
          },
        },
      };

    case "Twitter":
      return {
        channelType: channelType,
        twitter: {
          contentOptions: {
            systemPostType: "TextPost",
            superFollowers: false,
          },
        },
      };

    case "YouTube":
      return {
        channelType: channelType,
        youTube: {
          contentOptions: {
            systemPostType: "ShortVideo",
            postType: "ShortVideo",
            categoryId: "22",
            description: null,
            license: "youtube",
            privacyStatus: "public",
            madeForKids: false,
            notifySubscribers: true,
            embeddable: true,
            tags: [],
            title: null,
          },
        },
      };

    case "TikTok":
      return {
        channelType: channelType,
        tikTok: {
          contentOptions: {
            postType: "Video",
            systemPostType: "Video",
            title: null,
            description: null,
            privacyStatus: null,
            comment: false,
            duet: false,
            stitch: false,
            commercialContent: false,
            promotionalContent: false,
            paidPartnership: false,
            aiGenerated: false,
            autoAddMusic: false,
          },
        },
      };

    case "Threads":
      return {
        channelType: channelType,
        threads: {
          contentOptions: {
            instances: [
              {
                id: uuidv4().substring(0, 8),
                index: 0,
                systemPostType: "TextPost",
                message: null,
                link: null,
                attachmentIds: [],
                current: true,
              },
            ],
          },
        },
      };

    case "LinkedIn":
      return {
        channelType: channelType,
        linkedIn: {
          contentOptions: {
            systemPostType: "TextPost",
            commentary: null,
          },
        },
      };
  }
}

function formError(message: string): ValidationError {
  return {
    message,
    owner: "form",
  };
}

function attachmentError(
  message: string,
  attachmentId: string
): ValidationError {
  return {
    message,
    owner: "attachment",
    attachmentId,
  };
}

export function validateFacebookPost(
  postInstance: PostInstance,
  attachments: AttachmentSlot[]
): ValidationResult {
  const facebookConfig = postInstance.postConfig.facebook;

  const errors: ValidationError[] = [];
  let isValid = true;
  let canPreview = true;

  if (facebookConfig.contentOptions.postType == "Post") {
    if (attachments.length == 0) {
      if (
        !facebookConfig.contentOptions.message &&
        !facebookConfig.contentOptions.link
      ) {
        errors.push(
          formError(
            "A Facebook post requires at least one of message, photo or video."
          )
        );
      }
    } else if (attachments.length == 1) {
      const slot = attachments[0];

      if (slot.isPhoto) {
        facebookConfig.contentOptions.systemPostType = "SinglePhotoPost";
      } else if (slot.isVideo) {
        facebookConfig.contentOptions.systemPostType = "VideoPost";
      }

      if (facebookConfig.contentOptions.systemPostType == "SinglePhotoPost") {
        if (slot.preview.fileSize > 10485760) {
          errors.push(
            attachmentError("Image file size can't exceed 10MB.", slot.id)
          );
        }
      } else if (facebookConfig.contentOptions.systemPostType == "VideoPost") {
        if (slot.preview.fileSize > 1073741824) {
          errors.push(
            attachmentError("Video file size can't exceed 1GB.", slot.id)
          );
        }
      }
    } else if (attachments.length > 1) {
      facebookConfig.contentOptions.systemPostType = "MultiPhotoPost";

      const videoAttachments = attachments.filter((x) => !x.isPhoto);

      videoAttachments.forEach((slot) =>
        errors.push(
          attachmentError(
            "Video is not supported for a Facebook carousel post.",
            slot.id
          )
        )
      );

      if (attachments.length > 10) {
        errors.push(
          formError("A Facebook carousel post can contain up to 10 photos.")
        );
      }

      const invalidPhotoSizeattachments = attachments.filter(
        (x) => x.isPhoto && x.preview.fileSize > 10485760
      );

      invalidPhotoSizeattachments.forEach((slot) =>
        errors.push(
          attachmentError("Image file size can't exceed 10MB.", slot.id)
        )
      );
    }
  } else if (facebookConfig.contentOptions.postType == "Reel") {
    facebookConfig.contentOptions.systemPostType = "Reel";

    if (!attachments.some((x) => x.isVideo)) {
      errors.push(
        formError("A Facebook reel requires a video to be attached.")
      );
    } else if (attachments.some((x) => x.isPhoto)) {
      attachments
        .filter((x) => x.isPhoto)
        .forEach((slot) =>
          errors.push(
            attachmentError(
              "A Facebook reel can't include any photo attachments.",
              slot.id
            )
          )
        );
    } else if (attachments.length > 1) {
      errors.push(formError("A Facebook reel supports only one video."));
    } else {
      const slot = attachments[0];
      const aspect = roundDecimal(slot.preview.width / slot.preview.height, 2);

      if (aspect != 0.56) {
        errors.push(
          attachmentError(
            "Video aspect ratio must be 9:16. Recommended resolution is 1080x1920 pixels.",
            slot.id
          )
        );
      }

      if (slot.preview.width < 540 || slot.preview.height < 960) {
        errors.push(
          attachmentError(
            `Video resolution should be at least 540x960 pixels. Found ${slot.preview.width}x${slot.preview.height}.`,
            slot.id
          )
        );
      }

      if (slot.preview.fileSize > 1073741824) {
        errors.push(
          attachmentError("Video file size can't exceed 1GB.", slot.id)
        );
      }

      if (slot.preview.duration < 3 || slot.preview.duration > 90) {
        errors.push(
          attachmentError(
            "Video duration should be between 3 and 90 seconds.",
            slot.id
          )
        );
      }
    }
  } else if (facebookConfig.contentOptions.postType == "Story") {
    if (attachments.length == 0) {
      errors.push(
        formError(
          "A Facebook story requires either one photo or video to be attached."
        )
      );
    } else if (attachments.length > 1) {
      errors.push(
        formError(
          "A Facebook story supports only one photo or video attachment."
        )
      );
    } else if (facebookConfig.contentOptions.systemPostType == "VideoStory") {
      const slot = attachments[0];
      const aspect = roundDecimal(slot.preview.width / slot.preview.height, 2);

      if (aspect != 0.56) {
        errors.push(
          attachmentError(
            "Video aspect ratio must be 9:16. Recommended resolution is 1080x1920 pixels.",
            slot.id
          )
        );
      }
      if (slot.preview.width < 540 || slot.preview.height < 960) {
        errors.push(
          attachmentError(
            `Video resolution should be at least 540x960 pixels. Found ${slot.preview.width}x${slot.preview.height}.`,
            slot.id
          )
        );
      }

      if (slot.preview.fileSize > 1073741824) {
        errors.push(
          attachmentError("Video file size can't exceed 1GB.", slot.id)
        );
      }

      if (slot.preview.duration < 3 || slot.preview.duration > 90) {
        errors.push(
          attachmentError(
            "Video duration should be between 3 and 90 seconds.",
            slot.id
          )
        );
      }
    } else if (facebookConfig.contentOptions.systemPostType == "PhotoStory") {
      const slot = attachments[0];

      if (slot.preview.fileSize > 10485760) {
        errors.push(
          attachmentError("Image file size can't exceed 10MB.", slot.id)
        );
      }
    }
  }

  isValid = errors.length == 0;
  canPreview = errors.length == 0;

  // updateAttachmentStatus(postInstance, errors);

  return {
    isValid,
    canPreview,
    errors,
  } as ValidationResult;
}

export function validateInstagramPost(
  postInstance: PostInstance,
  attachments: AttachmentSlot[]
): ValidationResult {
  const instagramConfig = postInstance.postConfig.instagram;
  const errors: ValidationError[] = [];
  let isValid = true;
  let canPreview = true;

  if (instagramConfig.contentOptions.postType == "Post") {
    if (attachments.length == 0) {
      errors.push(formError("An Instagram post requires at least one photo."));
    } else if (attachments.length == 1) {
      const slot = attachments[0];

      if (slot.isVideo) {
        errors.push(
          formError("A single Instagram video can only be published as a Reel.")
        );
      } else {
        const aspect = roundDecimal(
          slot.preview.width / slot.preview.height,
          2
        );

        if (aspect < 0.8 || aspect > 1.91) {
          errors.push(
            attachmentError(
              "Image aspect ratio must be between 4:5 and 1.91:1.",
              slot.id
            )
          );
        }

        if (slot.preview.fileSize > 8388608) {
          errors.push(
            attachmentError("Image file size can't exceed 8MB.", slot.id)
          );
        }
      }
    } else if (attachments.length > 1) {
      if (attachments.length > 10) {
        errors.push(
          formError(
            "A carousel Instagram post can contain up to 10 photos or videos combined."
          )
        );
      } else {
        const invalidPhotoSizeattachments = attachments.filter(
          (slot) => slot.isPhoto && slot.preview.fileSize > 8388608
        );

        const invalidPhotoAspectRatioattachments = attachments.filter(
          (slot) => {
            const aspect = roundDecimal(
              slot.preview.width / slot.preview.height,
              2
            );
            return slot.isPhoto && (aspect < 0.8 || aspect > 1.91);
          }
        );

        const invalidVideoAspectRatioattachments = attachments.filter(
          (slot) => {
            const aspect = roundDecimal(
              slot.preview.width / slot.preview.height,
              2
            );
            return slot.isVideo && (aspect < 0.8 || aspect > 1.91);
          }
        );

        invalidPhotoSizeattachments.forEach((slot) =>
          errors.push(
            attachmentError("Image file size can't exceed 8MB.", slot.id)
          )
        );

        invalidPhotoAspectRatioattachments.forEach((slot) =>
          errors.push(
            attachmentError(
              "Image aspect ratio must be between 4:5 and 1.91:1.",
              slot.id
            )
          )
        );

        invalidVideoAspectRatioattachments.forEach((slot) =>
          errors.push(
            attachmentError(
              "Video aspect ratio must be between 4:5 to 1.91:1.",
              slot.id
            )
          )
        );
      }
    }
  } else if (instagramConfig.contentOptions.postType == "Reel") {
    if (!attachments.some((x) => x.isVideo)) {
      errors.push(
        formError("An Instagram reel requires a video to be attached.")
      );
    } else if (attachments.some((x) => !x.isVideo)) {
      attachments
        .filter((x) => !x.isVideo)
        .forEach((slot) =>
          errors.push(
            attachmentError(
              "An Instagram reel can't include any photo attachments",
              slot.id
            )
          )
        );
    } else if (attachments.length > 1) {
      errors.push(
        formError("An Instagram reel supports only one video attachment.")
      );
    } else {
      const slot = attachments[0];
      const aspect = roundDecimal(slot.preview.width / slot.preview.height, 2);

      if (aspect <= 0.1 || aspect >= 10) {
        errors.push(
          attachmentError(
            "Video aspect ratio must be between 0.1:1 to 10:1. Ideal resolution is 1080x1920 px.",
            slot.id
          )
        );
      } else if (slot.preview.width > 1920) {
        errors.push(
          attachmentError(
            "Video width should be less than or equal to 1920 pixels.",
            slot.id
          )
        );
      }

      if (slot.preview.duration < 3 || slot.preview.duration > 900) {
        errors.push(
          attachmentError(
            "Video duration should be between 3 seconds and 15 minutes.",
            slot.id
          )
        );
      }
      if (slot.preview.fileSize > 1073741824) {
        errors.push(
          attachmentError("Video file size can't exceed 1GB.", slot.id)
        );
      }
    }
  } else if (instagramConfig.contentOptions.postType == "Story") {
    if (postInstance.channel.accountType != "InstagramBusiness") {
      errors.push(
        formError(
          "An Instagram story can only be published to an Instagram Business account."
        )
      );
    } else if (attachments.length == 0) {
      errors.push(
        formError(
          "An Instagram story requires either one photo or video to be attached."
        )
      );
    } else if (attachments.length > 1) {
      errors.push(
        formError(
          "An Instagram story supports only one photo or video attachment."
        )
      );
    } else {
      const slot = attachments[0];

      if (instagramConfig.contentOptions.systemPostType == "VideoStory") {
        const aspect = roundDecimal(
          slot.preview.width / slot.preview.height,
          2
        );

        if (aspect <= 0.1 || aspect >= 10) {
          errors.push(
            attachmentError(
              "Video aspect ratio must be between 0.1:1 to 10:1. Ideal resolution is 1080x1920 px.",
              slot.id
            )
          );
        } else if (slot.preview.width > 1920) {
          errors.push(
            attachmentError(
              "Video width should be less than or equal to 1920 pixels.",
              slot.id
            )
          );
        }

        if (slot.preview.duration < 3 || slot.preview.duration > 60) {
          errors.push(
            attachmentError(
              "Video duration should be between 3 and 60 seconds.",
              slot.id
            )
          );
        }
        if (slot.preview.fileSize > 104857600) {
          errors.push(
            attachmentError("Video file size can't exceed 100MB.", slot.id)
          );
        }
      } else if (
        instagramConfig.contentOptions.systemPostType == "PhotoStory"
      ) {
        if (slot.preview.fileSize > 8388608) {
          errors.push(
            attachmentError("Image file size can't exceed 8MB.", slot.id)
          );
        }
      }
    }
  }

  isValid = errors.length == 0;
  canPreview = errors.length == 0;

  // updateAttachmentStatus(postInstance, errors);

  return {
    isValid,
    canPreview,
    errors,
  } as ValidationResult;
}

export function validateTwitterPost(
  postInstance: PostInstance,
  attachments: AttachmentSlot[]
): ValidationResult {
  const twitterConfig = postInstance.postConfig.twitter;

  const errors: ValidationError[] = [];
  let isValid = true;
  let canPreview = true;

  if (attachments.length == 0) {
    if (!twitterConfig.contentOptions?.caption?.length) {
      errors.push(
        formError("An X post requires at least one of caption, photo or video.")
      );
    }
  } else if (attachments.length == 1) {
    const slot = attachments[0];

    if (slot.isPhoto) {
      if (slot.preview.mimeType == "image/gif") {
        if (slot.preview.fileSize > 15728640) {
          errors.push(
            attachmentError("Gif file size can't exceed 15MB.", slot.id)
          );
        }
        if (slot.preview.width > 1280 || slot.preview.height > 1080) {
          errors.push(
            attachmentError("Gif dimensions can't exceed 1280x1080.", slot.id)
          );
        }
      } else {
        if (slot.preview.fileSize > 5242880) {
          errors.push(
            attachmentError("Image file size can't exceed 5MB.", slot.id)
          );
        }
      }
    } else if (slot.isVideo) {
      const aspectRatio = slot.preview.width / slot.preview.height;
      if (aspectRatio < 0.33 || aspectRatio > 3) {
        errors.push(
          attachmentError(
            "Video aspect ratio should be between 1:3 and 3:1.",
            slot.id
          )
        );
      }
      // if (
      //   slot.preview.width < 32 ||
      //   slot.preview.height < 32 ||
      //   slot.preview.width > 1280 ||
      //   slot.preview.height > 1024
      // ) {
      //   errors.push("Video dimensions should be between 32x32 and 1280x1024.");
      // }
      if (slot.preview.fileSize > 536870912) {
        errors.push(
          attachmentError("Video file size can't exceed 512MB.", slot.id)
        );
      }
      if (slot.preview.duration < 1 || slot.preview.duration > 140) {
        errors.push(
          attachmentError(
            "Video duration should be between 1 and 140 seconds.",
            slot.id
          )
        );
      }
    }
  } else if (attachments.length == 2) {
    if (!attachments[0].isPhoto || !attachments[1].isPhoto) {
      errors.push(
        formError("An X post can contain either 1 video or up to 4 photos.")
      );
    } else {
      const attachmentsExceedingSize = attachments.filter(
        (x) => x.preview.fileSize > 5242880
      );
      attachmentsExceedingSize.forEach((slot) => {
        errors.push(
          attachmentError("Image file size can't exceed 5MB.", slot.id)
        );
      });
    }
  } else if (attachments.length > 2) {
    if (attachments.some((x) => !x.isPhoto)) {
      attachments
        .filter((x) => !x.isPhoto)
        .forEach((slot) =>
          errors.push(
            attachmentError(
              "An X carousel post can only contain photos.",
              slot.id
            )
          )
        );
    } else if (attachments.length > 4) {
      errors.push(formError("An X carousel post can contain up to 4 photos."));
    }

    const attachmentsExceedingSize = attachments.filter(
      (x) => x.isPhoto && x.preview.fileSize > 5242880
    );
    attachmentsExceedingSize.forEach((slot) => {
      errors.push(
        attachmentError("Image file size can't exceed 5MB.", slot.id)
      );
    });
  }

  if (twitterConfig.contentOptions?.caption?.length > 280) {
    errors.push(
      formError("An X text post should contain less than 280 characters.")
    );
  }

  isValid = errors.length == 0;
  canPreview = errors.length == 0;

  // updateAttachmentStatus(postInstance, errors);

  return {
    isValid,
    canPreview,
    errors,
  } as ValidationResult;
}

export function validatePinterestPost(
  postInstance: PostInstance,
  attachments: AttachmentSlot[]
): ValidationResult {
  const pinterestConfig = postInstance.postConfig.pinterest;

  const errors: ValidationError[] = [];
  let isValid = true;
  let canPreview = true;

  if (attachments.length == 0) {
    errors.push(
      formError("A Pinterest post requires at least one photo or video.")
    );
  } else if (attachments.length == 1) {
    const slot = attachments[0];

    if (pinterestConfig.contentOptions.systemPostType == "ImagePin") {
      if (slot.preview.fileSize > 10485760) {
        errors.push(
          attachmentError("Image file size can't exceed 10MB.", slot.id)
        );
      }
    } else if (pinterestConfig.contentOptions.systemPostType == "VideoPin") {
      if (!slot.isVideo) {
        errors.push(
          attachmentError(
            "A Pinterest video post requires a video attachment.",
            slot.id
          )
        );
      } else {
        if (slot.preview.duration < 4 || slot.preview.duration > 900) {
          errors.push(
            attachmentError(
              "Video duration should be between 4 seconds and 15 minutes.",
              slot.id
            )
          );
        }

        if (slot.preview.fileSize > 2147483648) {
          errors.push(
            attachmentError("Video file size can't exceed 2GB.", slot.id)
          );
        }
      }
    }
  } else if (attachments.length > 1) {
    if (attachments.length > 5) {
      errors.push(
        formError(
          "A Pinterest carousel post supports maximum of 5 photo attachments."
        )
      );
    }

    const videoattachments = attachments.filter((x) => x.isVideo);
    const photoattachments = attachments.filter((x) => x.isPhoto);

    videoattachments.forEach((slot) => {
      errors.push(
        attachmentError(
          "Video files are not supported in a carousel Pin",
          slot.id
        )
      );
    });

    const aspects = photoattachments.map((x) =>
      roundDecimal(x.preview.width / x.preview.height, 2)
    );

    if (aspects.some((x) => x != aspects[0])) {
      errors.push(
        formError(
          "All photos in a carousel post should have the same aspect ratio."
        )
      );
    }

    const invalidPhotoSizeattachments = photoattachments.filter(
      (x) => x.preview.fileSize > 10485760
    );
    invalidPhotoSizeattachments.forEach((slot) =>
      errors.push(
        attachmentError("Image file size can't exceed 10MB.", slot.id)
      )
    );
  }

  isValid = errors.length == 0;
  canPreview = errors.length == 0;

  if (pinterestConfig.contentOptions.link) {
    if (!isValidUrl(pinterestConfig.contentOptions.link)) {
      errors.push(
        formError(
          "Pinterest Link is not a valid URL. Valid urls start with http:// or https://"
        )
      );
      isValid = false;
    }
  }

  if (
    isValid &&
    !postInstance.postConfig.pinterest.contentOptions?.boardId?.length
  ) {
    errors.push(formError("A Pinterest board has to be selected"));
    isValid = false;
  }

  // updateAttachmentStatus(postInstance, errors);

  return {
    isValid,
    canPreview,
    errors,
  } as ValidationResult;
}

export function validateYouTubePost(
  postInstance: PostInstance,
  attachments: AttachmentSlot[]
): ValidationResult {
  const youTubeConfig = postInstance.postConfig.youTube;

  const errors: ValidationError[] = [];
  let isValid = true;
  let canPreview = true;

  if (youTubeConfig.contentOptions.systemPostType == "ShortVideo") {
    if (attachments.some((x) => !x.isVideo)) {
      attachments
        .filter((x) => !x.isVideo)
        .forEach((slot) =>
          errors.push(
            attachmentError(
              "A YouTube short can't include any photo attachments.",
              slot.id
            )
          )
        );
    } else if (attachments.length == 0) {
      errors.push(formError("A YouTube short should contain a video."));
    } else if (attachments.length > 1) {
      errors.push(formError("A YouTube short can contain only one video."));
    } else if (attachments.length == 1) {
      const slot = attachments[0];

      if (slot) {
        if (slot.preview.duration > 60) {
          errors.push(
            attachmentError(
              "A YouTube short video should be less than 60 seconds.",
              slot.id
            )
          );
        }

        const aspectRatio = slot.preview.width / slot.preview.height;

        if (aspectRatio != 9 / 16 && aspectRatio != 1) {
          errors.push(
            attachmentError(
              "A YouTube short aspect ratio can be either Square (1:1) or Portrait (9:16).",
              slot.id
            )
          );
        }
      }

      if (!youTubeConfig.contentOptions.title?.length) {
        errors.push(formError("A YouTube short should have a title."));
      }
    }
  }

  isValid = errors.length == 0;
  canPreview = errors.length == 0;

  // updateAttachmentStatus(postInstance, errors);

  return {
    isValid,
    canPreview,
    errors,
  } as ValidationResult;
}

export function validateTikTokPost(
  postInstance: PostInstance,
  attachments: AttachmentSlot[]
): ValidationResult {
  const tikTokConfig = postInstance.postConfig.tikTok;

  const errors: ValidationError[] = [];
  let isValid = true;
  let canPreview = true;

  const tikTokCreator = postInstance?.extras?.creatorInfo as TikTokCreator;

  if (tikTokConfig.contentOptions.postType == "Video") {
    if (!attachments.some((x) => x.isVideo)) {
      errors.push(
        formError("A TikTok video requires a video file to be attached.")
      );
    } else if (attachments.some((x) => !x.isVideo)) {
      errors.push(
        formError("A Video post can't include any photo attachments.")
      );
    } else if (attachments.length > 1) {
      errors.push(
        formError("A TikTok video supports only one video attachment.")
      );
    } else {
      if (
        tikTokCreator?.max_video_post_duration_sec <
        attachments[0].preview.duration
      ) {
        errors.push(
          attachmentError(
            `Maximum video duration for this creator is ${tikTokCreator.max_video_post_duration_sec} seconds.`,
            attachments[0].id
          )
        );
      }

      if (
        attachments[0].preview.width < 360 ||
        attachments[0].preview.height < 360 ||
        attachments[0].preview.width > 4096 ||
        attachments[0].preview.height > 4096
      ) {
        errors.push(
          attachmentError(
            `Video resolution should be between 360x360 and 4096x4096 pixels. Found ${attachments[0].preview.width}x${attachments[0].preview.height}.`,
            attachments[0].id
          )
        );
      }

      if (attachments[0].preview.fileSize > 4294967296) {
        errors.push(
          attachmentError(
            "Video file size can't exceed 4GB.",
            attachments[0].id
          )
        );
      }

      if (
        !["video/mp4", "video/webm", "video/quicktime"].includes(
          attachments[0].preview.mimeType
        )
      ) {
        errors.push(
          attachmentError(
            "TikTok Video posts support only MP4, WebM and MOV video formats.",
            attachments[0].id
          )
        );
      }
    }
  } else if (tikTokConfig.contentOptions.postType == "Photo") {
    if (attachments.length == 0) {
      errors.push(
        formError(
          "A TikTok photo post requires at least one photo to be attached."
        )
      );
    } else if (attachments.some((x) => !x.isPhoto)) {
      errors.push(formError("A Photo post can't include video file."));
    }

    if (attachments.length > 35) {
      errors.push(
        formError("A TikTok photo post supports maximum of 35 photos.")
      );
    }

    if (attachments.some((x) => x.preview.fileSize > 10485760)) {
      errors.push(formError("Image file size can't exceed 10MB."));
    }

    const invalidSizeattachments = attachments.filter((x) => {
      const width = x.preview.width;
      const height = x.preview.height;

      return !(
        (width <= 1920 && height <= 1080) ||
        (width <= 1080 && height <= 1920)
      );
    });

    invalidSizeattachments.forEach((slot) =>
      errors.push(
        attachmentError(
          "Image resolution can't exceed 1920x1080 or 1080x1920.",
          slot.id
        )
      )
    );

    const invalidTypeattachments = attachments.filter(
      (x) => !["image/webp", "image/jpeg"].includes(x.preview.mimeType)
    );

    invalidTypeattachments.forEach((slot) =>
      errors.push(
        attachmentError(
          "TikTok Photo posts support only JPEG and WebP image formats.",
          slot.id
        )
      )
    );
  }

  isValid = errors.length == 0;
  canPreview = errors.length == 0;

  if (!errors.length) {
    if (!tikTokConfig.contentOptions.privacyStatus) {
      errors.push(
        formError("You need to set the privacy status of your TikTok post.")
      );
      isValid = false;
    } else if (
      tikTokConfig.contentOptions.commercialContent &&
      !tikTokConfig.contentOptions.promotionalContent &&
      !tikTokConfig.contentOptions.paidPartnership
    ) {
      errors.push(
        formError(
          "You need to indicate if your content promotes yourself, a third party, or both."
        )
      );
      isValid = false;
    }
  }

  // updateAttachmentStatus(postInstance, errors);

  return {
    isValid,
    canPreview,
    errors,
  } as ValidationResult;
}

export function validateThreadsPost(
  postInstance: PostInstance,
  attachments: AttachmentSlot[]
): ValidationResult {
  const threadsConfig = postInstance.postConfig.threads;

  const errors: ValidationError[] = [];
  let isValid = true;
  let canPreview = true;

  for (let i = 0; i < attachments.length; i++) {
    const slot = attachments[i];
    const mimeType = slot.preview.mimeType;
    const aspectRatio = slot.preview.width / slot.preview.height;

    if (slot.isPhoto) {
      if (slot.preview.fileSize > 8388608) {
        errors.push(
          attachmentError("Image file size can't exceed 8MB.", slot.id)
        );
      }
      if (mimeType != "image/jpeg" && mimeType != "image/png") {
        errors.push(
          attachmentError(
            "Image file type must be either jpeg or png.",
            slot.id
          )
        );
      }

      if (slot.preview.width < 360) {
        errors.push(
          attachmentError(
            `Image width should be at least 360px. Found ${slot.preview.width}px.`,
            slot.id
          )
        );
      }

      if (slot.preview.width > 1440) {
        errors.push(
          attachmentError(
            `Image width should be at most 1440px. Found ${slot.preview.width}px.`,
            slot.id
          )
        );
      }

      if (aspectRatio > 10) {
        errors.push(
          attachmentError(
            `Image aspect ratio should be less than 10:1.`,
            slot.id
          )
        );
      }
    } else if (slot.isVideo) {
      if (mimeType != "video/mp4" && mimeType != "video/quicktime") {
        errors.push(
          attachmentError("Video file type must be either MP4 or MOV.", slot.id)
        );
      }
      if (slot.preview.fileSize > 1073741824) {
        errors.push(
          attachmentError("Video file size can't exceed 1GB.", slot.id)
        );
      }
      if (aspectRatio < 0.01 || aspectRatio > 10) {
        errors.push(
          attachmentError(
            `Video aspect ratio should be between 0.01:1 and 10:1 but recommended is 9:16. Found ${slot.preview.width}:${slot.preview.height}.`,
            slot.id
          )
        );
      }
      if (slot.preview.duration < 1 || slot.preview.duration > 300) {
        errors.push(
          attachmentError(
            `Video duration should be between 1 and 300 seconds. Found ${slot.preview.duration}.`,
            slot.id
          )
        );
      }
    }
  }

  const invalidCarouselAttachmentCount =
    threadsConfig.contentOptions.instances.some(
      (x) =>
        x.systemPostType == "CarouselPost" &&
        (x.attachmentIds.length < 2 || x.attachmentIds.length > 20)
    );

  const invalidSingleAttachmentCount =
    threadsConfig.contentOptions.instances.some(
      (x) =>
        (x.systemPostType == "PhotoPost" || x.systemPostType == "VideoPost") &&
        x.attachmentIds.length != 1
    );

  if (invalidCarouselAttachmentCount) {
    errors.push(
      formError("A carousel post can contain between 2 and 20 attachments.")
    );
  }

  if (invalidSingleAttachmentCount) {
    errors.push(formError("A single post can contain only 1 attachment."));
  }

  const invalidThreadsCount = threadsConfig.contentOptions.instances.filter(
    (x) => !x.message && !x.link && !x.attachmentIds.length
  ).length;

  if (invalidThreadsCount > 0) {
    errors.push(
      formError("A Thread requires at least a message or attachment.")
    );
  }

  isValid = errors.length == 0 && invalidThreadsCount == 0;
  canPreview =
    errors.length == 0 ||
    invalidThreadsCount != threadsConfig.contentOptions.instances.length;

  return {
    isValid,
    canPreview,
    errors,
  } as ValidationResult;
}

export function validateLinkedInPost(
  postInstance: PostInstance,
  attachments: AttachmentSlot[]
): ValidationResult {
  const linkedInConfig = postInstance.postConfig.linkedIn;

  const errors: ValidationError[] = [];
  let isValid = true;
  let canPreview = true;

  const systemPostType = linkedInConfig.contentOptions.systemPostType;

  if (systemPostType == "PhotoPost") {
    if (
      attachments.length != 1 ||
      (attachments.length == 1 && !attachments[0].isPhoto)
    ) {
      errors.push(formError("A LinkedIn photo post should contain a photo."));
    } else {
      const slot = attachments[0];

      if (slot.preview.fileSize > 8388608) {
        errors.push(
          attachmentError("Image file size can't exceed 8MB.", slot.id)
        );
      } else if (slot.preview.width * slot.preview.height > 36152320) {
        errors.push(
          attachmentError(
            "Image width x height can't exceed 36,152,320.",
            slot.id
          )
        );
      }
    }
  } else if (systemPostType == "VideoPost") {
    if (
      attachments.length != 1 ||
      (attachments.length == 1 && !attachments[0].isVideo)
    ) {
      errors.push(formError("A LinkedIn video post should contain a video."));
    } else {
      const slot = attachments[0];

      if (slot.preview.fileSize < 76800 || slot.preview.fileSize > 524288000) {
        errors.push(
          attachmentError(
            "Video file size should be betwen 75KB and 500MB",
            slot.id
          )
        );
      }

      if (slot.preview.duration < 3 || slot.preview.duration > 30 * 60) {
        errors.push(
          attachmentError(
            "LinkedIn Video duration should be between 3 seconds and 30 minutes.",
            slot.id
          )
        );
      }
    }
  } else if (systemPostType == "MultiPhotoPost") {
    if (attachments.length < 2 || attachments.length > 20) {
      errors.push(
        formError(
          "A LinkedIn multi-image post should contain between 2 and 20 images."
        )
      );
    }

    const nonPhotos = attachments.filter((x) => !x.isPhoto);

    nonPhotos.forEach((slot) =>
      errors.push(
        attachmentError(
          "A LinkedIn multi-image post should contain only images.",
          slot.id
        )
      )
    );

    const invalidPhotoSizeattachments = attachments.filter(
      (x) => x.isPhoto && x.preview.fileSize > 8388608
    );

    invalidPhotoSizeattachments.forEach((slot) =>
      errors.push(attachmentError("Image file size can't exceed 8MB.", slot.id))
    );

    const invalidPhotoAspectRatioAttachments = attachments.filter((x) => {
      return x.isPhoto && x.preview.width * x.preview.height > 36152320;
    });

    invalidPhotoAspectRatioAttachments.forEach((slot) =>
      errors.push(
        attachmentError(
          "Image width x height can't exceed 36,152,320.",
          slot.id
        )
      )
    );
  } else if (systemPostType == "DocumentPost") {
    if (
      attachments.length != 1 ||
      (attachments.length == 1 && !attachments[0].isDocument)
    ) {
      errors.push(
        formError(
          "A LinkedIn document post should contain a document attachment."
        )
      );
    } else {
      const slot = attachments[0];

      if (slot.preview.fileSize > 104857600) {
        errors.push(
          attachmentError("Document file size can't exceed 100MB.", slot.id)
        );
      }
    }
  } else if (systemPostType == "TextPost") {
    if (!linkedInConfig.contentOptions.commentary) {
      errors.push(formError("A LinkedIn text post should contain a message."));
    }
  }

  isValid = errors.length == 0;
  canPreview = errors.length == 0;

  // updateAttachmentStatus(postInstance, errors);

  return {
    isValid,
    canPreview,
    errors,
  } as ValidationResult;
}

// function updateAttachmentStatus(
//   postInstance: PostInstance,
//   errors: ValidationError[]
// ) {
//   // postInstance.attachmentattachments.forEach((slot) => {
//   //   slot.status =
//   //     slot.status == "readyToUpload"
//   //       ? errors.some((x) => x.attachmentId == slot.id)
//   //         ? "validationFailed"
//   //         : "validated"
//   //       : slot.status;
//   // });
// }
