import Post from "../../../../../models/entities/post";
import MetricsViewMode from "../../models/metrics-view-mode";
import FacebookMetrics from "./FacebookMetrics";
import InstagramMetrics from "./InstagramMetrics";
import LinkedInMetrics from "./LinkedInMetrics";
import PinterestMetrics from "./PinterestMetrics";
import ThreadsMetrics from "./ThreadsMetrics";
import TikTokMetrics from "./TikTokMetrics";
import TwitterMetrics from "./TwitterMetrics";
import YouTubeMetrics from "./YouTubeMetrics";

export interface MetricsProps {
  post: Post;
  metricsViewMode: MetricsViewMode;
}

export default function Metrics({ post, metricsViewMode }: MetricsProps) {
  if (metricsViewMode == "hidden") {
    return null;
  }

  let content = null;
  if (post.config.channelType == "Facebook" && post.metrics?.facebook) {
    content = <FacebookMetrics post={post} metricsViewMode={metricsViewMode} />;
  } else if (
    post.config.channelType == "Instagram" &&
    post.metrics?.instagram
  ) {
    content = (
      <InstagramMetrics post={post} metricsViewMode={metricsViewMode} />
    );
  } else if (post.config.channelType == "Threads" && post.metrics?.threads) {
    content = <ThreadsMetrics post={post} metricsViewMode={metricsViewMode} />;
  } else if (post.config.channelType == "LinkedIn" && post.metrics?.linkedIn) {
    content = <LinkedInMetrics post={post} metricsViewMode={metricsViewMode} />;
  } else if (post.config.channelType == "Twitter" && post.metrics?.twitter) {
    content = <TwitterMetrics post={post} metricsViewMode={metricsViewMode} />;
  } else if (post.config.channelType == "YouTube" && post.metrics?.youTube) {
    content = <YouTubeMetrics post={post} metricsViewMode={metricsViewMode} />;
  } else if (
    post.config.channelType == "Pinterest" &&
    post.metrics?.pinterest
  ) {
    content = (
      <PinterestMetrics post={post} metricsViewMode={metricsViewMode} />
    );
  } else if (post.config.channelType == "TikTok" && post.metrics?.tikTok) {
    content = <TikTokMetrics post={post} metricsViewMode={metricsViewMode} />;
  }

  return content == null ? null : <div className="px-4 pb-2">{content}</div>;
}
