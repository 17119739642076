import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from "@headlessui/react";
import {
  ChevronDownIcon,
  CheckIcon,
  ChevronUpIcon,
} from "@heroicons/react/20/solid";
import { AttachmentType } from "../../../../models/entities/attachment";
import {
  DocumentIcon,
  FolderIcon,
  PhotoIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/outline";
import { ReactNode } from "react";

const options: {
  value?: AttachmentType;
  label: string;
  icon: ReactNode;
}[] = [
  { value: null, label: "All Types", icon: <FolderIcon className="h-5 w-5" /> },
  {
    value: AttachmentType.Photo,
    label: "Photos",
    icon: <PhotoIcon className="h-5 w-5" />,
  },
  {
    value: AttachmentType.Video,
    label: "Videos",
    icon: <VideoCameraIcon className="h-5 w-5" />,
  },
  {
    value: AttachmentType.Document,
    label: "Documents",
    icon: <DocumentIcon className="h-5 w-5" />,
  },
];

export interface FileTypePickerProps {
  attachmentType?: AttachmentType;
  onChanged: (value: AttachmentType) => void;
}

export default function FileTypePicker({
  attachmentType,
  onChanged,
}: FileTypePickerProps) {
  return (
    <Listbox value={attachmentType} onChange={onChanged}>
      {({ open }) => (
        <div className="relative w-40">
          <ListboxButton className="relative w-full cursor-pointer rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 focus:outline-none focus:ring-1 text-sm leading-6">
            <div className="flex items-center gap-2">
              <span>
                {options.find((o) => o.value === attachmentType)?.icon}
              </span>
              <span className="block truncate">
                {options.find((o) => o.value === attachmentType)?.label ??
                  "All"}
              </span>
            </div>

            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              {open ? (
                <ChevronUpIcon className=" h-5 w-5 text-gray-400" />
              ) : (
                <ChevronDownIcon className=" h-5 w-5 text-gray-400" />
              )}
            </span>
          </ListboxButton>

          <ListboxOptions
            anchor="bottom"
            transition
            className="w-[var(--button-width)] [--anchor-gap:var(--spacing-1)] focus:outline-none transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0 z-50 !max-h-56 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm"
          >
            {options?.map((option) => (
              <ListboxOption
                key={option.value}
                value={option.value}
                className="group relative select-none py-2 pl-3 rounded-md mx-1 cursor-pointer pr-9 text-gray-900 data-[focus]:bg-primary-600 data-[focus]:text-white data-[disabled]:bg-gray-100 data-[disabled]:opacity-50"
              >
                <div className="flex items-center gap-2">
                  <span>{option.icon}</span>

                  <span className="block truncate font-normal group-data-[selected]:font-semibold">
                    {option.label}
                  </span>
                </div>

                <span className="hidden md:flex absolute inset-y-0 right-0 items-center pr-2 text-primary-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                  <CheckIcon className="h-5 w-5" />
                </span>
              </ListboxOption>
            ))}
          </ListboxOptions>
        </div>
      )}
    </Listbox>
  );
}
