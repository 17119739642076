import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import sessionService from "../services/application/session-service";
import actionTypes from "../state/global-state/action-types";
import GlobalStateContext from "../state/global-state/GlobalStateContext";
import Channel from "../models/entities/channel";

const useSelectChannel = () => {
  const { dispatch } = useContext(GlobalStateContext);
  const navigate = useNavigate();

  return (channel?: Channel) => {
    dispatch({ type: actionTypes.SET_CURRENT_CHANNEL, payload: channel });
    sessionService.setCurrentChannel(channel?.id);

    if (
      !location.pathname.startsWith("/scheduler") &&
      !location.pathname.startsWith("/analytics")
    ) {
      navigate(`/scheduler/calendar`);
    }
  };
};

export default useSelectChannel;
