import Button from "./Button";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
// import { useAnimation } from "../../hooks/useAnimation";
import animation from "../../assets/animations/locked2.json";
import Lottie from "react-lottie-player";

export interface LockedFeatureProps {
  flat?: boolean;
}

export default function LockedFeature({ flat }: LockedFeatureProps) {
  const navigate = useNavigate();

  // const { animation } = useAnimation("/animations/locked2.json");

  return (
    <div
      className={clsx(
        "flex flex-col gap-8 items-center max-w-md mx-auto p-8 sm:p-14 sm:max-w-lg bg-white rounded-xl relative",
        !flat && "shadow"
      )}
    >
      <div className="text-3xl font-bold tracking-tight text-gray-900">
        Feature Locked!
      </div>
      <div className="-my-4">
        {animation && (
          <Lottie
            animationData={animation}
            play
            speed={1}
            style={{ height: 150 }}
          />
        )}
      </div>
      <div className="mx-auto mt-6 text-md text-gray-600 text-center">
        It looks like this feature isn’t available on your current plan. To
        unlock it, you can upgrade to a higher plan. Feel free to check out our
        pricing page or contact us for more details!
      </div>

      <div>
        <Button
          onClick={() => navigate("/settings/plan")}
          variant="solid"
          color="brand"
          text="Check Plans"
        />
      </div>

      <svg
        viewBox="0 0 1024 1024"
        aria-hidden="true"
        className="absolute left-1/2 top-0 -z-10 h-[32rem] w-[32rem] sm:h-[38rem] sm:w-[38rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
      >
        <circle
          r={512}
          cx={512}
          cy={512}
          fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)"
          fillOpacity="0.7"
        />
        <defs>
          <radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217">
            <stop stopColor="#7775D6" />
            <stop offset={1} stopColor="#E935C1" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  );
}
