import { Link, useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Button from "../../../components/common/Button";
import { useState } from "react";
import { confirmResetPassword, resetPassword } from "aws-amplify/auth";
import { XCircleIcon } from "@heroicons/react/20/solid";
import PasswordField from "../../../components/form/PasswordField";
import InputField from "../../../components/form/InputField";
import alertService from "../../../services/alert/alert-service";
import ValidationMessages from "../../../data/validation-messages";

const digitsOnly = (value) => /^\d+$/.test(value);

const schema = yup
  .object({
    code: yup
      .string()
      .required(ValidationMessages.OtpCodeRequired)
      .test("Digits", ValidationMessages.OtpCodeDigitsOnly, digitsOnly)
      .length(6, ValidationMessages.OtpCodeLength(6)),
    password: yup.string().required(ValidationMessages.NewPasswordRequired),
    repeatPassword: yup
      .string()
      .required("Please repeat your new password")
      .oneOf([yup.ref("password")], "Passwords must match"),
  })
  .required();

interface FormInput {
  code?: string;
  password?: string;
  repeatPassword?: string;
}

export default function ResetPassword() {
  const navigate = useNavigate();

  const search = useLocation().search;
  const email = new URLSearchParams(search).get("email");
  const [submitting, setSubmitting] = useState(false);
  const [resending, setResending] = useState(false);
  const [error, setError] = useState("");

  const form = useForm<FormInput>({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const submitForm = () => {
    form.handleSubmit(onSubmit)();
  };

  const onSubmit = async (input: FormInput) => {
    setError("");

    try {
      setSubmitting(true);

      await confirmResetPassword({
        username: email,
        newPassword: input.password,
        confirmationCode: input.code,
      });

      navigate("/login");
      alertService.success("Password reset successfully");
    } catch (e) {
      setError(e.message);
    } finally {
      setSubmitting(false);
    }
  };

  const resendCode = async () => {
    setError("");

    try {
      setResending(true);

      await resetPassword({
        username: email,
      });

      alertService.success("Code resent successfully");
    } catch (e) {
      setError(e.message);
    } finally {
      setResending(false);
    }
  };

  return (
    <>
      <div className="text-center">
        <h2 className="mt-8 text-2xl/9 font-bold tracking-tight text-gray-900">
          Reset your password
        </h2>
        <p className="mt-2 text-sm text-gray-500">
          Enter the 6-digit OTP code received at the email address associated
          with the email address entered on the previous step.
        </p>
      </div>

      <div className="mt-10">
        <div>
          {error && (
            <div className="mb-6 rounded-md bg-red-50 p-4">
              <div className="flex">
                <div className="shrink-0">
                  <XCircleIcon
                    aria-hidden="true"
                    className="size-5 text-red-400"
                  />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">{error}</h3>
                </div>
              </div>
            </div>
          )}

          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            {/* <OtpCodeField maxLength={6} onComplete={onCodeComplete} /> */}

            <div>
              <InputField
                label="Code"
                name="code"
                formReturn={form}
                maxLength={6}
                hideCharCounter={true}
              />
            </div>

            <div>
              <PasswordField
                label="Password"
                name="password"
                formReturn={form}
              />
            </div>

            <div>
              <PasswordField
                label="Confirm password"
                name="repeatPassword"
                formReturn={form}
              />
            </div>

            <div>
              <Button
                onClick={submitForm}
                text="Reset password"
                color="brand"
                variant="solid"
                disabled={resending || submitting}
                loading={submitting}
                type="submit"
                className="w-full"
              />
            </div>
          </form>
        </div>

        <p className="mt-4 text-sm/6 text-gray-500 text-center">
          <Button
            text="Resend Code"
            variant="text"
            color="brand"
            onClick={resendCode}
            disabled={resending || submitting}
            loading={resending}
          />
        </p>

        <p className="mt-4 text-center">
          <Link
            to="/login"
            className="text-sm/6 text-gray-600 hover:text-gray-900 transition-all cursor-pointer"
          >
            ← Return to sign in
          </Link>
        </p>
      </div>
    </>
  );
}
