import { useQuery } from "@tanstack/react-query";
import queryNames from "../../../../queries/query-names";
import stripeService from "../../../../services/api/stripe-service";
import Spinner from "../../../../components/common/Spinner";

import Button from "../../../../components/common/Button";
import cardMappings from "../../../../data/payment-cards";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import { useState } from "react";

export default function PaymentMethods() {
  const [isLoadingPortal, setIsLoadingPortal] = useState(false);

  const { data: paymentMethods, isLoading } = useQuery({
    queryKey: [queryNames.stripePaymentMethods],
    queryFn: stripeService.getPaymentMethods,
  });

  const openBillingPortal = async () => {
    setIsLoadingPortal(true);

    try {
      const returnUrl = `${window.location.origin}/settings/billing`;
      const customerPortalSession = await stripeService.getCustomerPortal(
        returnUrl
      );

      window.location.href = customerPortalSession.url;
    } catch {
      setIsLoadingPortal(false);
    }
  };

  const openAddPaymentMethod = async () => {
    setIsLoadingPortal(true);

    try {
      const returnUrl = `${window.location.origin}/settings/billing`;

      const customerPortalSession = await stripeService.getCustomerPortal(
        returnUrl,
        "payment_method_update"
      );

      window.location.href = customerPortalSession.url;
    } catch {
      setIsLoadingPortal(false);
    }
  };

  const manageComponent = (
    <div className="flex justify-start mt-2">
      <Button
        className="-ml-4"
        onClick={() => openBillingPortal()}
        target="_blank"
        variant="text"
        color="brand"
        text=""
        disabled={isLoadingPortal}
      >
        {isLoadingPortal ? (
          <div className="flex items-center gap-2">
            <span>Loading</span>
            <Spinner size="4" />
          </div>
        ) : (
          <div className="flex items-center gap-2">
            <span>Manage</span>
            <ArrowTopRightOnSquareIcon className="w-4" />
          </div>
        )}
      </Button>
    </div>
  );

  const addNewComponent = (
    <div className="flex justify-start mt-2">
      <Button
        className="-ml-4"
        onClick={() => openAddPaymentMethod()}
        target="_blank"
        variant="text"
        color="brand"
        text=""
        disabled={isLoadingPortal}
      >
        {isLoadingPortal ? (
          <div className="flex items-center gap-2 mt-4">
            <span>Loading</span>
            <Spinner size="4" />
          </div>
        ) : (
          <div className="flex items-center gap-2 mt-4">
            <span>Add new payment method</span>
            <ArrowTopRightOnSquareIcon className="w-4" />
          </div>
        )}
      </Button>
    </div>
  );

  const content = isLoading ? (
    <div className="flex-1 h-full flex items-center justify-center">
      <Spinner size="5" />
    </div>
  ) : (
    <>
      <div className="flex-1 mt-4">
        {!paymentMethods.length ? (
          <>
            <div className="flex flex-col">
              <div className="text-sm text-gray-700">No payment methods</div>
              {addNewComponent}
            </div>
          </>
        ) : (
          <>
            <table>
              <tbody>
                {paymentMethods.map((paymentMethod, index) => (
                  <tr key={index} className="">
                    <td className="">
                      <div className="flex items-center gap-2 text-sm font-medium text-gray-900 py-2">
                        <div>
                          <img
                            width="25"
                            src={
                              cardMappings?.[paymentMethod?.card?.brand]
                                ?.image ?? cardMappings.unknown.image
                            }
                          />
                        </div>
                        <span className="capitalize">
                          {paymentMethod.card?.brand}
                        </span>
                        <span className="-mt-1">....</span>
                        <span>{paymentMethod.card?.last4}</span>
                      </div>
                    </td>
                    <td className="px-8">
                      <div className="flex items-center text-sm text-gray-700 sm:col-span-2 sm:mt-0">
                        Expires {paymentMethod.card?.expMonth}/
                        {paymentMethod.card?.expYear}
                      </div>
                    </td>
                    {/* <td>
                      <Button
                        onClick={() => updatePaymentMethod(paymentMethod)}
                        target="_blank"
                        variant="text"
                        color="brand"
                        text=""
                        disabled={isLoadingPortal}
                      >
                        <div className="flex items-center gap-2">
                          {updating?.id == paymentMethod.id ? (
                            <Spinner size="4" />
                          ) : (
                            <div className="flex items-center gap-2">
                              <span>Update</span>
                              <ArrowTopRightOnSquareIcon className="w-4" />
                            </div>
                          )}
                        </div>
                      </Button>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
            {manageComponent}
          </>
        )}
      </div>
    </>
  );

  return (
    <>
      <div className="divide-y divide-gray-200">
        <div className="space-y-1">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Payment Methods
          </h2>

          <p className="max-w-2xl text-sm text-gray-500">
            View & manage your payment methods
          </p>
        </div>
        <div className="mt-4 min-h-20 flex flex-col">{content}</div>
      </div>
    </>
  );
}
