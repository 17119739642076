/* eslint-disable @typescript-eslint/no-explicit-any */
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CreatePostFormInput from "../../../../../models/form-inputs/create-post-input";
import * as yup from "yup";
import { FacebookPostType } from "../../../../../models/entities/post";
import { ReactNode, useEffect } from "react";
import { PostInstance } from "../../models/post-instance";
import PrimaryTextBox from "../../../../../components/common/PrimaryTextBox";
import eventBusService from "../../../../../services/application/event-bus/event-bus-service";
import EventBusEvents from "../../../../../services/application/event-bus/event-bus-events";
import TextAreaField from "../../../../../components/form/TextAreaField";
import AttachmentSlot from "../../models/attachment-slot";

interface FacebookFormProps {
  postInstance: PostInstance;
  attachments: AttachmentSlot[];
  onPostInstanceChanged: (postInstance: PostInstance, touched: boolean) => void;
  children?: ReactNode | (({ primaryForm, secondaryForm }) => ReactNode);
}

const schema = yup
  .object({
    description: yup.string().required().max(5000),
    firstComment: yup.string().max(500),
  })
  .required();

export default function FacebookForm({
  postInstance,
  attachments,
  onPostInstanceChanged,
  children,
}: FacebookFormProps) {
  const facebookConfig = postInstance.postConfig.facebook;

  const form = useForm<CreatePostFormInput>({
    resolver: yupResolver(schema) as any,
    defaultValues: {
      description: facebookConfig.contentOptions?.message ?? "",
      firstComment: facebookConfig.firstCommentOptions?.comment ?? "",
    },
  });

  useEffect(() => {
    const insertAiContent = (content) => {
      form.setValue("description", content);
      onTextChanged(content);
    };

    eventBusService.on(EventBusEvents.INSERT_AI_CONTENT, insertAiContent);

    return () => {
      eventBusService.remove(EventBusEvents.INSERT_AI_CONTENT, insertAiContent);
    };
  });

  useEffect(() => {
    const setSystemPostType = () => {
      let systemPostType = facebookConfig.contentOptions.systemPostType;
      const initialSystemPostType = systemPostType;

      if (facebookConfig.contentOptions.postType == "Post") {
        if (attachments.length == 0) {
          systemPostType = "TextPost";
        } else if (attachments.length == 1) {
          systemPostType = attachments[0].isPhoto
            ? "SinglePhotoPost"
            : "VideoPost";
        } else if (attachments.length > 1) {
          systemPostType = "MultiPhotoPost";
        }
      } else if (facebookConfig.contentOptions.postType == "Reel") {
        systemPostType = "Reel";
      } else if (facebookConfig.contentOptions.postType == "Story") {
        systemPostType =
          attachments.length > 0
            ? attachments[0].isPhoto
              ? "PhotoStory"
              : "VideoStory"
            : "PhotoStory";
      }

      if (systemPostType != initialSystemPostType) {
        const message = form.getValues("description") ?? "";

        const updatedPostInstance: PostInstance = {
          ...postInstance,
          postConfig: {
            ...postInstance.postConfig,
            facebook: {
              ...postInstance.postConfig.facebook,
              contentOptions: {
                ...facebookConfig.contentOptions,
                systemPostType: systemPostType,
                message: message,
              },
            },
          },
        };

        onPostInstanceChanged(updatedPostInstance, false);
      }
    };

    setSystemPostType();
  }, [attachments, facebookConfig, form, postInstance]);

  const selectPostType = (postType: FacebookPostType) => {
    const updatedPostInstance: PostInstance = {
      ...postInstance,
      postConfig: {
        ...postInstance.postConfig,
        facebook: {
          ...postInstance.postConfig.facebook,
          contentOptions: {
            ...facebookConfig.contentOptions,
            postType: postType,
          },
        },
      },
    };

    onPostInstanceChanged(updatedPostInstance, true);
  };

  const onTextChanged = (text: string) => {
    const updatedPostInstance: PostInstance = {
      ...postInstance,
      title: text,
      postConfig: {
        ...postInstance.postConfig,
        facebook: {
          ...postInstance.postConfig.facebook,
          contentOptions: {
            ...facebookConfig.contentOptions,
            message: text,
          },
        },
      },
    };

    onPostInstanceChanged(updatedPostInstance, true);
  };

  const onFirstCommentChanged = (text: string) => {
    const updatedPostInstance: PostInstance = {
      ...postInstance,
      postConfig: {
        ...postInstance.postConfig,
        facebook: {
          ...postInstance.postConfig.facebook,
          firstCommentOptions: {
            ...postInstance.postConfig.facebook.firstCommentOptions,
            comment: text,
          },
        },
      },
    };

    onPostInstanceChanged(updatedPostInstance, true);
  };

  const primaryForm = (
    <>
      <div className="flex flex-row">
        <fieldset className="w-full mt-0.5">
          <div className="flex items-center space-x-10">
            <div
              className="flex items-center cursor-pointer"
              onClick={() => selectPostType("Post")}
            >
              <input
                name="postType"
                type="radio"
                readOnly
                checked={facebookConfig.contentOptions.postType == "Post"}
                className="h-4 w-4 border-gray-300 text-primary-600 focus:ring-transparent cursor-pointer"
              />
              <label className="ml-3 block text-sm font-medium leading-6 text-gray-600 cursor-pointer">
                Post
              </label>
            </div>
            <div
              className="flex items-center cursor-pointer"
              onClick={() => selectPostType("Reel")}
            >
              <input
                name="postType"
                type="radio"
                readOnly
                checked={facebookConfig.contentOptions.postType == "Reel"}
                className="h-4 w-4 border-gray-300 text-primary-600 focus:ring-transparent cursor-pointer"
              />
              <label className="ml-3 block text-sm font-medium leading-6 text-gray-600 cursor-pointer">
                Reel
              </label>
            </div>
            <div
              className="flex items-center cursor-pointer"
              onClick={() => selectPostType("Story")}
            >
              <input
                name="postType"
                type="radio"
                readOnly
                checked={facebookConfig.contentOptions.postType == "Story"}
                className="h-4 w-4 border-gray-300 text-primary-600 focus:ring-transparent cursor-pointer"
              />
              <label className="ml-3 block text-sm font-medium leading-6 text-gray-600 cursor-pointer">
                Story
              </label>
            </div>
            <div className="flex-1"></div>
          </div>
        </fieldset>
      </div>
      {facebookConfig.contentOptions.postType != "Story" && (
        <div className="mt-2.5">
          <PrimaryTextBox
            onTextChanged={onTextChanged}
            onFirstCommentChanged={onFirstCommentChanged}
            channel={postInstance.channel}
            textAreaOptions={{
              hideLabel: true,
              maxLength: 5000,
              className: "h-[124px]",
              name: "description",
              label: "Description",
              formHook: form,
              placeholder: "Start writing your post",
            }}
            firstCommentOptions={{
              hideLabel: true,
              rows: 5,
              maxLength: 500,
              name: "firstComment",
              label: "First Comment",
              formHook: form,
              placeholder: "Write your first comment",
            }}
          />
        </div>
      )}
      {facebookConfig.contentOptions.postType == "Story" && (
        <div className="mt-2.5">
          <TextAreaField
            hideLabel={true}
            disabled={true}
            className="h-[160px] resize-none"
            name="dummy"
            label="Description"
            formHook={form}
            placeholder="Facebook stories don't support text captions"
          />
        </div>
      )}
    </>
  );

  const secondaryForm = null;

  return (
    <form>
      {typeof children === "function"
        ? children({ primaryForm, secondaryForm })
        : children}
    </form>
  );
}
