import Post from "../../../../../models/entities/post";
import MetricItem from "./MetricItem";
import { formatMetricCount } from "../../../../../utils/metric-utils";
import MetricsViewMode from "../../models/metrics-view-mode";

export interface PinterestMetricsProps {
  post: Post;
  metricsViewMode: MetricsViewMode;
}

export default function PinterestMetrics({
  metricsViewMode,
  post,
}: PinterestMetricsProps) {
  const metrics = post.metrics.pinterest;

  return (
    <div className="flex flex-wrap gap-4 text-center">
      <MetricItem
        value={formatMetricCount(metrics.impressions)}
        label="Impressions"
      />
      <MetricItem
        value={formatMetricCount(metrics.reactions)}
        label="Reactions"
      />
      <MetricItem
        value={formatMetricCount(metrics.userFollows)}
        label="Follows"
      />
      <MetricItem
        value={formatMetricCount(metrics.comments)}
        label="Comments"
      />

      {metricsViewMode == "expanded" && (
        <>
          <MetricItem
            value={formatMetricCount(metrics.profileVisits)}
            label="Profile Visits"
          />
          <MetricItem value={formatMetricCount(metrics.saves)} label="Saves" />

          <MetricItem
            value={formatMetricCount(metrics.pinClicks)}
            label="Pin Clicks"
          />
          <MetricItem
            value={formatMetricCount(metrics.outboundClicks)}
            label="Outbound Clicks"
          />
        </>
      )}
    </div>
  );
}
