import { useEffect, useMemo, useRef, useState } from "react";
import CalendarHeader from "./components/header/CalendarHeader";
import ViewRange from "./models/view-range";
import dayjs from "dayjs";
import { ViewType } from "./models/view-type";
import postService from "../../../services/api/post-service";
import queryNames from "../../../queries/query-names";
import { OpenComposerParams } from "../../../components/dialogs/CreatePostDialog";
import CalendarMonthView from "./components/views/CalendarMonthView";
import CalendarWeekView from "./components/views/CalendarWeekView";
import todayViewRange from "../../../utils/view-range-utils";
import { useSearchParams } from "react-router-dom";
import QueryParamConstants from "../../../constants/query-params";
import { useBreakpoint } from "../../../hooks/useBreakpoint";
import calendarViewService from "../../../services/application/calendar-view-service";
import PostActions from "../../shared/post-actions/PostActions";
import useCurrentSocialSet from "../../../hooks/useCurrentSocialSet";
import useCurrentChannel from "../../../hooks/useCurrentChannel";
import { useQuery } from "@tanstack/react-query";
import Channel from "../../../models/entities/channel";
import { queryClient } from "../../../queries/query-client";
import Post from "../../../models/entities/post";

export default function SchedulerCalendar() {
  const [searchParams, setSearchParams] = useSearchParams();
  const socialSet = useCurrentSocialSet();
  const channel = useCurrentChannel();
  const { isMd } = useBreakpoint("md");
  const [view, setView] = useState<ViewType>(
    calendarViewService.getCalendarView(isMd)
  );
  const [viewRange, setViewRange] = useState<ViewRange>(
    todayViewRange(socialSet.timezone, view)
  );
  const [selectedChannels, setSelectedChannels] = useState<Channel[]>([]);

  const postComposerRef = useRef(null);
  const postsQueryKey = useMemo(
    () => [
      queryNames.postsByDateRange,
      socialSet?.id,
      viewRange.summary.startDate,
      viewRange.summary.endDate,
    ],
    [socialSet?.id, viewRange.summary.endDate, viewRange.summary.startDate]
  );

  const { isLoading, data: posts = [] } = useQuery({
    queryKey: postsQueryKey,
    queryFn: () =>
      postService.list(
        socialSet.id,
        viewRange.summary.startDate,
        viewRange.summary.endDate,
        viewRange.summary.timezoneOffset
      ),
  });

  const filteredPosts = useMemo(
    () =>
      channel
        ? posts.filter((post) => post.channel.id === channel.id)
        : selectedChannels.length > 0
        ? posts.filter((post) =>
            selectedChannels.some((x) => x.id === post.channel.id)
          )
        : posts,
    [channel, posts, selectedChannels]
  );

  useEffect(() => {
    setSelectedChannels([]);
  }, [channel]);

  useEffect(() => {
    const view = calendarViewService.getCalendarView(isMd);
    setView(view);
  }, [isMd]);

  useEffect(() => {
    const scheduleToChannelId = searchParams.get(
      QueryParamConstants.AutoOpenChannelId
    );

    if (socialSet && scheduleToChannelId) {
      openComposer({
        channelIds: scheduleToChannelId.split(","),
      });

      setSearchParams((prev) => {
        const next = { ...prev };
        delete next[QueryParamConstants.AutoOpenChannelId];
        return next;
      });
    }
  }, [socialSet]);

  // When the view changes, we need to refetch the posts
  useEffect(() => {
    onToday();
  }, [view]);

  const openComposer = (params: OpenComposerParams) => {
    postComposerRef.current?.openDialog(params);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const postsToRefresh = posts.filter(
        (post) =>
          post.status === "Processing" ||
          post.status === "Publishing" ||
          post.status === "ProcessingEnqueued" ||
          post.status === "PublishingEnqueued"
      );

      if (postsToRefresh.length) {
        postService
          .getMultiple(socialSet.id, postsToRefresh.map((x) => x.id).join(","))
          .then((posts) => {
            queryClient.setQueryData(postsQueryKey, (oldData: Post[]) => {
              if (!oldData) {
                return oldData;
              }

              return oldData.map((post) => {
                if (posts.some((x) => x.id === post.id)) {
                  return posts.find((x) => x.id === post.id);
                }

                return post;
              });
            });
          });
      }
    }, 2000);
    return () => {
      clearInterval(interval);
    };
  }, [posts, postsQueryKey, socialSet.id]);

  const onSetView = (view: ViewType) => {
    setView(view);
    calendarViewService.setCalendarView(view);
  };

  const adjustViewRange = (viewRange, direction, viewMode): ViewRange => {
    const increment = direction === "next" ? 1 : -1;
    let newDate;

    if (viewMode === "month") {
      // Adjust month based on the direction

      newDate = dayjs(
        new Date(viewRange.monthly.year, viewRange.monthly.month)
      ).add(increment, "month");

      const startOfMonth = newDate.startOf("month");
      const endOfMonth = newDate.endOf("month");
      const startDate = startOfMonth.format("YYYY-MM-DD");
      const endDate = endOfMonth.format("YYYY-MM-DD");

      return {
        ...viewRange,
        monthly: {
          year: newDate.year(),
          month: newDate.month(),
          startDate: startDate,
          endDate: endDate,
          headerLabel: startOfMonth.format("MMM YYYY"),
        },
        summary: {
          ...viewRange.summary,
          startDate,
          endDate,
        },
      };
    } else if (viewMode === "week") {
      // Adjust week based on the direction
      const currentWeekDate = dayjs()
        .year(viewRange.weekly.year)
        .isoWeek(viewRange.weekly.week);

      newDate = currentWeekDate.add(increment, "week");

      const startOfWeek = newDate.startOf("isoWeek");
      const endOfWeek = newDate.endOf("isoWeek");
      const startDate = startOfWeek.format("YYYY-MM-DD");
      const endDate = endOfWeek.format("YYYY-MM-DD");

      return {
        ...viewRange,
        weekly: {
          year: newDate.year(),
          week: newDate.isoWeek(),
          startDate,
          endDate,
          headerLabel: `${startOfWeek.format("MMM DD")} - ${endOfWeek.format(
            "MMM DD"
          )}, ${startOfWeek.format("YYYY")}`,
        },
        summary: {
          ...viewRange.summary,
          startDate,
          endDate,
        },
      };
    }

    throw new Error(
      `Only 'month' and 'week' views are supported. View ${viewMode} not available.`
    );
  };

  const onNext = () => {
    setViewRange(adjustViewRange(viewRange, "next", view));
  };

  const onPrevious = () => {
    setViewRange(adjustViewRange(viewRange, "previous", view));
  };

  const onToday = () => {
    setViewRange(todayViewRange(socialSet.timezone, view));
  };

  const calendarView = (
    <>
      <PostActions composerRef={postComposerRef}>
        {({
          deletePost,
          isDeleting,
          deletingPost,
          isUpdating,
          moveToDraft,
          moveToScheduled,
          publishNow,
          editPost,
          clonePost,
        }) => (
          <>
            {view == "month" ? (
              <CalendarMonthView
                viewRange={viewRange}
                posts={filteredPosts}
                onDelete={deletePost}
                onEdit={editPost}
                onClone={clonePost}
                onCreate={(scheduledAt) => openComposer({ scheduledAt })}
                onMoveToDraft={moveToDraft}
                onMoveToScheduled={moveToScheduled}
                onPublishNow={publishNow}
                isSubmitting={isUpdating}
                deletingPost={isDeleting && deletingPost}
              />
            ) : view == "week" ? (
              <CalendarWeekView
                viewRange={viewRange}
                posts={filteredPosts}
                onDelete={deletePost}
                onEdit={editPost}
                onClone={clonePost}
                onCreate={(scheduledAt) => openComposer({ scheduledAt })}
                onMoveToDraft={moveToDraft}
                onMoveToScheduled={moveToScheduled}
                onPublishNow={publishNow}
                isSubmitting={isUpdating}
                deletingPost={isDeleting && deletingPost}
              />
            ) : null}
          </>
        )}
      </PostActions>
    </>
  );

  return (
    <>
      <div className="flex flex-col overflow-hidden rounded-md shadow overflow-y-auto h-full lg:h-[calc(100dvh-14rem)] xl:h-[calc(100dvh-8rem)]">
        <CalendarHeader
          currentView={view}
          viewRange={viewRange}
          unfilteredPosts={posts ?? []}
          loading={isLoading}
          selectedChannels={selectedChannels}
          showChannelFilters={!channel}
          onNewPost={(scheduledAt) => openComposer({ scheduledAt })}
          onSetView={onSetView}
          onNext={onNext}
          onPrevious={onPrevious}
          onToday={onToday}
          onChannelsFiltered={setSelectedChannels}
        />
        {calendarView}
      </div>
    </>
  );
}
