import { Field, Description, Switch, Label } from "@headlessui/react";
import clsx from "clsx";
import { ReactNode, useEffect, useState } from "react";

export interface ToggleBoxProps {
  value?: boolean;
  label?: string;
  description?: string;
  disabled?: boolean;
  onChanged?: (value: boolean) => void;
  leading?: ReactNode;
}

export default function ToggleBox({
  value,
  label,
  description,
  disabled,
  leading,
  onChanged,
}: ToggleBoxProps) {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(value);
  }, [value]);

  const onSwitchChanged = (value) => {
    setChecked(value);

    if (onChanged) {
      onChanged(value);
    }
  };

  return (
    <Field as="div" className="flex items-center justify-between w-full">
      {leading}
      <span className="flex flex-grow flex-col">
        <Label
          as="span"
          className="text-sm font-medium leading-6 text-gray-900"
          passive
        >
          {label}
        </Label>
        <Description as="span" className="text-sm text-gray-500">
          {description}
        </Description>
      </span>
      <Switch
        checked={checked}
        onChange={onSwitchChanged}
        disabled={disabled}
        className={clsx(
          checked ? "bg-primary-600" : "bg-gray-200",
          disabled ? "cursor-default opacity-60" : "cursor-pointer",
          "relative inline-flex h-6 w-11 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out outline-none"
        )}
      >
        <span
          className={clsx(
            checked ? "translate-x-5" : "translate-x-0",
            "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
          )}
        />
      </Switch>
    </Field>
  );
}
