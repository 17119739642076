import { Menu, MenuButton, MenuItems } from "@headlessui/react";
import AttachmentSlot from "../../models/attachment-slot";
import clsx from "clsx";
import { ThreadsContentInstance } from "../../../../../models/entities/post";
import { formatBytes } from "../../../../../utils/file-utils";
import MediaItem from "../../../media/components/MediaItem";
import { PhotoIcon } from "@heroicons/react/24/outline";

interface ThreadAttachmentsProps {
  attachmentSlots: AttachmentSlot[];
  threadInstance: ThreadsContentInstance;
  onToggleAttachment: (slot: AttachmentSlot) => void;
}

export default function ThreadAttachments({
  attachmentSlots,
  threadInstance,
  onToggleAttachment,
}: ThreadAttachmentsProps) {
  return (
    <Menu as="div" className="flex relative text-left">
      {() => (
        <>
          <MenuButton className="flex justify-start items-center gap-1">
            {threadInstance.attachmentIds.length > 0 ? (
              <div className="overflow-hidden whitespace-nowrap flex-1 text-sm text-gray-900 text-ellipsis text-left">
                <div className="flex flex-wrap gap-1 opacity-80 rounded-md bg-white p-1 text-sm leading-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 hover:bg-gray-50">
                  {threadInstance.attachmentIds.map((attachmentId) => {
                    const attachment = attachmentSlots.find(
                      (a) => a.id === attachmentId
                    );

                    return (
                      attachment && (
                        <div key={attachment.id}>
                          <img
                            src={attachment.thumbnailPreview?.url ?? ""}
                            className="w-6 h-6 object-cover rounded-sm"
                          />
                        </div>
                      )
                    );
                  })}
                </div>
              </div>
            ) : (
              <>
                {attachmentSlots.length > 0 && (
                  <div
                    data-tooltip-id="tooltip-placeholder"
                    data-tooltip-content="Manage attachments for this thread"
                    data-tooltip-delay-show={350}
                    className="w-7 h-6 transition-all border border-dashed rounded-sm px-1 py-0 text-xs font-medium text-gray-600 flex items-center justify-center cursor-pointer hover:border-gray-300 hover:text-gray-400 border-gray-400"
                  >
                    <PhotoIcon className="w-full h-full " />
                  </div>
                )}
              </>
            )}

            {/* {open ? (
              <ChevronUpIcon className="h-4 w-4 text-gray-400" />
            ) : (
              <ChevronDownIcon className=" h-4 w-4 text-gray-400" />
            )} */}
          </MenuButton>
          <MenuItems
            transition
            anchor="bottom start"
            className="[--anchor-gap:0.25rem] [--anchor-padding:0px] z-50 w-80 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5"
          >
            <ul className="py-1 px-1">
              {attachmentSlots &&
                attachmentSlots.map((slot) => (
                  <MediaItem
                    key={slot.id}
                    isGridView={true}
                    attachmentSlot={slot}
                    onSlotUpdated={() => console.log("updated")}
                    onSlotDeleted={() => console.log("deleted")}
                  >
                    {({ mediaNode, fileTypeNode }) => (
                      <li
                        onClick={() => onToggleAttachment(slot)}
                        className={clsx(
                          "group text-sm flex items-center justify-between py-2 px-2 rounded-md hover:bg-gray-50 cursor-pointer"
                        )}
                      >
                        <div className="flex items-center gap-2 whitespace-nowrap text-sm w-full">
                          <div className="mr-2">
                            <input
                              type="checkbox"
                              readOnly={true}
                              checked={threadInstance.attachmentIds.some(
                                (x) => x === slot.id
                              )}
                              className="h-5 w-5 rounded border-gray-300 text-primary-600 focus:ring-primary-600 focus:ring-transparent cursor-pointer"
                            />
                          </div>
                          <div className="h-11 w-11 flex-shrink-0 rounded-lg overflow-hidden relative">
                            {mediaNode}
                            <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
                              {fileTypeNode}
                            </div>
                          </div>
                          <div className="flex-1 truncate">
                            <div className="text-sm font-medium text-gray-700 truncate">
                              {slot.preview.fileName}
                            </div>
                            <div className="text-sm font-medium text-gray-500">
                              {formatBytes(slot.preview.fileSize)}
                            </div>
                          </div>
                        </div>
                      </li>
                    )}
                  </MediaItem>
                ))}
            </ul>
          </MenuItems>
        </>
      )}
    </Menu>
  );
}
