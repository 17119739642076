import { Outlet } from "react-router-dom";
import GlobalStateProvider from "../../state/global-state/GlobalStateProvider";
import { queryClient } from "../../queries/query-client";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { createIDBPersister } from "../../queries/indexdb-persister";
import { createTheme, ThemeProvider } from "@mui/material/styles";

export default function LoggedInLayout() {
  const persister = createIDBPersister();

  const theme = createTheme({
    palette: {
      primary: {
        main: "#E03058",
      },
    },
  });

  return (
    <>
      <PersistQueryClientProvider
        client={queryClient}
        persistOptions={{ persister, maxAge: 1000 * 60 * 60 * 24 * 1 }} // 1 day
      >
        <GlobalStateProvider>
          <ThemeProvider theme={theme}>
            <Outlet />
          </ThemeProvider>
        </GlobalStateProvider>
      </PersistQueryClientProvider>
    </>
  );
}
