import clsx from "clsx";
// import { useAnimation } from "../../../hooks/useAnimation";
import animation from "../../../assets/animations/viraly-bird-animation.json";
import Lottie from "react-lottie-player";

interface SplashProps {
  message?: string;
}

export default function Splash({ message }: SplashProps) {
  const showLogo = false;

  // const { animation } = useAnimation("/animations/viraly-bird-animation.json");

  return (
    <div className="flex flex-col h-[100dvh]">
      {showLogo ? (
        <img
          className="max-w-40 p-6"
          src="/logo/viraly-logo-rect.png"
          alt="Viraly"
        />
      ) : null}
      <div></div>
      <div className="flex-1 flex flex-col justify-center items-center">
        <div
          className={clsx(
            "flex flex-col items-center",
            showLogo ? "-mt-40" : ""
          )}
        >
          {animation && (
            <Lottie
              loop
              animationData={animation}
              play
              style={{ height: 120 }}
            />
          )}

          <div className="text-sm text-center leading-8 text-gray-600">
            {message ?? "Fluffing our feathers, just a sec :)"}
          </div>
        </div>
      </div>
    </div>
  );
}
