/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import {
  ChangeEvent,
  InputHTMLAttributes,
  useEffect,
  useMemo,
  useState,
} from "react";
import { UseFormReturn } from "react-hook-form";
import Spinner from "../common/Spinner";

export interface ComboBoxItem {
  value: string;
  label: string;
  caption?: string;
}

interface ComboBoxFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
  hideLabel?: boolean;
  horizontalLayout?: boolean;
  disabled?: boolean;
  items: ComboBoxItem[];
  loading?: boolean;
  formReturn: UseFormReturn<any, any, any>;
}

export default function ComboBoxField({
  name,
  label,
  formReturn,
  hideLabel,
  horizontalLayout,
  disabled,
  items,
  loading,
  ...rest
}: ComboBoxFieldProps) {
  const {
    register,
    formState: { errors },
    getValues,
  } = formReturn;

  const { ...restRegister } = register(name);

  const [query, setQuery] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);

  const filteredList = useMemo(
    () =>
      !query
        ? items ?? []
        : items?.filter((item) =>
            `${item.value} ${item.label}`
              .toLowerCase()
              .includes(query.toLowerCase())
          ) ?? [],
    [query, items]
  );

  useEffect(() => {
    const value = getValues(name);
    if (value) {
      const item = items?.find((item) => item.value === value);
      if (item) {
        setSelectedItem(item);
      }
    }
  }, [name, items, getValues]);

  const onValueChanged = (event) => {
    setSelectedItem(event);
    setQuery("");
    formReturn.setValue(name, event?.value);
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    console.log(e);
    onValueChanged(e);
    rest?.onChange?.(e);
  };

  return (
    <>
      <div
        className={clsx(
          horizontalLayout
            ? "flex flex-row justify-start items-center gap-1"
            : "flex flex-col justify-center items-start gap-1"
        )}
      >
        {!hideLabel ? (
          <label
            htmlFor={name}
            className="block text-sm font-medium leading-5 text-gray-700"
          >
            {label}
          </label>
        ) : null}

        <div className={clsx("relative", horizontalLayout ? null : "w-full")}>
          <Combobox
            as="div"
            {...register(name)}
            {...restRegister}
            {...rest}
            onChange={handleOnChange}
            immediate
            defaultValue={null}
            disabled={disabled}
            value={selectedItem}
            virtual={{ options: filteredList }}
          >
            <div className="relative">
              <ComboboxInput
                type="text"
                placeholder={loading ? "Loading data..." : rest.placeholder}
                className={clsx(
                  "block w-full rounded-md border-0 py-1.5 text-gray-600 text-sm leading-5 shadow-sm ring-1 ring-inset ring-gray-200 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-400 read-only:bg-gray-50/80",
                  errors[name] ? "ring-red-700 focus:ring-red-700" : ""
                )}
                onChange={(event) => {
                  setQuery(event.target.value);
                }}
                onBlur={() => setQuery("")}
                displayValue={(item: ComboBoxItem) => item?.value}
              />

              {loading && (
                <div className="absolute inset-y-0 right-0 flex items-center pr-2">
                  <Spinner size="6" />
                </div>
              )}

              {!loading && (
                <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </ComboboxButton>
              )}

              {filteredList.length > 0 && (
                <ComboboxOptions
                  anchor="bottom"
                  transition
                  className="w-[var(--input-width)] [--anchor-gap:var(--spacing-1)] empty:invisible transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0 z-10 rounded-md bg-white py-2 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                >
                  {({ option: item }) => (
                    <ComboboxOption
                      key={item.value}
                      value={item}
                      className="w-full group relative cursor-default select-none py-2 pl-3 pr-6 text-gray-900 data-[focus]:bg-primary-600 data-[focus]:text-white"
                    >
                      <div className="flex ml-6">
                        <span className="truncate group-data-[selected]:font-semibold">
                          {item.value}
                        </span>
                        <span className="ml-auto truncate text-gray-500 group-data-[focus]:text-primary-200">
                          ({item.caption})
                        </span>
                      </div>

                      <span className="absolute inset-y-0 left-0 hidden items-center pl-1.5 text-indigo-600 group-data-[selected]:flex group-data-[focus]:text-white">
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    </ComboboxOption>
                  )}
                </ComboboxOptions>
              )}
            </div>
          </Combobox>
          <p role="alert" className="text-sm text-red-700">
            {errors[name]?.message?.toString()}
          </p>
        </div>
      </div>
    </>
  );
}
