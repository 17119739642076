export type FeatureCode =
  | "f:instagram_schedule"
  | "f:facebook_schedule"
  | "f:twitter_schedule"
  | "f:youtube_schedule"
  | "f:tiktok_schedule"
  | "f:pinterest_schedule"
  | "f:threads_schedule"
  | "f:linkedin_schedule"
  | "f:social_set_count"
  | "f:channels_count"
  | "f:posts_count"
  | "f:post_analytics"
  | "f:channel_analytics"
  | "f:export_analytics"
  | "f:draft_posts"
  | "f:hashtag_lists"
  | "f:media_library"
  | "f:ai_caption_generator"
  | "f:photo_editor"
  | "f:video_editor"
  | "f:first_comment"
  | "f:content_queues"
  | "f:team_access"
  | "f:team_permissions"
  | "f:team_realtime_collaboration"
  | "f:email_support"
  | "f:priority_support"
  | "f:zoom_support"
  | "f:dedicated_account_manager";

export type SectionCode =
  | "s:post-scheduling"
  | "s:analytics-reporting"
  | "s:content-management"
  | "s:team-collaboration"
  | "s:support";

export interface FeatureSection {
  id: SectionCode;
  name: string;
  features: Feature[];
}

export interface Feature {
  id: FeatureCode;
  name: string;
  image?: string;
}

export interface PlanFrequency {
  value: "monthly" | "yearly";
  label: string;
  priceSuffix: string;
  stripeInterval: "month" | "year";
}

export interface TierFeature {
  feature: FeatureCode;
  value: boolean | string | number;
}

export const featureSections: FeatureSection[] = [
  {
    id: "s:post-scheduling",
    name: "Post Scheduling",
    features: [
      {
        id: "f:instagram_schedule",
        name: "Instagram Scheduling",
        image: "/images/social-platforms/instagram.svg",
      },
      {
        id: "f:facebook_schedule",
        name: "Facebook Scheduling",
        image: "/images/social-platforms/facebook.svg",
      },
      {
        id: "f:twitter_schedule",
        name: "X Scheduling",
        image: "/images/social-platforms/x.svg",
      },
      {
        id: "f:youtube_schedule",
        name: "YouTube Scheduling",
        image: "/images/social-platforms/youtube.svg",
      },
      {
        id: "f:tiktok_schedule",
        name: "TikTok Scheduling",
        image: "/images/social-platforms/tiktok.svg",
      },
      {
        id: "f:pinterest_schedule",
        name: "Pinterest Scheduling",
        image: "/images/social-platforms/pinterest.svg",
      },
      {
        id: "f:threads_schedule",
        name: "Threads Scheduling",
        image: "/images/social-platforms/threads.svg",
      },
      {
        id: "f:linkedin_schedule",
        name: "LinkedIn Scheduling",
        image: "/images/social-platforms/linkedin.svg",
      },
      { id: "f:social_set_count", name: "Social sets" },
      { id: "f:channels_count", name: "Channels" },
      { id: "f:posts_count", name: "Posts per month" },
    ],
  },
  {
    id: "s:analytics-reporting",
    name: "Analytics & Reporting",
    features: [
      { id: "f:post_analytics", name: "Post analytics" },
      { id: "f:channel_analytics", name: "Channel analytics" },
      { id: "f:export_analytics", name: "Export insights with custom options" },
    ],
  },
  {
    id: "s:content-management",
    name: "Content Management",
    features: [
      { id: "f:draft_posts", name: "Draft posts" },
      { id: "f:hashtag_lists", name: "Hashtag lists" },
      { id: "f:media_library", name: "Media library" },
      { id: "f:ai_caption_generator", name: "AI caption generator" },
      { id: "f:photo_editor", name: "Rich photo editor" },
      { id: "f:video_editor", name: "Rich video editor" },
      {
        id: "f:first_comment",
        name: "Add first comment for supported platforms",
      },
      { id: "f:content_queues", name: "Content queues" },
    ],
  },
  {
    id: "s:team-collaboration",
    name: "Team Collaboration",
    features: [
      { id: "f:team_access", name: "Team access" },
      { id: "f:team_permissions", name: "Permissions & social set assignment" },
      { id: "f:team_realtime_collaboration", name: "Real-time collaboration" },
    ],
  },
  {
    id: "s:support",
    name: "Support",
    features: [
      { id: "f:email_support", name: "Email support" },
      { id: "f:priority_support", name: "Priority support" },
      { id: "f:zoom_support", name: "Zoom support" },
      { id: "f:dedicated_account_manager", name: "Dedicated account manager" },
    ],
  },
];

export const frequencies: PlanFrequency[] = [
  {
    value: "monthly",
    label: "Monthly",
    priceSuffix: "per month",
    stripeInterval: "month",
  },
  {
    value: "yearly",
    label: "Yearly",
    priceSuffix: "per year",
    stripeInterval: "year",
  },
];

export const tierFeatures: {
  high: TierFeature[];
  medium: TierFeature[];
  low: TierFeature[];
} = {
  low: [
    { feature: "f:instagram_schedule", value: true },
    { feature: "f:facebook_schedule", value: true },
    { feature: "f:twitter_schedule", value: true },
    { feature: "f:youtube_schedule", value: true },
    { feature: "f:tiktok_schedule", value: true },
    { feature: "f:pinterest_schedule", value: true },
    { feature: "f:threads_schedule", value: true },
    { feature: "f:linkedin_schedule", value: true },
    { feature: "f:social_set_count", value: "Unlimited" },
    { feature: "f:channels_count", value: 5 },
    { feature: "f:posts_count", value: 200 },
    { feature: "f:post_analytics", value: true },
    { feature: "f:channel_analytics", value: true },
    { feature: "f:export_analytics", value: true },
    { feature: "f:draft_posts", value: true },
    { feature: "f:hashtag_lists", value: true },
    { feature: "f:media_library", value: true },
    { feature: "f:ai_caption_generator", value: true },
    { feature: "f:photo_editor", value: true },
    { feature: "f:video_editor", value: true },
    { feature: "f:first_comment", value: true },
    { feature: "f:content_queues", value: true },
    { feature: "f:team_access", value: false },
    { feature: "f:team_permissions", value: false },
    { feature: "f:team_realtime_collaboration", value: false },
    { feature: "f:email_support", value: true },
    { feature: "f:priority_support", value: false },
    { feature: "f:zoom_support", value: false },
    { feature: "f:dedicated_account_manager", value: false },
  ],
  medium: [
    { feature: "f:instagram_schedule", value: true },
    { feature: "f:facebook_schedule", value: true },
    { feature: "f:twitter_schedule", value: true },
    { feature: "f:youtube_schedule", value: true },
    { feature: "f:tiktok_schedule", value: true },
    { feature: "f:pinterest_schedule", value: true },
    { feature: "f:threads_schedule", value: true },
    { feature: "f:linkedin_schedule", value: true },
    { feature: "f:social_set_count", value: "Unlimited" },
    { feature: "f:channels_count", value: 15 },
    { feature: "f:posts_count", value: 500 },
    { feature: "f:post_analytics", value: true },
    { feature: "f:channel_analytics", value: true },
    { feature: "f:export_analytics", value: true },
    { feature: "f:draft_posts", value: true },
    { feature: "f:hashtag_lists", value: true },
    { feature: "f:media_library", value: true },
    { feature: "f:ai_caption_generator", value: true },
    { feature: "f:photo_editor", value: true },
    { feature: "f:video_editor", value: true },
    { feature: "f:first_comment", value: true },
    { feature: "f:content_queues", value: true },
    { feature: "f:team_access", value: true },
    { feature: "f:team_permissions", value: true },
    { feature: "f:team_realtime_collaboration", value: true },
    { feature: "f:email_support", value: true },
    { feature: "f:priority_support", value: true },
    { feature: "f:zoom_support", value: false },
    { feature: "f:dedicated_account_manager", value: false },
  ],
  high: [
    { feature: "f:instagram_schedule", value: true },
    { feature: "f:facebook_schedule", value: true },
    { feature: "f:twitter_schedule", value: true },
    { feature: "f:youtube_schedule", value: true },
    { feature: "f:tiktok_schedule", value: true },
    { feature: "f:pinterest_schedule", value: true },
    { feature: "f:threads_schedule", value: true },
    { feature: "f:linkedin_schedule", value: true },
    { feature: "f:social_set_count", value: "Unlimited" },
    { feature: "f:channels_count", value: 50 },
    { feature: "f:posts_count", value: "Unlimited" },
    { feature: "f:post_analytics", value: true },
    { feature: "f:channel_analytics", value: true },
    { feature: "f:export_analytics", value: true },
    { feature: "f:draft_posts", value: true },
    { feature: "f:hashtag_lists", value: true },
    { feature: "f:media_library", value: true },
    { feature: "f:ai_caption_generator", value: true },
    { feature: "f:photo_editor", value: true },
    { feature: "f:video_editor", value: true },
    { feature: "f:first_comment", value: true },
    { feature: "f:content_queues", value: true },
    { feature: "f:team_access", value: true },
    { feature: "f:team_permissions", value: true },
    { feature: "f:team_realtime_collaboration", value: true },
    { feature: "f:email_support", value: true },
    { feature: "f:priority_support", value: true },
    { feature: "f:zoom_support", value: true },
    { feature: "f:dedicated_account_manager", value: true },
  ],
};
