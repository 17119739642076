import { ReactNode, useRef, useState } from "react";
import ChannelConfig from "../models/channel-config";
import errorReporter from "../../../../utils/error-reporter";
import channelProviderService from "../../../../services/application/channel-provider-service";
import OAuthConstants from "../../../../constants/oauth";
import ChannelInfoDialog from "../../../channels/components/channel-info-dialog/ChannelInfoDialog";
import FacebookInfoDialog from "../../../channels/components/channel-info-dialog/FacebookInfoDialog";
import InstagramInfoDialog from "../../../channels/components/channel-info-dialog/InstagramInfoDialog";
import TwitterInfoDialog from "../../../channels/components/channel-info-dialog/TwitterInfoDialog";
import PinterestInfoDialog from "../../../channels/components/channel-info-dialog/PinterestInfoDialog";
import YouTubeInfoDialog from "../../../channels/components/channel-info-dialog/YouTubeInfoDialog";
import TikTokInfoDialog from "../../../channels/components/channel-info-dialog/TikTokInfoDialog";
import ThreadsInfoDialog from "../../../channels/components/channel-info-dialog/ThreadsInfoDialog";
import LinkedInInfoDialog from "../../../channels/components/channel-info-dialog/LinkedInInfoDialog";

export interface RedirectChannelConnectorProp {
  channelConfig: ChannelConfig;
  channelId?: string;
  externalChannelId?: string;
  socialSetId: string;
  showInfoPopup: boolean;
  children?: ReactNode | ((onClick) => ReactNode);
}

export default function RedirectChannelConnector({
  channelConfig,
  channelId,
  externalChannelId,
  socialSetId,
  children,
  showInfoPopup,
}: RedirectChannelConnectorProp) {
  const [isLoading, setIsLoading] = useState(false);
  const infoDialogRef = useRef(null);
  const [url, setUrl] = useState(null);

  const chooseChannel = async (): Promise<void> => {
    if (showInfoPopup && infoDialogRef.current?.openDialog) {
      infoDialogRef.current.openDialog();
      await fetchRedirectUrl();
      return;
    }

    initiateChannelConnection();
  };

  const fetchRedirectUrl = async () => {
    if (url) {
      return url;
    }
    const state = getState();

    const result = await channelProviderService.getAuthorizationUrl(
      channelConfig.type,
      state
    );

    setUrl(result);
    return result;
  };

  const initiateChannelConnection = async () => {
    try {
      setIsLoading(true);
      const url = await fetchRedirectUrl();

      window.location.href = url;
    } catch (error) {
      console.error(`Error fetching ${channelConfig.type} channels`, error);
      errorReporter.alertErrors(error);
      setIsLoading(false);
    }
  };

  const getState = () => {
    return channelId
      ? JSON.stringify({
          action: OAuthConstants.RefreshChannel,
          socialSetId: socialSetId,
          channelId: channelId,
          externalChannelId: externalChannelId,
        })
      : JSON.stringify({
          action: OAuthConstants.ConnectChannel,
          socialSetId: socialSetId,
        });
  };

  const onConfirm = () => {
    initiateChannelConnection();
  };

  function ChannelInfoWrapper() {
    if (channelConfig.type == "Facebook") {
      return (
        <ChannelInfoDialog
          title="Connect your Facebook pages"
          ref={infoDialogRef}
          onConfirm={onConfirm}
        >
          <FacebookInfoDialog />
        </ChannelInfoDialog>
      );
    } else if (channelConfig.type == "Instagram") {
      return (
        <ChannelInfoDialog
          title="Authenticate with Facebook"
          confirmLabel="Authenticate with Facebook"
          ref={infoDialogRef}
          onConfirm={onConfirm}
        >
          <InstagramInfoDialog />
        </ChannelInfoDialog>
      );
    } else if (channelConfig.type == "Twitter") {
      return (
        <ChannelInfoDialog
          title="Connect your X Profile"
          confirmLabel="Authenticate with X"
          ref={infoDialogRef}
          onConfirm={onConfirm}
        >
          <TwitterInfoDialog />
        </ChannelInfoDialog>
      );
    } else if (channelConfig.type == "Pinterest") {
      return (
        <ChannelInfoDialog
          title="Connect your Pinterest account"
          confirmLabel="Authenticate with Pinterest"
          ref={infoDialogRef}
          onConfirm={onConfirm}
        >
          <PinterestInfoDialog />
        </ChannelInfoDialog>
      );
    } else if (channelConfig.type == "YouTube") {
      return (
        <ChannelInfoDialog
          title="Connect your YouTube channel"
          confirmLabel="Authenticate with Google"
          ref={infoDialogRef}
          onConfirm={onConfirm}
        >
          <YouTubeInfoDialog />
        </ChannelInfoDialog>
      );
    } else if (channelConfig.type == "TikTok") {
      return (
        <ChannelInfoDialog
          title="Connect your TikTok account"
          confirmLabel="Authenticate with TikTok"
          ref={infoDialogRef}
          onConfirm={onConfirm}
        >
          <TikTokInfoDialog />
        </ChannelInfoDialog>
      );
    } else if (channelConfig.type == "Threads") {
      return (
        <ChannelInfoDialog
          title="Connect your Threads account"
          confirmLabel="Authenticate with Threads"
          ref={infoDialogRef}
          onConfirm={onConfirm}
        >
          <ThreadsInfoDialog />
        </ChannelInfoDialog>
      );
    } else if (channelConfig.type == "LinkedIn") {
      return (
        <ChannelInfoDialog
          title="Connect your LinkedIn profile or Page"
          confirmLabel="Authenticate with LinkedIn"
          ref={infoDialogRef}
          onConfirm={onConfirm}
        >
          <LinkedInInfoDialog />
        </ChannelInfoDialog>
      );
    }

    return null;
  }

  return (
    <>
      {typeof children === "function"
        ? children({ onClick: chooseChannel, isLoading })
        : children}

      {ChannelInfoWrapper()}
    </>
  );
}
