import { useEffect } from "react";
import AttachmentSlot from "../../models/attachment-slot";
import { PostInstance } from "../../models/post-instance";

interface TwitterSyncerProps {
  attachments: AttachmentSlot[];
  postInstance: PostInstance;
  onPostInstanceChanged: (postInstance: PostInstance, touched: boolean) => void;
}

export default function TwitterSyncer({
  attachments,
  postInstance,
  onPostInstanceChanged,
}: TwitterSyncerProps) {
  const twitterConfig = postInstance.postConfig.twitter;

  useEffect(() => {
    const setSystemPostType = () => {
      let systemPostType = twitterConfig.contentOptions.systemPostType;

      const initialSystemPostType = systemPostType;

      if (attachments.length == 0) {
        systemPostType = "TextPost";
      } else if (attachments.length == 1) {
        systemPostType = attachments[0].isPhoto ? "ImagePost" : "VideoPost";
      } else if (attachments.length > 1) {
        systemPostType = "Carousel";
      }

      if (systemPostType != initialSystemPostType) {
        const updatedPostInstance: PostInstance = {
          ...postInstance,
          postConfig: {
            ...postInstance.postConfig,
            twitter: {
              contentOptions: {
                ...twitterConfig.contentOptions,
                systemPostType: systemPostType,
              },
            },
          },
        };

        onPostInstanceChanged(updatedPostInstance, false);
      }
    };

    setSystemPostType();
  }, [twitterConfig.contentOptions, attachments, postInstance]);

  return null;
}
