/* eslint-disable @typescript-eslint/no-explicit-any */
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";

export interface ColorPickerFieldProps {
  name: string;
  label: string;
  colors: string[];
  form: UseFormReturn<any, any, any>;
}

export default function ColorPickerField({
  name,
  label,
  colors,
  form,
}: ColorPickerFieldProps) {
  const [currentValue, setCurrentValue] = useState<string>();
  const {
    register,
    formState: { errors },
    getValues,
  } = form;

  const select = (colorHex: string) => {
    form.setValue(name, colorHex);
    setCurrentValue(colorHex);
  };

  useEffect(() => {
    setCurrentValue(getValues()[name]);
  }, [getValues, name]);

  return (
    <>
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-5 text-gray-700"
      >
        {label}
      </label>
      <div className="mt-2">
        <input {...register(name)} type="hidden" />
        <div className="flex flex-wrap">
          {colors.map((color) => (
            <span
              key={color}
              className="hover:opacity-80 cursor-pointer rounded-full text-xs font-medium text-gray-600 w-7 h-7 mr-2 mb-2 flex items-center justify-center"
              onClick={() => select(color)}
              style={{ backgroundColor: color }}
            >
              {currentValue == color ? (
                <CheckCircleIcon className="w-8 h-8 text-white font-bold" />
              ) : null}
            </span>
          ))}
        </div>
        <p role="alert" className="text-sm text-red-700">
          {errors[name]?.message?.toString()}
        </p>
      </div>
    </>
  );
}
