import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import InputField from "../../../components/form/InputField";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Button from "../../../components/common/Button";
import { useState } from "react";
import { resetPassword, ResetPasswordOutput } from "aws-amplify/auth";
import { XCircleIcon } from "@heroicons/react/20/solid";
import ValidationMessages from "../../../data/validation-messages";

const schema = yup
  .object({
    email: yup
      .string()
      .email(ValidationMessages.EmailFormat)
      .required(ValidationMessages.EmailRequired),
  })
  .required();

interface FormInput {
  email?: string;
}

export default function ForgotPassword() {
  const navigate = useNavigate();

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState("");

  const form = useForm<FormInput>({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const submitForm = () => {
    form.handleSubmit(onSubmit)();
  };

  const onSubmit = async (input: FormInput) => {
    setError("");

    try {
      setSubmitting(true);

      const output = await resetPassword({
        username: input.email,
      });

      handleResetPasswordNextSteps(output);
    } catch (e) {
      setError(e.message);
    } finally {
      setSubmitting(false);
    }
  };

  const handleResetPasswordNextSteps = (output: ResetPasswordOutput) => {
    console.log(output);
    const { nextStep } = output;

    switch (nextStep.resetPasswordStep) {
      case "CONFIRM_RESET_PASSWORD_WITH_CODE":
        navigate(`/reset-password?email=${form.getValues().email}`);
        break;

      case "DONE":
        navigate("/scheduler");
        console.log("Successfully reset password.");
        break;
    }
  };

  return (
    <>
      <div className="text-center">
        <h2 className="mt-8 text-2xl/9 font-bold tracking-tight text-gray-900">
          Reset your password
        </h2>
        <p className="mt-2 text-sm text-gray-500">
          Enter the email address associated with your account and we'll send
          you a 6-digit OTP code which you can use to reset your password.
        </p>
      </div>

      <div className="mt-10">
        <div>
          {error && (
            <div className="mb-6 rounded-md bg-red-50 p-4">
              <div className="flex">
                <div className="shrink-0">
                  <XCircleIcon
                    aria-hidden="true"
                    className="size-5 text-red-400"
                  />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">{error}</h3>
                </div>
              </div>
            </div>
          )}

          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <div>
              <InputField label="Email" name="email" formReturn={form} />
            </div>

            <div>
              <Button
                onClick={submitForm}
                text="Continue"
                color="brand"
                variant="solid"
                loading={submitting}
                type="submit"
                className="w-full"
              />
            </div>
          </form>
        </div>

        <p className="mt-4 text-center">
          <Link
            to="/login"
            className="text-sm/6 text-gray-600 hover:text-gray-900 transition-all cursor-pointer"
          >
            ← Return to sign in
          </Link>
        </p>
      </div>
    </>
  );
}
