import { useEffect } from "react";
import AttachmentSlot from "../../models/attachment-slot";
import { PostInstance } from "../../models/post-instance";

interface LinkedInSyncerProps {
  attachments: AttachmentSlot[];
  postInstance: PostInstance;
  onPostInstanceChanged: (postInstance: PostInstance, touched: boolean) => void;
}

export default function LinkedInSyncer({
  attachments,
  postInstance,
  onPostInstanceChanged,
}: LinkedInSyncerProps) {
  const linkedInConfig = postInstance.postConfig.linkedIn;

  useEffect(() => {
    const setSystemPostType = () => {
      let systemPostType = linkedInConfig.contentOptions.systemPostType;

      const initialSystemPostType = systemPostType;

      if (attachments.length == 1) {
        systemPostType = attachments[0].isPhoto
          ? "PhotoPost"
          : attachments[0].isVideo
          ? "VideoPost"
          : "DocumentPost";
      } else if (attachments.length > 1) {
        systemPostType = "MultiPhotoPost";
      } else {
        systemPostType = "TextPost";
      }

      if (systemPostType != initialSystemPostType) {
        const updatedPostInstance: PostInstance = {
          ...postInstance,
          postConfig: {
            ...postInstance.postConfig,
            linkedIn: {
              ...postInstance.postConfig.linkedIn,
              contentOptions: {
                ...linkedInConfig.contentOptions,
                systemPostType: systemPostType,
              },
            },
          },
        };

        onPostInstanceChanged(updatedPostInstance, false);
      }
    };

    setSystemPostType();
  }, [linkedInConfig.contentOptions, postInstance, attachments]);

  return null;
}
