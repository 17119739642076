import Button from "../common/Button";
import animation from "../../assets/animations/astronaut2.json";
import Lottie from "react-lottie-player";

export interface InnerErrorProps {
  title?: string;
  message?: string;
  reset?: () => void;
}

export default function InnerError({
  title = "Oops! Something went wrong!",
  message = "An unexpected error has occurred. Please try again later or contact support if the issue persists.",
  reset,
}: InnerErrorProps) {
  return (
    <div className="flex flex-col bg-white rounded-lg shadow p-8">
      <div className="flex-1 flex flex-col justify-center items-center">
        <div className="flex flex-col items-center relative overflow-hidden">
          {animation && (
            <Lottie
              loop
              animationData={animation}
              play
              style={{ height: 250 }}
            />
          )}
        </div>

        <div className="flex flex-col items-center gap-2 text-center">
          <div className="text-xl font-bold text-gray-900">{title}</div>
          <div className="text-lg text-gray-600">{message}</div>

          {reset && (
            <Button
              className="mt-8"
              variant="solid"
              text="Reload Page"
              onClick={reset}
            />
          )}
        </div>
      </div>
    </div>
  );
}
