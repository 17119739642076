import { useState, useMemo, useEffect } from "react";
import ToggleBox from "../../../../../components/common/ToggleBox";
import SocialSet from "../../../../../models/entities/social-set";
import { UseFormReturn } from "react-hook-form";
import UserUpdateFormInput from "../../../../../models/form-inputs/user-update-input";
import useSocialSets from "../../../../../hooks/useSocialSets";

interface SocialSetsSectionProps {
  form: UseFormReturn<UserUpdateFormInput, void, UserUpdateFormInput>;
  defaultValue?: string[];
}

export default function SocialSetsSection({
  form,
  defaultValue,
}: SocialSetsSectionProps) {
  const [allSocialSets, setAllSocialSets] = useState(defaultValue === null);
  const socialSets = useSocialSets();
  const [selectedSocialSets, setSelectedSocialSets] =
    useState<string[]>(defaultValue);

  const socialSetsMap = useMemo(
    () =>
      socialSets.reduce((accumulator, current: SocialSet) => {
        accumulator[current.id] =
          selectedSocialSets == null
            ? true
            : selectedSocialSets.includes(current.id);
        return accumulator;
      }, {} as Record<string, boolean>),
    [socialSets, selectedSocialSets]
  );

  useEffect(() => {
    form.setValue("socialSetIds", selectedSocialSets);
  }, [form, selectedSocialSets]);

  const onAllSocialSetsChanged = (value: boolean) => {
    setAllSocialSets(value);
    setSelectedSocialSets(value ? null : []);
  };

  return (
    <ul
      role="list"
      className="divide-y divide-gray-100 border border-gray-100 rounded-md mt-6"
    >
      <li className="flex items-center justify-between py-4 px-8 text-sm leading-6">
        <ToggleBox
          value={allSocialSets}
          onChanged={onAllSocialSetsChanged}
          label="All"
          description="Grant access to all current and future social sets."
        />
      </li>
      {socialSets.map((socialSet) => (
        <li
          key={socialSet.id}
          className="flex items-center justify-between text-sm leading-6 px-8"
        >
          <ToggleBox
            label={socialSet.name}
            description={`${socialSet.channels.length} channels`}
            disabled={allSocialSets}
            value={socialSetsMap[socialSet.id]}
            onChanged={(value) => {
              setSelectedSocialSets((prev) => {
                if (value) {
                  return [...prev, socialSet.id];
                }

                return prev.filter((x) => x !== socialSet.id);
              });
            }}
            leading={
              <div className="p-3 pl-0">
                <div
                  style={{ backgroundColor: socialSet.colorHex }}
                  className="flex flex-shrink-0 items-center justify-center text-sm font-medium text-white w-11 h-11 rounded-full mr-4"
                >
                  {socialSet.code}
                </div>
              </div>
            }
          />
        </li>
      ))}
    </ul>
  );
}
