import Uploader from "./Uploader";
import AttachmentSlot from "../../create-post/models/attachment-slot";
import animation from "../../../../assets/animations/empty-box.json";
import Lottie from "react-lottie-player";

export interface EmptyMediaLibraryProps {
  onAttached: (slots: AttachmentSlot[]) => void;
}

export default function EmptyMediaLibrary({
  onAttached,
}: EmptyMediaLibraryProps) {
  return (
    <div className="h-full flex flex-col items-center justify-center gap-3 text-center">
      <div>
        <Lottie loop animationData={animation} play style={{ height: 200 }} />
      </div>
      <div className="text-gray-800 text-md font-medium">
        Whoa, your media library is empty!
      </div>

      <div className="text-gray-500 text-sm max-w-lg">
        Time to change that! Start adding your awesome content now so it's
        always ready when you need it for your next big post.
      </div>

      <div className="mt-6">
        <Uploader onAttached={onAttached} />
      </div>
    </div>
  );
}
