import { useEffect } from "react";
import AttachmentSlot from "../../models/attachment-slot";
import { PostInstance } from "../../models/post-instance";

interface YouTubeSyncerProps {
  attachments: AttachmentSlot[];
  postInstance: PostInstance;
  onPostInstanceChanged: (postInstance: PostInstance, touched: boolean) => void;
}

export default function YouTubeSyncer({
  postInstance,
  onPostInstanceChanged,
}: YouTubeSyncerProps) {
  const youTubeConfig = postInstance.postConfig.youTube;

  useEffect(() => {
    const setSystemPostType = () => {
      const initialSystemPostType = youTubeConfig.contentOptions.systemPostType;

      if (initialSystemPostType != "ShortVideo") {
        const updatedPostInstance: PostInstance = {
          ...postInstance,
          postConfig: {
            ...postInstance.postConfig,
            youTube: {
              ...postInstance.postConfig.youTube,
              contentOptions: {
                ...youTubeConfig.contentOptions,
                systemPostType: "ShortVideo",
                postType: "ShortVideo",
              },
            },
          },
        };

        onPostInstanceChanged(updatedPostInstance, false);
      }
    };

    setSystemPostType();
  }, [youTubeConfig.contentOptions, onPostInstanceChanged, postInstance]);

  return null;
}
