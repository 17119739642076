export function convertUrlsToLinks(text: string, styles?: string): string {
  if (!text) return text;

  try {
    /*
      Explanation:
      - (^) or (\s) - capture the start of string or any whitespace into group 1
      - (https?:\/\/... | (?:[a-zA-Z0-9-]+\.)+...) - capture the URL into group 2
        so we can separately handle the leading space (or start-of-string) vs. the URL itself.
    */
    const urlRegex =
      /(^|\s)(https?:\/\/[^\s;:,!"'()]+|(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}[^\s;:,!"'()]*)/gi;

    const result = text.replace(urlRegex, (_, before, url) => {
      // Add https:// if the URL doesn't already have http:// or https://
      const fullUrl =
        url.startsWith("http://") || url.startsWith("https://")
          ? url
          : `https://${url}`;

      // Reconstruct: (leading whitespace or start-of-string) + <a> tag
      return `${before}<a style="${styles}" href="${fullUrl}" target="_blank" rel="nofollow">${url}</a>`;
    });

    return result;
  } catch (e) {
    console.error("Error converting URLs to links", e);
    return text;
  }
}

export function padNumber(num: number): string {
  return num < 10 ? "0" + num : num.toString();
}
