import Channel from "../../models/entities/channel";

class ScopeAuthorizerService {
  authorizeFirstComment(channel?: Channel): boolean {
    if (channel == null) {
      return false;
    }

    if (channel.status !== "Active") {
      return false;
    }

    switch (channel.type) {
      case "Facebook":
        return ["pages_manage_engagement"].every((scope) =>
          channel.scopes.includes(scope)
        );
      case "Instagram":
        return channel.scopes.includes("instagram_manage_comments");
      case "Threads":
        return channel.scopes.includes("threads_manage_replies");
      case "LinkedIn":
        return ["w_organization_social_feed", "w_member_social_feed"].every(
          (scope) => channel.scopes.includes(scope)
        );
      case "Pinterest":
        return true; // Feature not supported for Pinterest
      case "Twitter":
        return true;
      case "YouTube":
        return channel.scopes.includes(
          "https://www.googleapis.com/auth/youtube.force-ssl"
        );
      case "TikTok":
        return true; // Feature not supported for TikTok

      default:
        return false;
    }
  }

  authorizeAnalytics(channel: Channel): boolean {
    if (channel.status !== "Active") {
      return false;
    }

    switch (channel.type) {
      case "Facebook":
        return ["pages_manage_posts", "read_insights"].every((scope) =>
          channel.scopes.includes(scope)
        );
      case "Instagram":
        return channel.scopes.includes("instagram_manage_insights");
      case "Threads":
        return channel.scopes.includes("threads_manage_insights");
      case "LinkedIn":
        return ["rw_organization_admin", "r_organization_social"].every(
          (scope) => channel.scopes.includes(scope)
        );
      case "Pinterest":
        return ["pins:read", "user_accounts:read"].every((scope) =>
          channel.scopes.includes(scope)
        );
      case "Twitter":
        return true;
      case "YouTube":
        return channel.scopes.includes(
          "https://www.googleapis.com/auth/youtube.readonly"
        );
      case "TikTok":
        return ["user.info.stats", "video.list"].every((scope) =>
          channel.scopes.includes(scope)
        );

      default:
        return false;
    }
  }
}

export default new ScopeAuthorizerService();
