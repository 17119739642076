import Post from "../../../../../models/entities/post";
import MetricItem from "./MetricItem";
import { formatMetricCount } from "../../../../../utils/metric-utils";
import MetricsViewMode from "../../models/metrics-view-mode";

export interface LinkedInMetricsProps {
  post: Post;
  metricsViewMode: MetricsViewMode;
}

export default function LinkedInMetrics({ post }: LinkedInMetricsProps) {
  const metrics = post.metrics.linkedIn;

  return (
    <div className="flex flex-wrap gap-4 text-center">
      <MetricItem
        value={formatMetricCount(metrics.impressions)}
        label="Impressions"
      />
      <MetricItem value={formatMetricCount(metrics.reach)} label="Reach" />
      <MetricItem
        value={formatMetricCount(metrics.engagement)}
        label="Engagement"
      />
      <MetricItem value={formatMetricCount(metrics.clicks)} label="Clicks" />
      <MetricItem value={formatMetricCount(metrics.likes)} label="Likes" />
      <MetricItem
        value={formatMetricCount(metrics.comments)}
        label="Comments"
      />
      <MetricItem value={formatMetricCount(metrics.shares)} label="Shares" />
    </div>
  );
}
