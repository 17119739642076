import Lottie from "react-lottie-player";
import animation from "../../assets/animations/feathers.json";

export default function GenericError() {
  const showLogo = false;

  return (
    <div className="flex flex-col h-[100dvh]">
      {showLogo ? (
        <img
          className="max-w-40 p-6"
          src="/logo/viraly-logo-rect.png"
          alt="Viraly"
        />
      ) : null}
      <div></div>
      <div className="flex-1 flex flex-col justify-center items-center">
        <div className="flex flex-col items-center relative overflow-hidden">
          {animation && (
            <Lottie
              loop
              animationData={animation}
              play
              style={{ height: 250 }}
            />
          )}

          <img
            className="max-w-40 p-6 absolute top-[70px]"
            src="/logo/viraly-logo-square-dense.png"
            alt="Viraly"
          />
        </div>

        <div className="text-sm text-center leading-8 text-gray-600">
          <span className="font-semibold">
            Alert! We've ruffled some feathers.
          </span>
          <br />
          Our human pals are smoothing things out.
          <br />
          <br />
          In the meantime, you can{" "}
          <a
            href="/scheduler"
            className="text-primary-600 hover:text-primary-700"
          >
            fly back home
          </a>
        </div>
      </div>
    </div>
  );
}
