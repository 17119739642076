import { Fragment } from "react/jsx-runtime";
import { formatBytes } from "../../../../utils/file-utils";
import MediaItem from "./MediaItem";
import AttachmentSlot from "../../create-post/models/attachment-slot";
import clsx from "clsx";

interface ListViewProps {
  slotsByDate: Record<string, AttachmentSlot[]>;
  selectedAttachments: AttachmentSlot[];
  isSelectionMode: boolean;
  onSlotUpdated: (slot: AttachmentSlot) => void;
  onSlotDeleted: (slot: AttachmentSlot) => void;
  onViewAttachment: (attachmentSlot: AttachmentSlot) => void;
}

export default function ListView({
  slotsByDate,
  selectedAttachments,
  isSelectionMode,
  onSlotUpdated,
  onSlotDeleted,
  onViewAttachment,
}: ListViewProps) {
  return (
    <table className="min-w-full">
      <tbody className="bg-white">
        {Object.keys(slotsByDate).map((groupName) => {
          return (
            <Fragment key={groupName}>
              <tr className="group border-b">
                <th
                  scope="colgroup"
                  colSpan={2}
                  className="flex items-center gap-2 pt-4  mb-2 pr-4 group-first:pt-0"
                >
                  <span className="text-md font-semibold text-gray-900 ">
                    {groupName}
                  </span>
                </th>
              </tr>
              {slotsByDate[groupName].map((slot, slotIndex) => (
                <MediaItem
                  key={slot.id}
                  isGridView={false}
                  attachmentSlot={slot}
                  onSlotUpdated={onSlotUpdated}
                  onSlotDeleted={onSlotDeleted}
                >
                  {({ mediaNode }) => (
                    <tr
                      className={clsx(
                        "w-full hover:opacity-85 cursor-pointer",
                        slotIndex == 0 && "pt-2"
                      )}
                      onClick={
                        slot.status != "ready"
                          ? null
                          : () => onViewAttachment(slot)
                      }
                    >
                      <td className="whitespace-nowrap py-2 text-sm pt-4">
                        <div className="flex items-center">
                          {isSelectionMode && (
                            <input
                              type="checkbox"
                              readOnly={true}
                              checked={selectedAttachments.some(
                                (x) => x.id === slot.id
                              )}
                              className="mr-6 h-5 w-5 rounded border-gray-300 text-primary-600 focus:ring-primary-600 focus:ring-transparent cursor-pointer"
                            />
                          )}
                          <div className="h-11 w-11 flex-shrink-0 rounded-lg overflow-hidden">
                            {mediaNode}
                          </div>
                          <div className="ml-4">
                            <div className="text-sm font-medium text-gray-700 truncate max-w-80">
                              {slot.preview.fileName}
                            </div>
                            <div className="text-sm font-medium text-gray-500">
                              {formatBytes(slot.preview.fileSize)}
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </MediaItem>
              ))}
            </Fragment>
          );
        })}
      </tbody>
    </table>
  );
}
