import Slider from "react-slick";
import slider1 from "../../../assets/images/login-slider/login-slider-1.png";
import slider2 from "../../../assets/images/login-slider/login-slider-2.png";
import slider3 from "../../../assets/images/login-slider/login-slider-3.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const settings = {
  dots: false,
  infinite: true,
  arrows: false,
  speed: 3000,
  autoplaySpeed: 2000,
  autoplay: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  vertical: true,
  verticalSwiping: false,
  swipeToSlide: true,
  cssEase: "ease-in-out",
  centerMode: true,
  adaptiveHeight: false,
};

export default function VerticalCarousel() {
  return (
    <Slider {...settings} className="h-full">
      <div className="slide">
        <img src={slider1} className="" />
      </div>

      <div className="slide">
        <img src={slider2} className="" />
      </div>

      <div className="slide">
        <img src={slider3} className="" />
      </div>
    </Slider>
    // <div className="relative m-auto w-[500px] overflow-hidden bg-white before:absolute before:left-0 before:top-0 before:z-[2] before:h-full before:w-[100px] before:bg-[linear-gradient(to_right,white_0%,rgba(255,255,255,0)_100%)] before:content-[''] after:absolute after:right-0 after:top-0 after:z-[2] after:h-full after:w-[100px] after:-scale-x-100 after:bg-[linear-gradient(to_right,white_0%,rgba(255,255,255,0)_100%)] after:content-['']">
    //   <div className="animate-infinite-slider flex w-[calc(250px*10)]">
    //     {LOGOS.map((logo, index) => (
    //       <div
    //         className="slide flex w-[125px] items-center justify-center"
    //         key={index}
    //       >
    //         {logo}
    //       </div>
    //     ))}
    //     {LOGOS.map((logo, index) => (
    //       <div
    //         className="slide flex w-[125px] items-center justify-center"
    //         key={index}
    //       >
    //         {logo}
    //       </div>
    //     ))}
    //   </div>
    // </div>
  );
}
