import Hashtag from "../../../../models/entities/hashtag";
import { HashtagIcon } from "@heroicons/react/20/solid";
import SelectHashtagsDialog from "../../../../components/dialogs/SelectHashtagsDialog";
import { useRef } from "react";

export interface HashtagsPickerProps {
  onSelected: (hashtag: Hashtag) => void;
}

export default function HashtagsPicker({ onSelected }: HashtagsPickerProps) {
  const hashtagsDialogRef = useRef(null);

  return (
    <>
      <button
        data-tooltip-id="tooltip-placeholder"
        data-tooltip-content="Add Hashtags"
        data-tooltip-delay-show={350}
        onClick={() => hashtagsDialogRef.current.openDialog()}
        type="button"
        className="flex items-center justify-center gap-1 rounded-full text-gray-400 hover:text-secondary-500 group focus:ring-0 focus:outline-none"
      >
        <HashtagIcon className="h-5 w-5 " />
      </button>

      <SelectHashtagsDialog ref={hashtagsDialogRef} onConfirm={onSelected} />
    </>
  );
}
